/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Box } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Switch } from '@mui/material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import CapabilitiesIcon from 'Theme/icons/capabilitiesIcon';
import InfoIcon from 'Theme/icons/InfoIcon';
import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import useThemeStore from 'Theme/store';
import useChatStore from 'Components/Chat/store';
import CapabilityModelValidator from './CapabilityModelValidator';

const CapabilitySelector = inject('store')(
  observer(
    ({
      store,
      selectedModels,
      onClose,
      onCapabilities,
      selectedCapabilities,
      setFreePlanModels,
      onInteraction,
    }) => {
      const [modelsSelected, setModelsSelected] = useState([]);
      const [capabilitiesSelected, setCapabilitiesSelected] = useState([]);
      const [capabilities, setCapabilities] = useState([]);
      const [infoModal, setInfoModal] = useState({
        isOpen: false,
        capabilityId: null,
      });

      const setModelsList = useChatStore((state) => state.setModelsList);
      const openInfoModal = (capabilityId, e) => {
        e.stopPropagation();
        setInfoModal({
          isOpen: true,
          capabilityId,
        });
      };

      const closeInfoModal = () => {
        setInfoModal({
          isOpen: false,
          capabilityId: null,
        });
      };

      useEffect(() => {
        (async () => {
          await getModels();
          await getCapabilities();
        })();
      }, []);

      useEffect(() => {
        const savedCapabilities = localStorage.getItem('capabilities');
        if (savedCapabilities) {
          try {
            setCapabilitiesSelected(JSON.parse(savedCapabilities));
          } catch (error) {
            console.error('Error parsing saved capabilities:', error);
          }
        } else if (selectedCapabilities) {
          setCapabilitiesSelected(selectedCapabilities);
        }
      }, [selectedCapabilities]);

      const getModels = async () => {
        try {
          const response = await store.api.get(`/model`);
          let availableModels = response.data.models;
          if (store.profile.plan == 'freeTos') {
            const res = await store.api.get('/user/constant', {
              params: {
                type: 'princing_plans',
              },
            });
            let freePlanModels = []; // Array to store models allowed for free users

            freePlanModels = res.data.rules[0].models;

            const modelsInFreePlan = []; // Array to store models available for free users
            const otherModels = []; // Array to store models not available for free users

            availableModels.forEach((model) => {
              if (freePlanModels.includes(model.model)) {
                modelsInFreePlan.push(model);
              } else {
                otherModels.push(model);
              }
            });
            // setModels(setDefaultModel(otherModels));
            setModelsList(otherModels);
            setFreePlanModels(setDefaultModel(modelsInFreePlan));
          } else {
            setModelsList(setDefaultModel(response.data.models));
          }
        } catch (error) {
          console.error(error);
        }
      };
      const getCapabilities = async () => {
        try {
          const response = await store.api.get(`/capability`);
          setCapabilities(response.data.capabilities);
        } catch (error) {
          console.error(error);
        }
      };

      const setDefaultModel = (models) => {
        setModelsSelected(selectedModels);
        return models;
      };

      const handleCapabilities = (capability) => {
        const newCapabilitiesSelected = isActive(capability)
          ? capabilitiesSelected.filter((item) => item._id !== capability._id)
          : [...capabilitiesSelected, capability];

        setCapabilitiesSelected(newCapabilitiesSelected);

        if (newCapabilitiesSelected.length > 0 && !isActive(capability)) {
          const incompatible = modelsSelected.filter((model) =>
            newCapabilitiesSelected.some(
              (cap) =>
                !model.function_calls.some(
                  (func) => func.capability === cap._id
                )
            )
          );

          if (incompatible.length > 0) {
            setModelValidatorOpen(true);
          }
        }

        if (onCapabilities) {
          onCapabilities(newCapabilitiesSelected);
        }
      };

      const isActive = (capability) => {
        return Boolean(
          capabilitiesSelected.find((item) => item._id === capability._id)
        );
      };

      const getIcon = (icon) => {
        const Icon = get(outlineIcons, icon);
        return Icon ? <Icon className={'h-6 w-6'} /> : null;
      };

      const getCompability = (capability) => {
        const comModels = modelsSelected.filter(
          (model) =>
            model.function_calls &&
            model.function_calls.length > 0 &&
            model.function_calls.some(
              (func) => func.capability == capability._id
            )
        );
        return comModels.map((model) => model.abbreviation);
      };

      const { theme: themeValue } = useThemeStore();

      const [newModelsSelected, setNewModelsSelected] = useState([]);
      const [isModelValidatorOpen, setModelValidatorOpen] = useState(false);

      const closeModelValidator = () => {
        setModelValidatorOpen(false);
      };

      return (
        <>
          <div className="font-figtree fixed right-0 bottom-0 z-50 m-4">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => {
                if (!isModelValidatorOpen) {
                  onClose();
                }
              }}
            />

            <div
              className={`relative ${
                themeValue === 'dark'
                  ? 'bg-lead text-crystal-bell'
                  : 'bg-ghost-white text-raisin-black'
              } rounded-[21px] overflow-hidden shadow-[0px_0px_30px_8px_rgba(0,0,0,0.25)] w-80`}
            >
              {!infoModal.isOpen ? (
                // Main Capabilities Modal
                <div className="p-4 h-min">
                  <div className="absolute right-[6px] top-[6px] group">
                    <button onClick={onClose}>
                      <XMarkIcon
                        className={`w-3 h-3 ${
                          themeValue === 'dark'
                            ? 'text-quicksilver'
                            : 'text-cool-gray'
                        }`}
                      />
                    </button>
                  </div>

                  <div className="flex items-center mb-3">
                    <div className="flex items-center gap-2">
                      <CapabilitiesIcon
                        className={
                          themeValue === 'dark'
                            ? 'text-crystal-bell'
                            : 'text-raisin-black'
                        }
                      />
                      <div
                        className={`${
                          themeValue === 'dark'
                            ? 'text-crystal-bell'
                            : 'text-raisin-black'
                        } text-xl font-bold`}
                      >
                        Capabilities
                      </div>
                    </div>
                  </div>

                  {modelsSelected.length > 0 && (
                    <div
                      className={`text-l text-center ${
                        themeValue === 'dark'
                          ? 'text-crystal-bell'
                          : 'text-agate-violet'
                      }`}
                    >
                      Compatible with selected:{' '}
                      <span className="font-bold">
                        {Array.from(
                          new Set(
                            capabilities.flatMap((capability) =>
                              getCompability(capability)
                            )
                          )
                        ).join(', ')}
                      </span>
                    </div>
                  )}

                  <Box sx={{ width: '100%' }}>
                    <TabContext value="1">
                      <TabPanel value="1" sx={{ padding: '12px 0 0 0' }}>
                        <div>
                          {capabilities.map((capability, index) => {
                            const active = isActive(capability);

                            return (
                              <div
                                key={index}
                                className={`grid grid-cols-[1fr_auto] gap-x-2 items-center w-full p-1 rounded select-none ${
                                  themeValue === 'dark'
                                    ? 'bg-lead'
                                    : 'bg-ghost-white'
                                } rounded-xl px-3 py-4 mb-2`}
                              >
                                <div className="flex gap-2">
                                  <div
                                    className={
                                      active
                                        ? themeValue === 'dark'
                                          ? 'text-stargate-shimmer'
                                          : 'text-violet-blue'
                                        : ''
                                    }
                                  >
                                    {getIcon(capability.icon)}
                                  </div>
                                  <div className="w-full">
                                    <div className="flex items-center justify-between">
                                      <div className="text-base">
                                        {capability.name}
                                      </div>
                                      <InfoIcon
                                        className="w-4 h-4 cursor-pointer hover:opacity-70 ml-2"
                                        onClick={(e) =>
                                          openInfoModal(capability._id, e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleCapabilities(capability)}
                                >
                                  <Switch
                                    id={`switch-${capability._id}`}
                                    checked={active}
                                    onChange={() =>
                                      handleCapabilities(capability)
                                    }
                                    className="data-[state=checked]:bg-violet-blue"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              ) : (
                // Info Modal
                <div className="p-4 h-min">
                  <div className="absolute right-[6px] top-[6px] group">
                    <button onClick={onClose}>
                      <XMarkIcon
                        className={`w-3 h-3 ${
                          themeValue === 'dark'
                            ? 'text-quicksilver'
                            : 'text-cool-gray'
                        }`}
                      />
                    </button>
                  </div>

                  <button
                    onClick={closeInfoModal}
                    className="flex items-center gap-2 text-violet-blue mb-6 hover:opacity-80"
                  >
                    <ArrowLeftIcon className="w-4 h-4" />
                    Go back to Capabilities
                  </button>

                  {(() => {
                    const capability = capabilities.find(
                      (c) => c._id === infoModal.capabilityId
                    );
                    if (!capability) return null;

                    return (
                      <>
                        <div className="flex items-center gap-3 mb-4">
                          <div className="text-gray-600">
                            {getIcon(capability.icon)}
                          </div>
                          <div className="text-xl font-semibold flex-grow">
                            {capability.name}
                          </div>
                          <div className="flex items-center gap-1 text-gray-600">
                            <CurrencyDollarIcon className="w-4 h-4" />
                            <span>{capability.coins}</span>
                          </div>
                        </div>

                        <div
                          className="text-sm text-gray-600 [&>a]:text-violet-blue [&>a]:hover:opacity-80"
                          dangerouslySetInnerHTML={{
                            __html: capability.description,
                          }}
                        />
                      </>
                    );
                  })()}
                </div>
              )}
            </div>
          </div>
          <CapabilityModelValidator
            capabilitiesSelected={capabilitiesSelected}
            modelsSelected={modelsSelected}
            setModelsSelected={setModelsSelected}
            isOpen={isModelValidatorOpen}
            onClose={() => setModelValidatorOpen(false)}
            themeValue={themeValue}
            onInteraction={onInteraction}
            onCapabilities={onCapabilities}
            newModelsSelected={newModelsSelected}
            setModelValidatorOpen={setModelValidatorOpen}
          />
        </>
      );
    }
  )
);

export default CapabilitySelector;
