/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { memo, useEffect, useState } from 'react';
// Plugin's

import katex from 'katex'; // Import KaTeX to render mathematical equations
import 'katex/dist/katex.min.css'; // Import CSS styles for KaTeX
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
// Components
import { Tooltip } from '@mui/material';
import useThemeStore from 'Theme/store';
import toast from 'react-hot-toast';
import { HighlightedText } from './HighlightedText';
import { MemoizedReactMarkdown } from './Markdown';
import { MarkdownComponents } from './Markdown.components';
import { MemoizedOriginalPrompt } from './OriginalPrompt';
import ImageMaskEditorModal from './ImageMaskEditorModal';
// Icons
import {
  ArrowDownTrayIcon,
  HeartIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/solid';
import useChatStore from 'Components/Chat/store';
import Modal from 'react-modal';

const ContentMessage = memo(
  ({
    message,
    userType,
    onFavoriteFile,
    onOpenFull,
    originalPrompt,
    expanded = true,
    optimizing,
    images,
    store,
    renderedFromShareChatPage,
    isEditable,
    onSend,
    loading,
    capability,
    functionCapability,
  }) => {
    const selectedChat = useChatStore((state) => state.selectedChat);
    const { theme } = useThemeStore();
    const [isRendering, setIsRendering] = useState(false);

    // useEffect(() => {
    //   if (message) {
    //     setIsRendering(true);
    //   }

    //   const handleWriting = () => {
    //     setIsRendering(true);
    //   };

    //   io.socket.on('writing', handleWriting);

    //   return () => {
    //     io.socket.off('writing', handleWriting);
    //   };
    // }, [message]);

    const [isOpen, setIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [maskEditorOpen, setMaskEditorOpen] = useState(false);
    const [maskEditorImage, setMaskEditorImage] = useState(null);

    const [localOriginalPrompt, setLocalOriginalPrompt] = useState(
      originalPrompt || ''
    );

    useEffect(() => {
      if (originalPrompt !== null && originalPrompt !== undefined) {
        setLocalOriginalPrompt(originalPrompt);
      }
    }, [originalPrompt]);

    /**
     * Renders mathematical equations using KaTeX.
     * @param {string} equation - The equation to render.
     * @param {boolean} displayMode - Block mode (true) or inline mode (false).
     * @returns {string} - The rendered equation in HTML.
     */
    const renderEquation = (equation, displayMode = false) => {
      try {
        const className = displayMode ? 'latex-block' : 'latex-inline';
        return `<span class="${className}">${katex.renderToString(equation, {
          throwOnError: false,
          displayMode,
        })}</span>`;
      } catch (error) {
        console.error('KaTeX Error:', error);
        return `<span style="color:#cc0000">Error in equation: ${equation}</span>`;
      }
    };

    const getMessage = (message) => {
      if (typeof message == 'string') {
        return processing(message);
      }

      if (message.length === 1 && message[0].type === 'text') {
        return processing(message[0].text);
      }

      const parseMessage = message.map((frg, index) => {
        switch (frg.type) {
          case 'image_url':
            return (
              <div
                key={index}
                className={`max-h-80 w-full flex justify-center items-center`}
              >
                <div
                  className={`max-h-80 h-fit cursor-pointer relative ${
                    !frg.loading ? 'w-fit' : 'w-full'
                  }`}
                  onClick={() => onOpenFull(getImgTag(frg, true))}
                >
                  {frg.loading ? (
                    <div
                      className={`rounded-[14px] w-full h-80 flex justify-center items-center  ${
                        theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                      }`}
                    >
                      <img
                        className="h-9"
                        key={Math.random()}
                        src="/gifs/straico_loader.gif"
                        alt="img ref"
                      />
                    </div>
                  ) : (
                    getImgTag(frg)
                  )}
                  {frg.no_actions || userType == 'user' ? (
                    ''
                  ) : (
                    <div className="absolute p-2 bottom-0 right-0 rounded-b-[14px] w-full bg-gradient-to-t from-raisin-black to-transparent">
                      <div className="relative flex gap-2 justify-end">
                        <div className="relative group">
                          <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                            <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Download image
                            </span>
                          </Tooltip>
                          <ArrowDownTrayIcon
                            onClick={(e) =>
                              handleDownload(e, frg.image_url.url)
                            }
                            className="w-5 h-5 font-bold text-white cursor-pointer"
                          />
                        </div>
                        {onFavoriteFile && (
                          <div className="relative group">
                            <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                              <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                Add image to your attachments
                              </span>
                            </Tooltip>
                            <HeartIcon
                              id={'favorite' + frg._id}
                              onClick={(e) => handleFavorite(e, frg)}
                              className={`w-5 h-5 font-bold ${
                                frg.favorite ? 'text-nue-blue' : 'text-white'
                              } cursor-pointer`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          default:
            return processing(frg.text);
        }
      });
      return parseMessage;
    };

    const getImgTag = (frg, maxScreen = false) => {
      return (
        <img
          className={`max-h-${
            maxScreen ? '[calc(100vh_-_2rem)]' : '80'
          } rounded-[14px]`}
          key={Math.random()}
          src={frg.image_url.url}
          alt="img ref"
        />
      );
    };

    const handleDownload = (e, url) => {
      e.stopPropagation();
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg';
      link.target = '_blanck';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleFavorite = (e, item) => {
      e.stopPropagation();
      if (!item.favorite) {
        let url = item.image_url.url;
        onFavoriteFile({
          url: url,
          name: url.substring(url.lastIndexOf('/') + 1),
          size: null,
          enabled: true,
          type: 'image',
          imageId: item._id,
          chatId: selectedChat.chat._id,
        });

        const heartIcon = document.getElementById('favorite' + item._id);
        if (heartIcon) {
          heartIcon.classList.remove('text-white');
          heartIcon.classList.add('text-nue-blue');
          item.favorite = !item.favorite;
        }
      } else {
        toast.error('The file is currently in your attachments.');
      }
    };

    const customStylesRegex = /""'\s*([^']*)\s*'""/g;

    const regex_1 = /```"'(.*?)'"```/g;
    const regex_2 = /```""'(.*?)'""```/g;

    const processing = (text) => {
      let formattedMessage = text
        .replace(regex_1, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        })
        .replace(regex_2, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        });

      if (userType === 'user') {
        formattedMessage = formattedMessage.replaceAll(
          customStylesRegex,
          (match) => {
            return `<span id='template'>${match
              .replaceAll('"', '')
              .replaceAll("'", '')}</span>`;
          }
        );
      }

      // Render mathematical equations detected by their delimiters
      formattedMessage = formattedMessage
        .replace(/\\\[([^\]]+?)\\\]/g, (_, equation) =>
          renderEquation(equation, true)
        )
        .replace(/\\\(([^\)]+?)\\\)/g, (_, equation) =>
          renderEquation(equation, false)
        );

      return formattedMessage;
    };

    const renderMessageContent = (message) => {
      if (!message) {
        return;
      }
      const messageContent = getMessage(message);

      if (typeof messageContent === 'string') {
        return (
          <MemoizedReactMarkdown
            className="prose break-words dark:prose-invert prose-p:leading-relaxed markdown prose-pre:p-0 text-base"
            remarkPlugins={[remarkGfm, remarkMath]} // Plugins Remark
            rehypePlugins={[rehypeRaw]} // Plugins Rehype
            components={MarkdownComponents}
          >
            {messageContent}
          </MemoizedReactMarkdown>
        );
      } else {
        return (
          <div
            className={`py-2 grid gap-2 justify-items-center ${
              messageContent.length > 1 && 'grid-cols-2'
            }`}
          >
            {messageContent}
          </div>
        );
      }
    };

    const openModal = (url) => {
      setModalImage(url);
      setIsOpen(true);
    };

    const closeModal = () => {
      setModalImage(null);
      setIsOpen(false);
    };

    const openMaskEditor = (url) => {
      setMaskEditorImage(url);
      setMaskEditorOpen(true);
    };

    const closeMaskEditor = () => {
      setMaskEditorImage(null);
      setMaskEditorOpen(false);
    };

    const [processingImages, setProcessingImages] = useState(new Set());

    const [localCapabilities, setLocalCapabilities] = useState(() => {
      const savedCapabilities = localStorage.getItem('capabilities');
      return savedCapabilities ? JSON.parse(savedCapabilities) : [];
    });

    const getImageState = (func, msg) => {
      if (!localCapabilities || localCapabilities.length === 0) {
        return null;
      }
      if (func && !msg) {
        if (loading && capability?.name !== 'Browsing') {
          return (
            loading && (
              <div
                className={`rounded-[14px] w-full h-80 flex justify-center items-center relative overflow-hidden ${
                  theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                }`}
                style={{
                  background: `linear-gradient(
                  to right,
                  ${theme === 'dark' ? '#333333' : '#e5e7eb'} 0%,
                  ${theme === 'dark' ? '#555555' : '#ffffff'} 50%,
                  ${theme === 'dark' ? '#333333' : '#e5e7eb'} 100%
                )`,
                  backgroundSize: '200% 100%',
                  animation: 'shimmer 2s infinite',
                }}
              ></div>
            )
          );
        }
      }
    };

    const renderImages = () => {
      const handleFavoriteImage = async (e, image) => {
        e.stopPropagation();

        if (processingImages.has(image.url)) return;

        if (!image.favorite) {
          setProcessingImages((prev) => new Set(prev).add(image.url));

          onFavoriteFile({
            url: image.url,
            name: image.url.substring(image.url.lastIndexOf('/') + 1),
            size: null,
            enabled: true,
            type: 'image',
            chatId: selectedChat.chat._id,
          });
          image.favorite = true;

          setProcessingImages((prev) => {
            const updated = new Set(prev);
            updated.delete(image.url);
            return updated;
          });
        } else {
          toast.error('The file is currently in your attachments.');
        }
      };

      if (images && images.length > 0) {
        return (
          <div className="gap-10 max-w-sm mx-auto">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative group max-h-90 w-full flex justify-center items-center"
              >
                <img
                  src={image.url}
                  alt={`Image ${index + 1}`}
                  className="max-h-90 rounded-[14px] cursor-pointer w-full object-cover"
                  onClick={() => {
                    setModalImage(image.url);
                    setIsOpen(true);
                  }}
                  loading="lazy"
                />

                {!renderedFromShareChatPage && (
                  <div className="absolute bottom-2 right-2 flex gap-2">
                    <Tooltip title="Download image" placement="top" arrow>
                      <button
                        onClick={(e) => handleDownload(e, image.url)}
                        className="bg-black bg-opacity-50 p-1 rounded-md text-white"
                      >
                        <ArrowDownTrayIcon className="w-5 h-5" />
                      </button>
                    </Tooltip>

                    {onFavoriteFile && (
                      <Tooltip title="Add to favorites" placement="top" arrow>
                        <button
                          id={`favorite-${image.url}`}
                          onClick={(e) => handleFavoriteImage(e, image)}
                          className={`bg-black bg-opacity-50 p-1 rounded-md ${
                            image.favorite ? 'text-nue-blue' : 'text-white'
                          }`}
                        >
                          <HeartIcon className="w-5 h-5 font-bold" />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      }
      return null;
    };

    return (
      <div className={`${!expanded && 'line-clamp-2'}`}>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[9999]"
          style={{
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: 'none',
              background: 'transparent',
              padding: '0',
              maxWidth: '100%',
              maxHeight: '90vh',
              overflow: 'hidden',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              zIndex: 9999,
            },
          }}
        >
          {modalImage && (
            <img
              src={modalImage}
              alt="Full view"
              className="max-w-full max-h-[90vh] rounded-md"
            />
          )}
        </Modal>

        <ImageMaskEditorModal
          isOpen={maskEditorOpen}
          onRequestClose={closeMaskEditor}
          imageUrl={maskEditorImage}
          store={store}
          onSend={onSend}
        />

        {userType === 'user' ? (
          <>
            <MemoizedOriginalPrompt
              originalPrompt={originalPrompt}
              theme={theme}
              optimizing={optimizing}
            />
            <HighlightedText text={getMessage(message)} />
          </>
        ) : (
          <>
            {renderMessageContent(message)}
            {renderImages()}
            {getImageState(functionCapability, message)}
          </>
        )}
      </div>
    );
  }
);

export default ContentMessage;
