import * as outlineIcons from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { get } from 'lodash';
import CapabilitiesIcon from 'Theme/icons/capabilitiesIcon';
import ModelsIcon from 'Theme/icons/modelsIcon';
import { icons as modelIcons } from 'Theme/icons/modelsIcons/index';

const useCommonFunctionsModels = ({
  capabilities,
  modelsSelected,
  capabilitiesSelected,
  addResponseModels,
}) => {
  const isSelected = (model) => {
    return Boolean(modelsSelected.find((item) => item._id === model._id));
  };

  const selected = (model) => {
    return isSelected(model);
  };

  const disabled = (model) => {
    return addResponseModels && isSelected(model);
  };

  const isActive = (capability) => {
    return Boolean(
      capabilitiesSelected.find((item) => item._id === capability._id)
    );
  };

  const getIcon = (icon) => {
    const Icon = get(outlineIcons, icon);
    return Icon ? <Icon className={'h-6 w-6'} /> : null;
  };

  const getModelIcon = (icon) => {
    const Icon = get(modelIcons, icon);
    return Icon ? (
      <Icon className="h-6 w-6" />
    ) : (
      <ModelsIcon className="h-6 w-6 stroke-cool-gray" />
    );
  };

  const getCapabilityIcon = (model) => {
    if (model.function_calls.length) {
      // find capability
      let modelCapabilities = capabilities.filter((capability) =>
        model.function_calls
          .map((func) => func.capability)
          .includes(capability._id)
      );
      return (
        <div className="relative group">
          <CapabilitiesIcon
            className={`w-4 h-4 ${
              isSelected(model) ? 'stroke-violet-blue' : 'stroke-cool-gray'
            }`}
          />
          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-2/3 -top-full">
            <div className="relative z-50 p-2 text-sm leading-none text-raisin-black bg-lavender bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
              {modelCapabilities.map((capability, index) => (
                <div key={index}>{capability.name}</div>
              ))}
            </div>
          </Tooltip>
        </div>
      );
    } else {
      return '-';
    }
  };

  const getCompability = (capability) => {
    const comModels = modelsSelected.filter(
      (model) =>
        model.function_calls &&
        model.function_calls.length > 0 &&
        model.function_calls.some((func) => func.capability === capability._id)
    );
    return (
      <>
        {comModels.map((model) => (
          <div key={model._id} className="flex gap-2 items-center">
            {getModelIcon(model.icon)}
            <div className="text-sm flex-1 line-clamp-1">
              {model.abbreviation}
            </div>
          </div>
        ))}
      </>
    );
  };

  const getUncompability = (capability) => {
    const uncomModels = modelsSelected.filter(
      (model) =>
        !model.function_calls ||
        model.function_calls.length === 0 ||
        !model.function_calls.some((func) => func.capability === capability._id)
    );
    return (
      <>
        {uncomModels.map((model) => (
          <div key={model._id} className="flex gap-2 items-center">
            {getModelIcon(model.icon)}
            <div className="text-sm flex-1 line-clamp-1">
              {model.abbreviation}
            </div>
          </div>
        ))}
      </>
    );
  };

  return {
    isSelected,
    selected,
    disabled,
    isActive,
    getIcon,
    getModelIcon,
    getCapabilityIcon,
    getCompability,
    getUncompability,
  };
};

export default useCommonFunctionsModels;
