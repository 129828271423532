import useThemeStore from 'Theme/store';
// Mobx
// Components
import Modal from 'Components/Common/Modal/NewModal';
// Icons
import { SparklesIcon } from '@heroicons/react/24/solid';
import TemplatesIcon from '../Theme/icons/templatesIcon';

const ModelWarningDialog = ({
  data,
  onClose,
  onContinue,
  onContinueWithCurrentModels,
}) => {
  const onConfirm = () => {
    onContinue(data);
  };

  const onContinueWithCurrent = () => {
    onContinueWithCurrentModels(data);
  };

  const { theme } = useThemeStore();

  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        title="Template and Selected Models Mismatch"
      >
        <div
          className={`font-figtree text-base ${
            theme === 'dark' ? 'text-crystal-bell' : 'text-black'
          } text-center`}
        >
          <div className="mb-4 text-base text-center">
            The template you've selected is designed for a model that's not
            among your current choices. Which option would you like to choose?
          </div>
        </div>
        <div className="flex justify-center mt-6 gap-4">
          <button
            onClick={onContinueWithCurrent}
            className={`flex items-center justify-center px-4 py-2 rounded-[12px] text-sm font-medium transition-colors ${
              theme === 'dark'
                ? 'bg-[#9091A4] text-white hover:bg-[#D4D5E6B2]'
                : 'bg-[#D4D5E6] text-black hover:bg-[#E1E1E5]'
            }`}
          >
            <SparklesIcon
              className="h-[18px] w-[18px] mr-2"
              aria-hidden="true"
            />
            Current selected models
          </button>
          <button
            onClick={onConfirm}
            className={`flex items-center justify-center px-4 py-2 rounded-[12px] text-sm font-medium transition-colors ${
              theme === 'dark'
                ? 'bg-[#464BBA] text-white hover:bg-[#5256A6]'
                : 'bg-[#464BBA] text-white hover:bg-[#5256A6]'
            }`}
          >
            <TemplatesIcon
              className="h-[18px] w-[18px] mr-2  stroke-crystal-bell mr-3"
              aria-hidden="true"
            />
            Template default models
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ModelWarningDialog;
