import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
// Icons
import {
  ArchiveBoxIcon,
  ChevronDoubleRightIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
// Hooks
import useDebounce from 'Hooks/useDebounce.js';
import useWindowSize from 'Hooks/useWindowSize';
import useModal from '../../../Hooks/useModal';
// Context
import { useChat } from '../../../Context/Chat/Chat.context';
// Utils
import { getIndexV2 } from 'Constants/prompts';
import { toast } from 'react-hot-toast';
// Framer motion
import { motion } from 'framer-motion';
// Mobx
import { inject, observer } from 'mobx-react';
// Theme
import useThemeStore from 'Theme/store';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store/index.js';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store/index.js';
import { useCreateTemplateFolder } from '../CreateTemplateFolder/store';
import useTemplateModalStore from '../TemplateModal/store.js';
// Components
import { Button } from '@mui/material';
import { buildDataToFormEdit } from 'Components/Chat/TemplateModal/components/AdvancedSection/helpers';
import Loader from 'Components/Loader.js';
import ShareTool from 'Features/Core/Components/ShareTool/ShareTool.js';
import FoldersTemplates from 'Features/FoldersTemplates';
import DeleteFolder from 'Features/FoldersTemplates/Components/DeleteFolder';
import CreateTemplateFolder from '../CreateTemplateFolder';
import usePromptLibraryStore from '../PromptLibrary/store';

const PromptSide = ({ visible, store, newDesing = false }) => {
  // Context
  const {
    getToolsAndFilterSidebar,
    sidebarTools,
    templatesFolders,
    showPromptSide: show,
    toggleShowPromptSide: toggleSide,
    closeShowPrompSide: closeSide,
    loadingSideTools,
    setShowMobilePrompts,
  } = useChat();

  // local tool
  const [localTool, setLocalTool] = useState(null);

  // Modals state
  const { setDeleteTemplateModal, setToDelete } = useTemplateModalStore();

  const { theme } = useThemeStore();

  const {
    isOpen: isOpenShareTool,
    openModal: openModalShareTool,
    closeModal: closeModalShareTool,
  } = useModal();
  // on search
  const [searchValue, setSearchValue] = useState('');
  const onSearch = (e) => setSearchValue(e.target.value);

  const debouncedValueText = useDebounce(searchValue, 500);

  useEffect(() => {
    getToolsAndFilterSidebar(debouncedValueText);
    setSearch(debouncedValueText ?? '');
  }, [debouncedValueText]);

  const [showSearch, setShowSearch] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 1024) {
      setShowSearch(true);
      setShowMobilePrompts(false);
    } else {
      setShowSearch(false);
      closeSide();
    }
  }, [width]);

  const navigate = useNavigate();
  const location = useLocation();

  // Build tree data
  const { setTreeData, setLoading, setSearch } = useTreeDNDTemplatesStore();
  useEffect(() => {
    if (sidebarTools.length > 0) {
      let tree = [];
      // add folders
      templatesFolders
        .sort((a, b) => b.name.localeCompare(a.name))
        .sort((a, b) => {
          const indexA = getIndexV2(a?.name);
          const indexB = getIndexV2(b?.name);
          // If index values are not available for both items, keep their order unchanged
          if (indexA === -1 && indexB === -1) {
            return -1;
          }
          // If index value is not available for one item, place it at the end
          if (indexA === -1) {
            return 1;
          }
          if (indexB === -1) {
            return -1;
          }
          // Otherwise, sort based on index value
          return indexA - indexB;
        })
        .sort((a, b) => {
          if (a.creator === null && b.creator === null) {
            return 0;
          }
          if (a.creator === null) {
            return 1;
          }
          if (b.creator === null) {
            return -1;
          }
          return a.creator.localeCompare(b.creator);
        })
        .forEach(({ name, _id, parent_folder, ...rest }) => {
          tree.push({
            id: _id,
            parent: parent_folder ?? 0,
            droppable: true,
            text: name,
            data: { name, _id, parent_folder, ...rest, type: 'folder' },
          });
        });
      // add tools
      sidebarTools.forEach((tool) => {
        tree.push({
          id: tool._id,
          parent: tool.parent_folder ?? 0,
          droppable: false,
          text: tool.title,
          data: { ...tool, type: 'template' },
        });
      });
      // Set the tree data
      setTreeData(tree);
    }
  }, [sidebarTools, templatesFolders]);

  // Update loading
  useEffect(() => {
    setLoading(loadingSideTools);
  }, [loadingSideTools]);

  // Methods templates
  const onClickTemplate = (tool) => {
    console.log('tool', tool);
    // url params
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const onEditTemplate = async (selectedTool) => {
    checkLocation();
    try {
      const res = await store.api.get(`/v2/templates/${selectedTool._id}`);
      const { data } = res.data;
      const editData = buildDataToFormEdit(data);
      // Function to set the data and open the modal
      setDefaultData(editData);
      setEdit(true);
      setOpenModal(true);
    } catch (error) {
      toast.error('Error: when get template data');
    }
  };

  const onDeleteTemplate = (tool) => {
    checkLocation();
    setToDelete(tool);
    setDeleteTemplateModal(true);
  };

  const onShareTemplate = (tool) => {
    checkLocation();
    openModalShareTool();
    setLocalTool(tool);
  };

  const onDuplicateTemplate = async (tool) => {
    if (
      tool._id === '65805947d92e370a3d5625c6' ||
      tool._id === '66c786d7e26442bc593e575d'
    ) {
      toast.error(
        'You cannot duplicate image generation templates. Try duplicating a different template.'
      );
      return;
    }
    try {
      await store.api.post('/tool/clone/' + tool?._id, {
        customCategory: 'My Tools',
      });
      await getToolsAndFilterSidebar('');
      toast.success(
        'Template duplicated. Please check your "My Templates" section.'
      );
    } catch (error) {
      toast.error(
        'Error occurred while duplicating the template. Please try again later.'
      );
    }
  };

  const checkLocation = () => {
    if (location.pathname !== '/chat') navigate('/chat');
  };

  const onOperationsTemplate = {
    onClickTemplate,
    onEditTemplate,
    onDeleteTemplate,
    onShareTemplate,
    onDuplicateTemplate,
  };

  // Methods Buttons
  const { setOpenModal, setEdit, setDefaultData } = useCreateTemplatesStore();
  const onCreateTemplate = () => {
    setOpenModal(true);
  };

  const toggleSearch = () => setShowSearch(!showSearch);

  const { setOpen: setOpenTemplateFolder } = useCreateTemplateFolder();
  const createTemplateFolder = () => setOpenTemplateFolder(true);

  const { setOpenPromptLibrary, openPromptLibrary } = usePromptLibraryStore();
  const togglePromptLibrary = () => setOpenPromptLibrary(!openPromptLibrary);

  const handleOpenPromptLibrary = () => {
    checkLocation();
    setOpenPromptLibrary(true);
  };

  return (
    <>
      {!newDesing ? (
        (show || visible) && (
          <motion.div
            initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 400, opacity: 0 }}
            className={`font-figtree bg-red-600 z-10 lg:bg-seasalt dark:lg:bg-lead lg:bg-none bg-gradient-to-b from-lavender dark:from-ship-grey to-lavender dark:to-night-black rounded-md lg:rounded-none relative h-full`}
          >
            <div className="w-full px-[16px] pt-[8px]">
              <div className="flex justify-between items-center">
                <p className="text-raisin-black uppercase font-bold dark:text-crystal-bell">
                  AI Dashboard
                </p>
                <div
                  className="hidden lg:flex w-[20px] z-50 mr-0 cursor-pointer h-[40px] bg-lavender dark:bg-ship-grey rounded items-center justify-center"
                  onClick={toggleSide}
                >
                  <ChevronDoubleRightIcon
                    className={`h-6 w-6 ${
                      theme === 'dark'
                        ? 'dark:text-tropical-indigo'
                        : 'text-violet-blue'
                    }`}
                  />
                </div>
              </div>
              <div className="hidden justify-between items-center lg:flex mt-[12px] bg-lavender dark:bg-ship-grey px-[10px] py-[6px] rounded">
                <p className="text-violet-blue uppercase font-bold text-sm">
                  Templates
                </p>
              </div>
              <div className="hidden lg:flex gap-2 items-center mt-[12px]">
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-md"
                  onClick={onCreateTemplate}
                >
                  <PlusCircleIcon className={'h-6 w-6 text-white'} />
                </div>
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white dark:bg-ship-grey hover:dark:bg-quicksilver cursor-pointer rounded-md"
                  onClick={createTemplateFolder}
                >
                  <FolderPlusIcon
                    className={
                      'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                <div
                  onClick={toggleSearch}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white dark:bg-ship-grey hover:dark:bg-quicksilver cursor-pointer rounded-md"
                >
                  <MagnifyingGlassIcon
                    className={
                      'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                {showSearch && (
                  <div className="flex w-[19em]">
                    <input
                      autoFocus
                      value={searchValue}
                      onChange={onSearch}
                      type="search"
                      placeholder="Search templates"
                      className={`appearance-none ${
                        theme === 'dark'
                          ? 'dark:bg-night-black dark:text-crystal-bell'
                          : 'bg-white text-raisin-black'
                      }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray`}
                      autoComplete="off"
                    />
                  </div>
                )}
              </div>
              <div
                className="hidden md:block h-[2px] bg-cool-gray my-2"
                content=" "
              />
            </div>
            <div className="flex px-4 pt-2 gap-2 w-full">
              <div
                className="md:hidden flex items-center justify-center w-[32px] h-[32px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-md"
                onClick={onCreateTemplate}
              >
                <PlusCircleIcon className={'h-6 w-6 text-white'} />
              </div>
              <div
                className="md:hidden flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md"
                onClick={createTemplateFolder}
              >
                <FolderPlusIcon
                  className={
                    'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                  }
                />
              </div>
              <input
                autoFocus
                value={searchValue}
                onChange={onSearch}
                type="search"
                placeholder="Search templates"
                className={`md:hidden appearance-none ${
                  theme === 'dark'
                    ? 'dark:bg-night-black dark:text-crystal-bell'
                    : 'bg-white text-raisin-black'
                }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray`}
                autoComplete="off"
              />
            </div>
            <div className="lg:pl-5 w-full grid col-span-12 overflow-y-auto overflow-auto  supports-[height:100cqh]:h-[calc(100cqh-20em)] supports-[height:100svh]:h-[calc(100cqh-20em)] lg:h-[calc(100vh-16em)]">
              {loadingSideTools ? (
                <div className="flex items-center justify-center mt-2">
                  <Loader />
                </div>
              ) : (
                <FoldersTemplates
                  onOperationsTemplate={onOperationsTemplate}
                  enableDnd
                />
              )}
              <div className="flex items-center justify-center mt-4">
                <Button
                  className="uppercase"
                  variant="contained"
                  onClick={togglePromptLibrary}
                >
                  <span>View all templates</span>
                </Button>
              </div>
            </div>
            <ShareTool
              isOpen={isOpenShareTool}
              closeModal={closeModalShareTool}
              tool={localTool}
            />
            <DeleteFolder />
            <CreateTemplateFolder />
          </motion.div>
        )
      ) : (
        <motion.div
          className={`font-figtree z-10 relative h-full flex-1 flex flex-col overflow-auto`}
        >
          <div className="flex gap-2 items-center px-4 pb-2">
            <Tooltip title="Create template" arrow placement="top-start">
              <div
                className="flex items-center justify-center w-[32px] h-[32px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-[10px]"
                onClick={onCreateTemplate}
              >
                <PlusCircleIcon className={'h-5 w-5 text-white'} />
              </div>
            </Tooltip>
            <Tooltip title="Create template folder" arrow placement="top-start">
              <div
                className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white dark:bg-ship-grey hover:dark:bg-quicksilver cursor-pointer rounded-[10px]"
                onClick={createTemplateFolder}
              >
                <FolderPlusIcon
                  className={
                    'h-5 w-5 text-violet-blue dark:text-tropical-indigo'
                  }
                />
              </div>
            </Tooltip>
            <div
              className={`flex-1 flex items-center relative rounded-[10px] transition ${
                theme === 'dark'
                  ? 'focus-within:border-[#787878]'
                  : 'focus-within:border-[#CFD0D6]'
              } border-2 border-transparent rounded-[12px]`}
            >
              <input
                autoFocus
                value={searchValue}
                onChange={onSearch}
                type="search"
                placeholder="Search templates"
                className={`appearance-none ${
                  theme === 'dark'
                    ? 'dark:bg-night-black dark:text-crystal-bell'
                    : 'bg-white text-raisin-black'
                } h-8 text-sm focus:outline-none transition w-full pl-8 p-1 rounded-[10px] placeholder-battleship-gray`}
                autoComplete="off"
              />
              <span
                className={`${
                  theme === 'dark' ? 'dark:text-[#787878]' : 'text-[#51526C]'
                } absolute left-2.5`}
              >
                <MagnifyingGlassIcon className={'h-4 w-4'} />
              </span>
            </div>
          </div>
          <div className="w-full h-full overflow-auto flex-1 flex flex-col">
            {loadingSideTools ? (
              <div className="flex-1 flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                {sidebarTools.length === 0 ? (
                  <div className="flex items-center justify-center mt-2 mx-2 text-sm p-4">
                    <p>
                      Sorry, no results were found for your search. Please
                      adjust your keywords and try again :(
                    </p>
                  </div>
                ) : (
                  <FoldersTemplates
                    onOperationsTemplate={onOperationsTemplate}
                    enableDnd
                  />
                )}
              </>
            )}
            <div className="flex items-center justify-center mt-2">
              <div
                className="flex items-center bg-lavender hover:bg-lavender/70 text-violet-blue px-3 py-2 rounded-xl cursor-pointer select-none"
                onClick={handleOpenPromptLibrary}
              >
                <ArchiveBoxIcon className="w-[18px] h-[18px] mr-2" />
                <span>Visit template library</span>
              </div>
            </div>
          </div>
          <ShareTool
            isOpen={isOpenShareTool}
            closeModal={closeModalShareTool}
            tool={localTool}
          />
          <DeleteFolder />
          <CreateTemplateFolder />
        </motion.div>
      )}
    </>
  );
};

export default inject('store')(observer(PromptSide));
