import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const ChatModelSelectorDialog = ({
  isOpen,
  onClose,
  onPreserveModels,
  onResetToDefaultModels,
  currentModels,
  themeValue,
}) => {
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [tempDontAskAgain, setTempDontAskAgain] = useState(false);
  const [showIrreversibleDialog, setShowIrreversibleDialog] = useState(false);
  const [dialogReady, setDialogReady] = useState(false);

  // Load "Don't ask again" preference from localStorage
  useEffect(() => {
    const storedDontAskAgain =
      localStorage.getItem('chatModelDontAskAgain') === 'true';
    setDontAskAgain(storedDontAskAgain);
  }, []);

  // Handle the dialog rendering and auto-action
  useEffect(() => {
    if (dontAskAgain && isOpen) {
      executeLastAction();
    } else if (isOpen) {
      setDialogReady(true);
    } else {
      setDialogReady(false);
    }
  }, [dontAskAgain, isOpen]);

  const executeLastAction = () => {
    const lastAction = localStorage.getItem('chatModelLastAction');
    if (lastAction === 'preserve') {
      onPreserveModels(currentModels);
    } else if (lastAction === 'reset') {
      onResetToDefaultModels();
    }
    // Ensure the dialog does not reopen immediately
    onClose();
  };

  const handlePreserveModels = () => {
    onPreserveModels(currentModels);
    savePreferences('preserve');
    onClose();
  };

  const handleResetToDefaultModels = () => {
    onResetToDefaultModels();
    savePreferences('reset');
    onClose();
  };

  const savePreferences = (action) => {
    localStorage.setItem('chatModelLastAction', action);
    if (tempDontAskAgain) {
      setDontAskAgain(true);
      localStorage.setItem('chatModelDontAskAgain', 'true');
    }
  };

  const handleDontAskAgainChange = (event) => {
    const isChecked = event.target.checked;
    setTempDontAskAgain(isChecked);
    if (isChecked) {
      setShowIrreversibleDialog(true);
    }
  };

  const handleIrreversibleConfirm = () => {
    setShowIrreversibleDialog(false);
  };

  const handleIrreversibleCancel = () => {
    setTempDontAskAgain(false);
    setShowIrreversibleDialog(false);
  };

  if (!dialogReady) {
    return null; // Prevent rendering the dialog until it's ready
  }

  return (
    <>
      {/* Main Dialog */}
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#F7F7F8',
            width: '655px',
            borderRadius: '21px',
            padding: '32px',
            gap: '4px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Figtree, sans-serif',
            fontWeight: 700,
            fontSize: '28px',
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
          }}
        >
          Select Models for the New Chat
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              marginBottom: '8px',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontFamily: 'Figtree, sans-serif',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            Do you want to reselect the current models with their active
            capabilities or reset to default settings for the new chat?
          </Typography>
          <ul
            style={{
              margin: '0 auto',
              listStyleType: 'disc',
              width: 'fit-content',
              padding: 8,
            }}
          >
            <li
              style={{ fontSize: '16px', lineHeight: '24px', color: '#B05A5A' }}
            >
              Current models:{' '}
              {currentModels.map((model) => model.name).join(', ')}
            </li>
          </ul>
          <FormControlLabel
            control={
              <Checkbox
                checked={tempDontAskAgain}
                onChange={handleDontAskAgainChange}
                sx={{
                  color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                  '&.Mui-checked': {
                    color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Figtree, sans-serif',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#858585',
                }}
              >
                Don't ask me again
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '12px' }}>
          <Button
            onClick={handlePreserveModels}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#464BBA' : '#464BBA',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#3C41A3' : '#3C41A3',
              },
            }}
          >
            Reselect Current Models
          </Button>
          <Button
            onClick={handleResetToDefaultModels}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#7F82C3' : '#D4D5E6',
              color: themeValue === 'dark' ? '#EFEFEF' : '#5256A6',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#6C6FB5' : 'inherit',
              },
            }}
          >
            Reset to Default
          </Button>
        </DialogActions>
      </Dialog>

      {/* Irreversible Confirmation Dialog */}
      <Dialog
        open={showIrreversibleDialog}
        onClose={handleIrreversibleCancel}
        disableEscapeKeyDown
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#F7F7F8',
            width: '655px',
            height: 'fit-content',
            borderRadius: '21px',
            padding: '32px',
            gap: '4px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Figtree, sans-serif',
            fontWeight: 700,
            fontSize: '28px',
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
          }}
        >
          Confirm Irreversible Action
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              marginBottom: '8px',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontFamily: 'Figtree, sans-serif',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            By selecting "Don't ask me again," this dialog will no longer appear
            and the system will automatically execute the last chosen option
            (Reselect or Reset). This action is irreversible. Are you sure you
            want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '12px' }}>
          <Button
            onClick={handleIrreversibleCancel}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#7F82C3' : '#D4D5E6',
              color: themeValue === 'dark' ? '#EFEFEF' : '#5256A6',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#6C6FB5' : 'inherit',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleIrreversibleConfirm}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#464BBA' : '#464BBA',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#3C41A3' : '#3C41A3',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatModelSelectorDialog;
