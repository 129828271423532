import { toast } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import useThemeStore from 'Theme/store';
import Modal from 'Components/Common/Modal/NewModal';

const DownloadOptionsDialog = ({
  open,
  onClose,
  message,
  api,
  availableTypes,
}) => {
  const { theme: themeValue } = useThemeStore();
  const [validatedContent, setValidatedContent] = useState({
    csv: { isValid: false, content: null },
    json: { isValid: false, content: null },
  });
  const [isValidating, setIsValidating] = useState(false);

  const getDetectedTypesText = () => {
    if (availableTypes.length === 1) {
      return availableTypes[0] === 'csv' ? 'a Markdown table' : 'JSON content';
    }
    return 'both a Markdown table and JSON content';
  };

  useEffect(() => {
    const validateContent = async () => {
      if (!open) return;
      setIsValidating(true);
      const newValidatedContent = {
        csv: { isValid: false, content: null },
        json: { isValid: false, content: null },
      };

      for (const type of availableTypes) {
        if (type === 'csv') {
          const response = await api.post('/auth/toCsv', {
            markdownText: message.content,
          });
          newValidatedContent.csv = {
            isValid: response.data.csvContent !== 'NO_TABLE_FOUND',
            content: response.data.csvContent,
          };
        }
        if (type === 'json') {
          const response = await api.post('/auth/toJson', {
            markdownText: message.content,
          });
          newValidatedContent.json = {
            isValid: response.data.jsonContent !== 'NO_JSON_FOUND',
            content: response.data.jsonContent,
          };
        }
      }

      setValidatedContent(newValidatedContent);
      setIsValidating(false);
      // Check if any content was successfully validated
      const hasValidContent = Object.values(newValidatedContent).some(
        (type) => type.isValid
      );

      if (!hasValidContent) {
        toast.custom(
          (t) => (
            <CustomToast
              id={t.id}
              message="No valid content could be extracted from this message."
            />
          ),
          { duration: 3000 }
        );
        onClose();
      }
    };

    toast.custom(
      (t) => (
        <CustomToast
          id={t.id}
          message="Different download methods will be available depending on the message's contents."
        />
      ),
      { duration: 4000 }
    );

    validateContent();
  }, [open, message, api, availableTypes]);

  const handleDownloadCSV = (csvContent) => {
    if (!csvContent) return;
    let rawContent = csvContent.replace(/\\n/g, '\n');
    const rows = rawContent
      .split('\n')
      .filter((row) => row.trim())
      .map((row) => row.split(',').map((cell) => cell.trim()));

    let csvData =
      'data:text/csv;charset=utf-8,\uFEFF' +
      rows.map((row) => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvData);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `table_data_${new Date().toISOString().split('T')[0]}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onClose();
  };

  const handleDownloadJSON = (jsonContent) => {
    if (!jsonContent) return;
    try {
      const jsonData = JSON.parse(jsonContent);
      const jsonString = JSON.stringify(
        jsonData.length > 1 ? { multiple_jsons: jsonData } : jsonData[0],
        null,
        2
      );

      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `json_data_${
        new Date().toISOString().split('T')[0]
      }.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      onClose();
    } catch (error) {
      console.error('Error processing JSON:', error);
    }
  };

  const allDownloadOptions = [
    {
      id: 'csv',
      label: isValidating ? 'Validating...' : 'Table to CSV',
      icon: '📊',
      handler: () => handleDownloadCSV(validatedContent.csv.content),
    },
    {
      id: 'json',
      label: isValidating ? 'Validating...' : 'JSON Document',
      icon: '🗂️',
      handler: () => handleDownloadJSON(validatedContent.json.content),
    },
  ];

  return (
    <Modal open={open} title="Download Options" onClose={onClose}>
      <div className="p-4 space-y-4">
        <p
          className={`text-center ${
            themeValue === 'dark' ? 'text-crystal-bell' : 'text-black'
          }`}
        >
          {isValidating
            ? `We detected ${getDetectedTypesText()} in this message. We're currently extracting it for download.`
            : `We detected ${getDetectedTypesText()} in this message and have prepared it for download. Press the button to confirm.`}
        </p>

        <div className="grid grid-cols-2 gap-4">
          {allDownloadOptions.map((option) => {
            const isAvailable = availableTypes.includes(option.id);
            const isValid = validatedContent[option.id].isValid;
            const isDisabled = !isAvailable || isValidating || !isValid;

            return (
              <button
                key={option.id}
                onClick={() => {
                  if (isValid) {
                    option.handler();
                  }
                }}
                disabled={isDisabled}
                className={`
                  flex items-center justify-center 
                  space-x-2 p-3 rounded-lg transition-colors
                  ${
                    !isDisabled
                      ? 'bg-violet-blue text-white font-bold hover:bg-violet-blue/90'
                      : 'bg-gray-100 opacity-50 cursor-not-allowed'
                  }
                `}
              >
                <span className="text-2xl">{option.icon}</span>
                <span className="font-bold">{option.label}</span>
              </button>
            );
          })}
        </div>

        <div className="text-center mt-4">
          <p
            className={`text-sm italic ${
              themeValue === 'dark' ? 'text-gray-400' : 'text-gray-500'
            }`}
          >
            More formats coming soon!
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadOptionsDialog;

export const hasJSONStructure = (text) => {
  if (!text || typeof text !== 'string') return false;
  const jsonIndicators = [
    // Must start and end with object
    /^\s*\{[\s\S]*\}\s*$/,
    // Must start and end with array
    /^\s*\[[\s\S]*\]\s*$/,
    // Must have valid property format
    /\s*"[^"]+"\s*:\s*(?:\{|\[|"|\d+)/,
  ];

  const containsJsonStructure = jsonIndicators.some((regex) =>
    regex.test(text)
  );
  try {
    JSON.parse(text);
    return true;
  } catch {
    return containsJsonStructure;
  }
};

export const hasTableStructure = (markdownText) => {
  if (!markdownText || typeof markdownText !== 'string') return false;

  // Basic checks for table indicators
  const hasPipes = markdownText.includes('|');
  const hasSeparator =
    markdownText.includes('|-') || markdownText.includes('| -');
  const hasMultipleLines = markdownText.split('\n').length >= 3;

  return hasPipes && hasSeparator && hasMultipleLines;
};

const CustomToast = ({ id, message }) => {
  const [isExiting, setIsExiting] = useState(false);
  const { theme: themeValue } = useThemeStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${isExiting ? 'toast-exit' : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeValue === 'dark' ? '#333' : '#fff',
        color: themeValue === 'dark' ? '#fff' : '#333',
        padding: '8px 16px',
        borderRadius: '8px',
        transition: 'opacity 0.5s ease, transform 0.5s ease',
        boxShadow:
          themeValue === 'dark'
            ? '0 4px 8px rgba(0, 0, 0, 0.7)'
            : '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <span>📖 {message}</span>
      <button
        onClick={() => {
          setIsExiting(true);
          setTimeout(() => toast.dismiss(id), 500);
        }}
        style={{
          marginLeft: '16px',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          color: themeValue === 'dark' ? '#fff' : '#333',
          fontSize: '12px',
          padding: '0',
          lineHeight: '1',
        }}
      >
        ✖️
      </button>
    </div>
  );
};
