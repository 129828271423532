import { useEffect, useMemo, useRef } from 'react';
// Tree view
import { Tree } from '@minoru/react-dnd-treeview';
// DND scrolling
import { useDndScrolling } from 'react-dnd-scrolling';
// Mobx
import { inject, observer } from 'mobx-react';
// Zustand store
import useTreeDNDTemplatesStore from './Store';
// Intersection Observer
import { InView } from 'react-intersection-observer';
// Components
import Loader from 'Components/Loader';
import { toast } from 'react-hot-toast';
import CustomNode from './Components/CustomNode';

const TreeDNDTemplates = inject('store')(
  observer(({ onOperationsTemplate, store, enableDnd = false }) => {
    const { treeData, setTreeData, page, setPage, totalPage, search, loading } =
      useTreeDNDTemplatesStore();

    const ref = useRef();
    const refTree = useRef(null);
    useDndScrolling(ref);

    const memoSortTreeData = useMemo(() => {
      return treeData;
    }, [treeData]);

    /**
     * Fetch move files and folders
     */
    const fecthMoveFilesAndFolders = async (draggedNode, parentId) => {
      try {
        const res = await store.api.post(`/template/folders/move`, {
          id: draggedNode.id,
          type: draggedNode.data?.type === 'template' ? 'template' : 'folder',
          parent_folder: parentId ?? null,
        });
        return res;
      } catch (err) {
        toast.error(err?.data?.message);
      }
    };

    const handleDrop = async (tree, options) => {
      const { dragSourceId, dropTargetId } = options;

      const draggedNode = tree.find((node) => node.id === dragSourceId);
      const destinationNode = tree.find((node) => node.id === dropTargetId);

      // If dragged to the same parent, do nothing
      if (destinationNode?.parent === draggedNode?.parent) {
        return;
      }

      const res = await fecthMoveFilesAndFolders(
        draggedNode,
        dropTargetId === 0 ? null : dropTargetId
      );

      if (res.status === 200) {
        setTreeData(tree);
      }
    };

    // Zustand store
    const handleOpen = (nodeId) => refTree.current.open(nodeId);

    // Open my tools folder
    useEffect(() => {
      handleOpen(1);
    }, []);

    return (
      <div
        className="h-full overflow-y-auto w-full overflow-x-hidden px-2"
        ref={ref}
        id="scrollableDivTreeDNDChat"
      >
        <Tree
          rootId={0}
          sort={false}
          ref={refTree}
          enableAnimateExpand
          initialOpen={search !== '' ? true : false}
          tree={memoSortTreeData}
          render={(node, { depth, isOpen, onToggle, handleRef }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              handleRef={handleRef}
              {...onOperationsTemplate}
            />
          )}
          onDrop={handleDrop}
          placeholderRender={(node, { depth }) => (
            <Placeholder node={node} depth={depth} />
          )}
          classes={{
            root: 'h-auto w-full select-none',
            draggingSource: 'opacity-30',
            dropTarget: 'bg-lavender rounded-md',
            placeholder: 'bg-violet-blue relative',
          }}
          // Prevent drag and drop
          canDrag={(e) => {
            // Enable drag and drop for chat folders
            if (!enableDnd) {
              return enableDnd;
            }
            // Prevent drag and drop for public folders
            if (e.data?.type === 'folder' && e.data?.public === true) {
              return false;
            } else if (
              ['my templates', 'shared with me'].includes(
                e.data?.name?.toLowerCase()
              )
            ) {
              return false;
            }
            // Prevent drag and drop for public templates
            else if (
              e.data?.type === 'template' &&
              e.data?.user !== store.profile._id
            ) {
              return false;
            } else {
              return true;
            }
          }}
          canDrop={(currentTree, { dropTarget }) => {
            // Prevent drag and drop for public folders
            if (
              dropTarget?.data?.type === 'folder' &&
              dropTarget?.data?.public === true
            ) {
              return false;
            }
            // Enable drag and drop for chat folders
            if (!enableDnd) {
              return false;
            }
          }}
        />
        {search === '' &&
          totalPage > page &&
          memoSortTreeData.length > 0 &&
          !loading && (
            <InView
              onChange={(inView) => {
                if (inView) {
                  setPage(page + 1);
                }
              }}
            >
              <div className="flex items-center justify-center">
                <h2>..</h2>
              </div>
            </InView>
          )}
        {loading && page !== 1 && (
          <div className="flex items-center justify-center mt-2">
            <Loader />
          </div>
        )}
        {page === totalPage && totalPage > 1 && (
          <div className="flex items-center justify-center mt-2">
            <h2>End chat list</h2>
          </div>
        )}
      </div>
    );
  })
);

const FoldersTemplates = ({ onOperationsTemplate, enableDnd }) => {
  return (
    <TreeDNDTemplates
      onOperationsTemplate={onOperationsTemplate}
      enableDnd={enableDnd}
    />
  );
};

export const Placeholder = (props) => (
  <div
    className="bg-violet-blue absolute top-0 right-0 h-[10px]"
    style={{
      left: props.depth * 24,
      transform: 'translateY(-50%)',
    }}
    data-testid="placeholder"
  ></div>
);

export default FoldersTemplates;
