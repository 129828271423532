import {
  InformationCircleIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  RectangleGroupIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  AdjustmentsHorizontalIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/solid';
import {
  Badge,
  Divider,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import BadgeInformation from 'Features/ModelsModal/components/BadgeInformation';
import BlurTooltip from 'Features/ModelsModal/components/blurTooltip';
import FilterButton from 'Features/ModelsModal/components/filterButton';
import { useSelectedFiltersCount } from 'Features/ModelsModal/hooks/useSelectedFiltersCount';
import {
  APPLICATIONS_MAP,
  CAPABILITIES_MAP,
  FEATURES_MAP,
  OTHERS_MAP,
} from 'Features/ModelsModal/mocks/constants';
import { sortItems } from 'Features/ModelsModal/mocks/itemsSort';
import useModelsModalStore from 'Features/ModelsModal/store';
import { getSortIcon } from 'Features/ModelsModal/utils';
import usePopoverState from 'Hooks/usePopoverState';
import { useCallback } from 'react';
import { CrownIcon } from 'Theme/icons/crown';

const FiltersContainer = () => {
  const state = useModelsModalStore();
  const {
    search,
    setSearch,
    layout,
    setLayout,
    sort,
    setSort,
    favorites,
    other: others,
    editorsChoice,
    features,
    capabilities,
    applications,
    toggleFavorites,
    toggleEditorsChoice,
    toggleFilter,
    clearAll,
  } = state;
  const countFilters = useSelectedFiltersCount(state);

  // Search manage
  const handleChangeSearch = useCallback((value) => setSearch(value), []);

  const onChangeSearch = (e) => {
    handleChangeSearch(e.target.value); // Pasar solo el valor, no el evento completo
  };

  const handleChangeLayout = (event, newLayout) => {
    setLayout(newLayout);
  };

  // Popover state
  const filterPopover = usePopoverState('filter-popover');
  const sortPopover = usePopoverState('sort-popover');
  return (
    <>
      <div className="flex justify-between items-center mt-3 flex-col md:flex-row gap-2 md:gap-0">
        <div className="flex-1 max-w-[500px]">
          <div className="mx-auto">
            <div className="relative">
              <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={onChangeSearch}
                className="w-full pl-12 pr-3 py-1 rounded-xl border border-cool-gray  shadow-sm focus:outline-none focus:ring-2 focus:ring-nue-blue
                bg-transparent dark:bg-night-black focus:border-transparent transition-all text-gray-600  dark:text-crystal-bell placeholder-gray-400"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <Badge badgeContent={countFilters} color="primary">
            <FilterButton
              text="Filters"
              onClick={filterPopover.handleClick}
              aria-describedby={filterPopover.id}
              icon={AdjustmentsHorizontalIcon}
            />
          </Badge>
          <Popover
            id={filterPopover.id}
            open={filterPopover.open}
            anchorEl={filterPopover.anchorEl}
            onClose={filterPopover.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              mt: 1,
            }}
          >
            <div className="w-full max-w-md space-y-2 p-[10px] text-raisin-black dark:text-crystal-bell">
              {/* Favorites */}
              <div className="grid grid-cols-2 items-center justify-between">
                <span className="text-sm">Favorites</span>
                <button
                  onClick={toggleFavorites}
                  className={`p-2 rounded-full transition-colors duration-200 w-fit justify-self-end
            ${favorites ? 'text-nue-blue' : 'text-gray-400'}
            hover:bg-gray-100`}
                >
                  <StarIcon
                    className="w-5 h-5"
                    fill={favorites ? 'currentColor' : 'none'}
                  />
                </button>
              </div>
              <Divider flexItem />
              {/* Editor's choice */}
              <div className="grid grid-cols-2 items-center justify-between">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Handpicked models recommended by Straico for their high-quality performance and outputs. Ideal for users seeking reliable, versatile options."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Editor's choice
                </span>
                <button
                  onClick={toggleEditorsChoice}
                  className={`px-3 py-1 rounded-full text-sm font-medium
            ${
              editorsChoice
                ? 'bg-lavender text-nue-blue'
                : ' text-gray-600 bg-platinum'
            }
            hover:opacity-80 transition-colors duration-200`}
                >
                  <div className="flex items-center justify-center gap-1">
                    <CrownIcon className="size-4" />
                    <p>EDITOR'S CHOICE</p>
                  </div>
                </button>
              </div>
              <Divider flexItem />
              {/* Features */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Built-in abilities that a model provides independently, without relying on external services."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Features
                </span>
                <div className="flex flex-wrap gap-2">
                  {FEATURES_MAP.map(({ feature, icon, label }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'feature'}
                        label={label}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          features.includes(feature) ? 'image' : 'disabled'
                        }
                        onClick={() => toggleFilter('features', feature)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />
              {/* Capabilities */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Optional enhancements you can enable for compatible models. These rely on external services, incurring additional interaction costs when activated."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Capabilities
                </span>
                <div className="flex flex-wrap gap-2">
                  {CAPABILITIES_MAP.map(
                    ({ capability, icon, label }, index) => {
                      const Icon = icon;
                      return (
                        <BadgeInformation
                          key={index + 'capability'}
                          label={label}
                          className="cursor-pointer"
                          icon={<Icon className="size-4" />}
                          variant={
                            capabilities.includes(capability)
                              ? 'browsing'
                              : 'disabled'
                          }
                          onClick={() =>
                            toggleFilter('capabilities', capability)
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <Divider flexItem />
              {/* Applications */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Tags highlighting the specific types of tasks or use cases a model excels in, helping you find the right fit for your needs."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Applications
                </span>
                <div className="flex flex-wrap gap-2">
                  {APPLICATIONS_MAP.map(({ application: app, icon }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'app'}
                        label={app}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          applications.includes(app) ? 'default' : 'disabled'
                        }
                        onClick={() => toggleFilter('applications', app)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />
              {/* Other */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    color="blur"
                    title="Miscellaneous attributes such as uncensored, deprecated, or unavailable, covering cases outside standard categories."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Other
                </span>
                <div className="flex flex-wrap gap-2">
                  {OTHERS_MAP.map(({ icon, other }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'other'}
                        label={other}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          others?.includes(other) ? 'browsing' : 'disabled'
                        }
                        onClick={() => toggleFilter('other', other)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />
              {/* Clear All */}
              <button
                onClick={clearAll}
                className="w-fit text-red-600 text-sm font-medium hover:text-red-700 transition-colors duration-200"
              >
                Clear All
              </button>
            </div>
          </Popover>
          <FilterButton
            text="Sort"
            icon={ArrowsUpDownIcon}
            aria-describedby={sortPopover.id}
            onClick={sortPopover.handleClick}
          />
          <Popover
            id={sortPopover.id}
            open={sortPopover.open}
            anchorEl={sortPopover.anchorEl}
            onClose={sortPopover.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              mt: 1,
            }}
          >
            <div className="w-full max-w-md rounded-lg bg-card p-1 ">
              {sortItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setSort(item.field)}
                  className="flex w-full items-center justify-between rounded-md px-2 py-1 text-sm hover:bg-nue-blue hover:bg-opacity-15 hover:text-accent-foreground"
                >
                  <span>{item.label}</span>
                  <span className="ml-2">{getSortIcon(item.field, sort)}</span>
                </button>
              ))}
            </div>
          </Popover>
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={layout}
            onChange={handleChangeLayout}
          >
            <ToggleButton value="grid">
              <RectangleGroupIcon className="size-5" />
            </ToggleButton>
            <ToggleButton value="table">
              <ListBulletIcon className="size-5" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

export default FiltersContainer;
