import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import useIsMobile from 'Hooks/useIsMobile';
import useThemeStore from 'Theme/store';

const CapabilitiesContainer = ({
  capabilities = [],
  isActive,
  handleCapabilities,
  getIcon,
  getCompability,
  getUncompability,
}) => {
  const isMobile = useIsMobile();
  const { theme } = useThemeStore();
  const isDark = theme === 'dark';
  return (
    <>
      <div className="my-4 overflow-auto">
        {capabilities.map((capability, index) => (
          <div
            key={index}
            className={`${isDark ? 'text-white' : 'text-black'}`}
          >
            {index === 0 && (
              <div
                className={`grid ${
                  isMobile
                    ? 'grid-cols-[repeat(4,1fr)]'
                    : 'grid-cols-[repeat(8,1fr)]'
                } gap-x-2 items-center justify-between w-full  p-1 rounded select-none ${
                  isDark ? 'text-white' : 'text-black'
                } `}
              >
                <div className={`font-bold text-xs  col-span-3`}>
                  Capability
                </div>
                {!isMobile && (
                  <>
                    <div className={`font-bold text-xs col-span-2`}>
                      Compatible with selected
                    </div>
                    <div className={`font-bold text-xs col-span-2`}>
                      Incompatible with selected
                    </div>
                  </>
                )}
                <div className={`font-bold text-xs`}>Coins per prompt</div>
              </div>
            )}
            <div
              className={`grid ${
                isMobile
                  ? 'grid-cols-[repeat(4,1fr)]'
                  : 'grid-cols-[repeat(8,1fr)]'
              } gap-x-2 items-center justify-between w-full  p-1 rounded select-none`}
            >
              <div
                className={`col-span-3 flex gap-2 font-semibold text-sm
                 ${
                   isActive(capability)
                     ? 'dark:bg-ship-grey bg-lavender'
                     : 'dark:bg-night-black bg-ghost-white'
                 } rounded-xl px-3 py-4 cursor-pointer`}
                onClick={() => handleCapabilities(capability)}
              >
                <div
                  className={
                    isActive(capability) &&
                    'dark:text-stargate-shimmer text-violet-blue'
                  }
                >
                  {getIcon(capability.icon)}
                </div>
                <div className="flex-1">
                  <div
                    className={`text-base ${
                      isActive(capability) &&
                      'dark:text-stargate-shimmer text-violet-blue'
                    }`}
                  >
                    {capability.name}
                  </div>
                  <div className="font-normal text-sm">
                    <div
                      className="font-normal text-sm"
                      dangerouslySetInnerHTML={{
                        __html: capability.description,
                      }}
                    />
                  </div>
                </div>
              </div>
              {!isMobile && (
                <>
                  <div
                    className={`h-full text-xs col-span-2 gap-1 flex flex-col`}
                  >
                    {getCompability(capability)}
                  </div>
                  <div className="h-full text-xs text-pottery-red col-span-2 gap-1 flex flex-col">
                    {getUncompability(capability)}
                  </div>
                </>
              )}
              <div className="w-full text-xs">
                <div
                  className={`text-base leading-[14px] font-normal
                  flex gap-2 items-center`}
                >
                  <CurrencyDollarIcon className="w-5 h-5 text-cool-gray" />
                  <div>{capability.coins}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CapabilitiesContainer;
