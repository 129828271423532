/* eslint-disable no-console */
import {
  ArrowDownOnSquareIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  SparklesIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@mui/material/index';
import { Tooltip } from '@mui/material';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from './Button';
import FileUpload from './Forms/FileUpload';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import InfiniteScroll from 'react-infinite-scroll-component';

import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

//New file icons

import FileExcel from 'Theme/icons/fileIcons/fileexcel';
import FilePDF from 'Theme/icons/fileIcons/filepdf';
import FilePowerPoint from 'Theme/icons/fileIcons/filepowerpoint';
import FileVideo from 'Theme/icons/fileIcons/filevideo';
import FileWord from 'Theme/icons/fileIcons/fileword';
import FileMusic from 'Theme/icons/fileIcons/musicfile';
import FileText from 'Theme/icons/fileIcons/textfile';
import WebFile from 'Theme/icons/fileIcons/webfile';
import FilesIcon from 'Theme/icons/filesIcon';
import WebPageIcon from 'Theme/icons/webPageIcon';
import YoutubeIcon from 'Theme/icons/youtubeIcon';

import useThemeStore from 'Theme/store';

export const getIconFile = (type, file, theme = 'light') => {
  let iconComponent;

  switch (type) {
    case 'pdf':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FilePDF color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'youtube':
      iconComponent = (
        <div className="w-16 h-10 flex items-center justify-center">
          <img
            src={file.thumbnail}
            alt="YouTube Thumbnail"
            className="w-full h-full object-cover"
          />
        </div>
      );
      break;
    case 'webpage':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <WebFile color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'docx':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileWord color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'pptx':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FilePowerPoint color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'xlsx':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileExcel color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'txt':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileText color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'mp3':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileMusic color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'mp4':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileVideo color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'image':
      iconComponent = (
        <div className="w-16 flex items-center justify-center">
          {file.thumbnail ? (
            <img
              src={file.thumbnail}
              className="w-10 h-10 object-contain"
              alt="Image"
            />
          ) : (
            <PhotoIcon className="text-raisin-black w-7 h-7" />
          )}
        </div>
      );
      break;
    case 'md':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileText color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;

    // Temporal icon for HTML, JSON, CSV
    case 'html':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileText color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'json':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileText color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;
    case 'csv':
      iconComponent = (
        <div className="w-7 h-7 flex items-center justify-center">
          <FileText color={theme == 'dark' ? '#EFEFEF' : '#211E21'} />
        </div>
      );
      break;

    default:
      iconComponent = (
        <div className="w-16 h-7 flex items-center justify-center">N</div>
      );
  }
  return (
    <div className="w-16 flex items-center justify-center">{iconComponent}</div>
  );
};

export const getLabel = (type, size) => {
  let label;
  if (
    type == 'pdf' ||
    type == 'docx' ||
    type == 'xlsx' ||
    type == 'pptx' ||
    type == 'txt' ||
    type == 'html' ||
    type == 'json' ||
    type == 'csv' ||
    type == 'md'
  ) {
    let value;
    if (size > 0) {
      value = Math.round((size / (1024 * 1024)) * 100) / 100;
      // Calculate the size of a file in MB (rounded to two decimal places)
      if (type === 'md') {
        const lines = countMarkdownLines(size);
        label = `${value} MB (${lines} lines)`;
      } else {
        label = value + ' MB';
      }
    }
  } else if (type == 'youtube' || type == 'mp3' || type == 'mp4') {
    let minutes = Math.floor(size / 60);
    let remainingSeconds = size % 60;
    let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    let secondsStr =
      remainingSeconds < 10
        ? '0' + remainingSeconds
        : remainingSeconds.toString();
    label = minutesStr + ':' + secondsStr;
  } else if (type == 'webpage') {
    label = size.toLocaleString() + ' words';
  } else if (type == 'image') {
    let value;
    if (size > 0) {
      value = Math.round((size / (1024 * 1024)) * 100) / 100;
    }
    label = value + ' MB';
  } else {
    label = '-';
  }
  return label;
};
const countMarkdownLines = (size) => {
  // Aproximadamente 50 bytes por línea en promedio
  return Math.ceil(size / 50);
};
export const formatedDate = (date, format = null) => {
  const mDate = moment.utc(date).local();
  return mDate.format(format ? format : 'DD/MM/YY, hh:mm a');
};

const SkeletonCard = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      className={`w-md flex w-full items-center justify-between flex-row ${
        themeValue == 'dark' ? 'bg-black' : 'bg-white'
      } rounded-lg overflow-hidden`}
      style={{ paddingLeft: '1.3rem' }}
    >
      <div className="bg-cool-gray dark:bg-quicksilver h-8 w-10 animate-pulse"></div>
      <div className="px-4 py-2 w-full">
        <div className="bg-cool-gray dark:bg-quicksilver h-4 w-1/2 mb-2 animate-pulse"></div>
        <div className="bg-cool-gray dark:bg-quicksilver h-4 w-1/3 animate-pulse"></div>
      </div>
    </div>
  );
};

const FileContext = inject('store')(
  observer(
    ({
      store,
      onInteraction,
      onClose,
      models,
      onBadInteraction,
      defaultTab,
      onlySelectedOne,
    }) => {
      const [fileSelected, setFileSelected] = useState([]);
      const [files, setFiles] = useState([]);
      const [selectedIndex, setSelectedIndex] = useState([]);

      const handleUploadedFiles = (updatedFiles) => {
        setFileSelected(updatedFiles);
      };

      const [searchTerm, setSearchTerm] = useState('');

      const [hasMore, setHasMore] = useState(true);

      const [page, setPage] = useState(1);

      const [filesTypeRules, setFileTypeRules] = useState([]);

      const [value, setValue] = useState(defaultTab ?? '1');

      const navigate = useNavigate();

      const { theme } = useThemeStore();

      const handleChange = (event, newValue) => {
        setValue(newValue);
        setFileSelected([]);
      };

      const noVision = () => {
        return models?.every((model) => model.type != 'vision');
      };

      const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setFileSelected([]);
        setSelectedIndex([]);
      };

      const handleNext = () => {
        getFiles(page + 1);
      };

      const scrollToTop = () => {
        const container = document.getElementsByClassName(
          'infinite-scroll-component'
        )[0];
        if (container) {
          container.scrollTop = 0;
        }
      };

      const getFiles = async (page) => {
        try {
          setPage(page);
          const response = await store.api.get(`/file`, {
            params: {
              page: page,
              limit: 6,
              search: searchTerm,
            },
          });
          if (response.data.success) {
            if (response.data.pagination.currentSearch) {
              if (page == 1) {
                setFiles(response.data.files);
              } else {
                setFiles([...files, ...(response.data.files || [])]);
              }
            } else {
              if (page == 1) {
                setFiles(response.data.files);
              } else {
                setFiles([...files, ...(response.data.files || [])]);
              }
            }
            setHasMore(response.data.pagination.next);
          }

          if (store.profile.plan == 'free') {
            const res = await store.api.get('/user/constant', {
              params: {
                type: 'princing_plans',
              },
            });
            setFileTypeRules(res.data.rules[0].files_type);
          }
        } catch (error) {
          console.log(error);
        }
      };

      useEffect(() => {
        getFiles(1);
        scrollToTop();
      }, [searchTerm]);

      const updateVisibilityFile = async (file) => {
        try {
          const response = await store.api.put(`/file/visible`, {
            hash: file._id,
            visible: !file.visible,
          });
          if (response.data.success) {
            getFiles(1);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const getFreeAd = () => {
        return (
          <>
            {store.profile.plan == 'free' && (
              <div className=" fixed font-figtree justify-center top-40 right-1 h-[50%] m-auto flex flex-col items-center  px-20 z-10 align-middle">
                <SparklesIcon className="h-6 flex justify-center w-full mb-5" />
                <div className="text-center not-italic text-raisin-black dark:text-crystal-bell text-sm font-bold uppercase pb-3">
                  {store.profile.trial_end
                    ? 'Looking for premium benefits?'
                    : 'Ready for a power-up?'}
                </div>
                {store.profile.trial_end ? (
                  <div className="text-center not-italic text-raisin-black dark:text-crystal-bell text-sm font-medium pb-5">
                    <a
                      href="/my-profile/pricing"
                      className="text-violet-blue text-sm font-semibold underline"
                    >
                      Upgrade
                    </a>{' '}
                    to a paid subscription to query on your uploaded media and
                    keep unlocking your AI superpowers.
                  </div>
                ) : (
                  <div className="text-center not-italic text-raisin-black dark:text-crystal-bell text-sm font-medium pb-5">
                    Gain immediate access to the media uploading feature and
                    others with our{' '}
                    <a
                      href="/my-profile/pricing"
                      className="text-violet-blue text-sm font-semibold underline"
                    >
                      {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial.
                    </a>
                    for just $1.
                  </div>
                )}
                <div className="flex justify-center w-full">
                  <button
                    type="button"
                    className="bg-nue-blue shadow-md rounded py-1 px-3 text-white font-bold text-center text-sm uppercase flex"
                    onClick={() => {
                      navigate('/my-profile/pricing');
                    }}
                  >
                    <StarIcon className="w-5 h-5  mr-2" />{' '}
                    {store.profile.trial_end
                      ? 'Upgrade now'
                      : `try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`}
                  </button>
                </div>
              </div>
            )}
          </>
        );
      };

      return (
        <>
          <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 transition-opacity" onClick={onClose}>
              <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
            </div>

            <div
              className={`relative font-figtree ${
                theme == 'dark' ? 'bg-lead' : 'bg-seasalt'
              } rounded-[21px] shadow-xl transform transition-all max-w-[530px] w-full max-h-full`}
            >
              <div className={`p-7 h-min`}>
                <div className="text-raisin-black dark:text-crystal-bell text-2xl leading-7 font-bold">
                  Your attachments
                </div>

                {/* Close button */}
                <div className="absolute right-[6px] top-[6px] group">
                  <IconButton onClick={onClose}>
                    <XMarkIcon
                      className={`w-3 h-3 ${
                        theme == 'dark' ? 'text-quicksilver' : 'text-cool-gray'
                      }`}
                    />
                  </IconButton>
                </div>

                <Box sx={{ width: '100%' }}>
                  <TabContext value={value}>
                    {/* Tabs */}
                    <TabList
                      onChange={handleChange}
                      orientation="horizontal"
                      variant="scrollable"
                    >
                      <Tab
                        value="1"
                        label="Your files"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          textTransform: 'none',
                        }}
                        icon={<FilesIcon />}
                      />
                      <Tab
                        value="2"
                        label="Upload"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          textTransform: 'none',
                        }}
                        icon={<ArrowUpTrayIcon className="w-5 h-5" />}
                      />
                      <Tab
                        value="0"
                        label="Image"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          textTransform: 'none',
                        }}
                        icon={<PhotoIcon className="w-5 h-5" />}
                      />
                      <Tab
                        value="4"
                        label="Youtube"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          textTransform: 'none',
                        }}
                        icon={<YoutubeIcon />}
                      />
                      <Tab
                        value="3"
                        label="Web page"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          textTransform: 'none',
                        }}
                        icon={<WebPageIcon />}
                      />
                    </TabList>

                    {/* Panel 0 */}
                    <TabPanel value="0" sx={{ padding: '12px 0 0 0' }}>
                      <div
                        className={`${
                          theme == 'dark'
                            ? 'text-crystal-bell'
                            : 'text-raisin-black'
                        } text-base font-medium mb-2`}
                      >
                        Upload an Image
                      </div>
                      <div className="relative">
                        <div
                          className={`${
                            noVision() && 'opacity-20 blur-[1px] select-none'
                          }`}
                        >
                          <FileUpload
                            onUploaded={(uploadedFiles) => {
                              // Log the files
                              console.log(uploadedFiles);

                              // Assuming uploadedFiles is an array of file objects
                              const filesWithImageType = uploadedFiles.map(
                                (file) => ({
                                  ...file,
                                  type: 'image', // Add the type 'image' to each file object.
                                })
                              );

                              // Merge the new files with the image type with the already selected files
                              setFileSelected((fileSelected) => [
                                ...filesWithImageType,
                              ]);
                            }}
                            type=".png, .jpeg, .jpg, .webp, .gif"
                          />
                        </div>
                        {noVision() && (
                          <div
                            className={`absolute px-4 top-0 left-0 ${
                              theme == 'dark'
                                ? 'text-crystal-bell'
                                : 'text-raisin-black'
                            } w-full h-full flex justify-center items-center select-none`}
                          >
                            <SparklesIcon className="h-6 w-6 mr-5" />
                            <div
                              className={`text-center not-italic ${
                                theme == 'dark'
                                  ? 'text-crystal-bell'
                                  : 'text-raisin-black'
                              }`}
                            >
                              Choose a{' '}
                              <span
                                onClick={() =>
                                  onBadInteraction({
                                    reason: 'bad model',
                                  })
                                }
                                className="text-nue-blue font-semibold underline cursor-pointer"
                              >
                                model (LLM) like GPT-4o - (Nov-20)
                              </span>{' '}
                              to enable image uploads.
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-raisin-black dark:text-crystal-bell text-xs font-normal mt-2">
                        Images you upload will be read as input for your
                        queries.
                      </div>
                      <div className={`flex justify-end w-full`}>
                        <Button
                          Icon={ArrowDownOnSquareIcon}
                          title={'Insert'}
                          disabled={fileSelected.length <= 0}
                          onClick={() => onInteraction(fileSelected)}
                          iconSize="w-[18px] h-[18px]"
                          customRound="rounded-xl"
                          customPaddig="px-4 py-2"
                          customWeight="font-semibold"
                          noUppercase
                        />
                      </div>
                    </TabPanel>

                    {/* Panel 1 */}
                    <TabPanel value="1" sx={{ padding: '12px 0 0 0' }}>
                      <div className="">
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-base font-medium mb-2`}
                        >
                          Choose from your previous uploads
                        </div>
                        <TextField
                          label="Search files"
                          variant="outlined"
                          value={searchTerm}
                          onChange={handleInputChange}
                          fullWidth
                          sx={{ mt: 1, mb: 2 }}
                          InputProps={{
                            sx: {
                              height: '34px',
                              fontSize: '14px',
                              '& .MuiOutlinedInput-input': {
                                padding: '12px 14px',
                              },
                              '& .MuiInputLabel-outlined': {
                                transform: 'translate(14px, 10px) scale(1)',
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: '14px',
                              transform: 'translate(14px, 6px) scale(1)',
                              '&.MuiInputLabel-shrink': {
                                transform: 'translate(14px, -6px) scale(0.75)',
                              },
                            },
                          }}
                        />

                        {files.length > 0 && (
                          <>
                            <InfiniteScroll
                              dataLength={files.length}
                              next={() => {
                                handleNext();
                              }}
                              height={300}
                              hasMore={hasMore}
                              loader={
                                <>
                                  <div className="flex gap-3 flex-col mt-3 w-full ">
                                    {[1, 2, 3, 4, 5, 6].map((index) => (
                                      <SkeletonCard key={index} />
                                    ))}
                                  </div>
                                </>
                              }
                              endMessage={
                                <p className="text-center mt-6 mb-3 text-raisin-black dark:text-crystal-bell">
                                  Congratulations, you've seen all your files!
                                </p>
                              }
                              className={`overflow-rtl ${
                                theme == 'dark'
                                  ? 'overflow-rtl-dark'
                                  : 'overflow-rtl-light'
                              }`}
                            >
                              {files.map((file, index) => (
                                <div
                                  key={index}
                                  className="p-1 border-b border-b-platinum relative"
                                  onClick={() => {
                                    if (!(noVision() && file.type == 'image')) {
                                      if (onlySelectedOne) {
                                        if (!selectedIndex.includes(index)) {
                                          setSelectedIndex([index]);
                                          setFileSelected([
                                            {
                                              ...files[index],
                                              enabled: true,
                                            },
                                          ]);
                                        } else {
                                          setSelectedIndex(
                                            selectedIndex.filter(
                                              (item) => item !== index
                                            )
                                          );
                                          setFileSelected(
                                            fileSelected.filter(
                                              (file2) => file._id !== file2._id
                                            )
                                          );
                                        }
                                      } else {
                                        if (!selectedIndex.includes(index)) {
                                          setSelectedIndex([
                                            ...selectedIndex,
                                            index,
                                          ]);
                                          setFileSelected([
                                            ...fileSelected,
                                            {
                                              ...files[index],
                                              enabled: true,
                                            },
                                          ]);
                                        } else {
                                          setSelectedIndex(
                                            selectedIndex.filter(
                                              (item) => item !== index
                                            )
                                          );
                                          setFileSelected(
                                            fileSelected.filter(
                                              (file2) => file._id !== file2._id
                                            )
                                          );
                                        }
                                      }
                                    }
                                  }}
                                >
                                  <div
                                    className={`flex items-center cursor-pointer justify-between w-full ${
                                      selectedIndex != null &&
                                      selectedIndex.includes(index)
                                        ? 'bg-lavender dark:bg-ship-grey font-medium'
                                        : 'font-normal'
                                    } ${
                                      noVision() &&
                                      file.type == 'image' &&
                                      'opacity-20 dark:opacity-10 blur-[1px] select-none'
                                    } p-1 rounded`}
                                  >
                                    {!selectedIndex.includes(index) ? (
                                      getIconFile(file.type, file, theme)
                                    ) : (
                                      <div
                                        className={
                                          'w-16 flex items-center justify-center'
                                        }
                                      >
                                        <CheckCircleIcon
                                          color={'#464BBA'}
                                          className={'w-7 h-7'}
                                        />
                                      </div>
                                    )}
                                    <div className={`ml-2 text-sm`}>
                                      <p
                                        className={`block whitespace-nowrap truncate w-[120px] overflow-hidden ${
                                          theme == 'dark'
                                            ? 'text-crystal-bell'
                                            : 'text-raisin-black'
                                        }`}
                                      >
                                        {file.name}
                                      </p>
                                    </div>
                                    <div
                                      className={`hidden md:block ml-2 ${
                                        theme == 'dark'
                                          ? 'text-crystal-bell'
                                          : 'text-raisin-black'
                                      } text-sm`}
                                    >
                                      {getLabel(file.type, file.size)}
                                    </div>
                                    <div
                                      className={`hidden md:block ml-2 ${
                                        theme == 'dark'
                                          ? 'text-crystal-bell'
                                          : 'text-raisin-black'
                                      } text-sm`}
                                    >
                                      {formatedDate(file.created, 'DD/MM/YYYY')}
                                    </div>
                                    <div
                                      className={`ml-1 ${
                                        theme == 'dark'
                                          ? 'text-crystal-bell'
                                          : 'text-raisin-black'
                                      } text-sm flex items-center gap-1`}
                                    >
                                      <Tooltip
                                        title="Download file"
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={(e) => e.stopPropagation()}
                                          component="a"
                                          target="_blank"
                                          href={file.url}
                                        >
                                          <ArrowDownTrayIcon
                                            className={`w-4 h-4 ${
                                              theme == 'dark'
                                                ? 'text-crystal-bell'
                                                : 'text-raisin-black'
                                            }`}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete file"
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateVisibilityFile(file);
                                          }}
                                        >
                                          <TrashIcon
                                            className={`w-4 h-4 ${
                                              theme == 'dark'
                                                ? 'text-crystal-bell'
                                                : 'text-raisin-black'
                                            }`}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  {noVision() && file.type == 'image' && (
                                    <div className="absolute top-0 left-0 text-black dark:text-white w-full h-full flex justify-center items-center select-none">
                                      <SparklesIcon
                                        className={`h-6 w-6 mr-5 ${
                                          theme == 'dark'
                                            ? 'text-crystal-bell'
                                            : 'text-raisin-black'
                                        }`}
                                      />
                                      <div
                                        className={`text-center not-italic ${
                                          theme == 'dark'
                                            ? 'text-crystal-bell'
                                            : 'text-raisin-black'
                                        }`}
                                      >
                                        Can't add images in this{' '}
                                        <span
                                          onClick={() =>
                                            onBadInteraction({
                                              reason: 'bad model',
                                            })
                                          }
                                          className="text-violet-blue font-semibold underline cursor-pointer"
                                        >
                                          Model (LLM)
                                        </span>
                                        .
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </InfiniteScroll>
                            <div className={`flex justify-end w-full`}>
                              <Button
                                Icon={ArrowDownOnSquareIcon}
                                title={'Insert'}
                                disabled={fileSelected.length <= 0}
                                onClick={() => onInteraction(fileSelected)}
                                iconSize="w-[18px] h-[18px]"
                                customRound="rounded-xl"
                                customPaddig="px-4 py-2"
                                customWeight="font-semibold"
                                noUppercase
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </TabPanel>

                    {/* TODO Panel 2 - Local Files (.mp3, .mp4, .doc, .xlx, .ppt, .txt, .pdf, .html, .csv, .json, .py, .php, .js, .css, .cs, .swift, .kt) */}
                    <TabPanel value="2" sx={{ padding: '12px 0 0 0' }}>
                      {store.profile.plan == 'free' &&
                        !filesTypeRules.upload &&
                        getFreeAd()}
                      <div
                        className={`${
                          store.profile.plan == 'free' &&
                          !filesTypeRules.upload &&
                          'opacity-30 blur-[1px] pb-4'
                        }`}
                      >
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-base font-medium mb-2`}
                        >
                          Upload a file
                        </div>
                        <FileUpload
                          onUploaded={handleUploadedFiles}
                          type={`.mp3, .mp4, .pdf, .docx, .txt, .pptx, .xlsx, .html, .csv, .json, .py, .php, .js, .css, .cs, .swift, .kt, .xml, .ts, .md`}
                        />
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-xs font-normal mt-2`}
                        >
                          You can upload files in .pdf, .docx, .pptx, .txt, .md,
                          .xlsx, .mp3, .mp4, .html, .csv, .json, .py, .php, .js,
                          .css, .cs, .swift, .ts, .xml and .kt format. According
                          to the selected model, the query over the file takes
                          60% of the input words from the model's capacity.
                        </div>
                        <div className={`flex justify-end w-full`}>
                          <Button
                            Icon={ArrowDownOnSquareIcon}
                            title={'Insert'}
                            disabled={fileSelected.length <= 0}
                            onClick={() => onInteraction(fileSelected)}
                            iconSize="w-[18px] h-[18px]"
                            customRound="rounded-xl"
                            customPaddig="px-4 py-2"
                            customWeight="font-semibold"
                            noUppercase
                          />
                        </div>
                      </div>
                    </TabPanel>

                    {/* TODO Panel 3 - WEB page */}
                    <TabPanel value="3" sx={{ padding: '12px 0 0 0' }}>
                      {store.profile.plan == 'free' &&
                        !filesTypeRules.web &&
                        getFreeAd()}
                      <div
                        className={`${
                          store.profile.plan == 'free' &&
                          !filesTypeRules.web &&
                          'opacity-30 blur-[1px] py-4'
                        }`}
                      >
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-base font-medium mb-3`}
                        >
                          Upload a web page
                        </div>

                        <div className="flex flex-col flex-1 mt-1">
                          <input
                            value={
                              fileSelected.length > 0 ? fileSelected[0] : ''
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              setFileSelected([value]);
                            }}
                            type="text"
                            placeholder="Web page link"
                            className={`rounded-md px-4 py-2 ${
                              theme == 'dark'
                                ? 'bg-night-black text-crystal-bell '
                                : 'bg-white text-raisin-black'
                            } border-cool-gray border-2 inline-block focus:border-nue-blue text-sm font-medium placeholder-cool-gray`}
                          />
                        </div>

                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-xs font-normal mt-2`}
                        >
                          Include the full url (including https://). According
                          to the selected model, the query over the analyzed web
                          page takes 60% of the input words from the model's
                          capacity.
                        </div>
                        <div className={`flex justify-end w-full`}>
                          <Button
                            Icon={ArrowDownOnSquareIcon}
                            title={'Insert'}
                            disabled={fileSelected.length <= 0}
                            onClick={() => onInteraction(fileSelected)}
                            iconSize="w-[18px] h-[18px]"
                            customRound="rounded-xl"
                            customPaddig="px-4 py-2"
                            customWeight="font-semibold"
                            noUppercase
                          />
                        </div>
                      </div>
                    </TabPanel>

                    {/* TODO Youtube */}
                    <TabPanel value="4" sx={{ padding: '12px 0 0 0' }}>
                      {store.profile.plan == 'free' &&
                        !filesTypeRules.youtube &&
                        getFreeAd()}

                      <div
                        className={`${
                          store.profile.plan == 'free' &&
                          !filesTypeRules.youtube &&
                          'opacity-30 blur-[1px] py-4'
                        }`}
                      >
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-base font-medium mb-3`}
                        >
                          Upload a YouTube video
                        </div>

                        <div className="flex flex-col flex-1 mt-1">
                          <input
                            value={
                              fileSelected.length > 0 ? fileSelected[0] : ''
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              setFileSelected([value]);
                            }}
                            type="text"
                            placeholder="YouTube link"
                            className={`rounded-md px-4 py-2 ${
                              theme == 'dark'
                                ? 'bg-night-black text-crystal-bell '
                                : 'bg-white text-raisin-black'
                            } border-cool-gray border-2 inline-block focus:border-nue-blue text-sm font-medium placeholder-cool-gray`}
                          />
                        </div>
                        <div
                          className={`${
                            theme == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-raisin-black'
                          } text-xs font-normal mt-2`}
                        >
                          The video should have a maximum length of 40 minutes.
                          text-xs
                          {/* ⚠️ Attention. YouTube functionality is under
                          maintenance due to new API changes. We're working to
                          restore it soon. Thank you for your patience. */}
                          According to the selected model, the query over the
                          video plot takes 60% of the input words from the
                          model's capacity and may take several minutes.
                        </div>
                      </div>
                      <div
                        className={`flex justify-center w-full px-6 pb-6 pt-2 bottom-0 ${
                          store.profile.plan == 'free' &&
                          !filesTypeRules.youtube &&
                          'opacity-30 blur-[1px] py-4'
                        }`}
                      >
                        <Button
                          Icon={ArrowDownOnSquareIcon}
                          title={'Insert'}
                          disabled={
                            fileSelected.length <= 0 ||
                            (store.profile.plan == 'free' &&
                              !filesTypeRules.youtube)
                          }
                          noMarginTop
                          iconSize="w-[18px] h-[18px]"
                          customRound="rounded-xl"
                          customPaddig="px-4 py-2"
                          customWeight="font-semibold"
                          noUppercase
                          onClick={() => onInteraction(fileSelected)}
                        />
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </>
      );
    }
  )
);

export default FileContext;
