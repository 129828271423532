import { useState, useEffect, useCallback } from 'react';
export default function useModalPromise() {
  const [isOpen, setIsOpen] = useState(false);
  const [resolver, setResolver] = useState(null);

  const open = useCallback(() => {
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolver(() => resolve);
    });
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    if (resolver) {
      resolver();
      setResolver(null);
    }
  }, [resolver]);

  return {
    isOpen,
    open,
    close,
  };
}
