import { useEffect, useState } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// store
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import { useFoldersChatStore } from './store';
// Icons
import { CheckIcon } from '@heroicons/react/24/outline';
// Components
import Modal from 'Components/Common/Modal/NewModal';
import Loader from 'Components/Loader';
import useDebounce from 'Hooks/useDebounce';
import useThemeStore from 'Theme/store';
import DeleteModalFolderChat from '../DeleteModalFolderChat/DeleteModalFolderChat';
import TreeDNDChat from '../TreeDNDChat';
import { buildDataTreeDNDChat } from '../TreeDNDChat/Utils/functions';
import UpdateModalFolderChat from '../UpdateModalFolderChat/UpdateModalFolderChat';

const DragAndDropFolders = inject('store')(
  observer(({ store, onSelectChat }) => {
    // Store
    const {
      setTreeData,
      setLoading,
      loading,
      treeData,
      setPage,
      setTotalPage,
      page,
      search,
      cleanTreeData,
    } = useTreeDNDChatStore();
    // Zustand store
    const { isModalOpen, setModalOpen } = useFoldersChatStore();

    // NEW FOLDER MODAL LOGIC
    const [newFolderName, setNewFolderName] = useState('');
    //const handleCloseModal = () => setModalOpen(false);
    const handleCloseModal = () => {
      setModalOpen(false);
    };

    const { theme } = useThemeStore();

    const onCreateFolder = async () => {
      if (newFolderName.trim() === '') {
        return;
      }
      try {
        const res = await store.api.post('/folder/chatFolders', {
          name: newFolderName,
          parentFolder: null,
        });
        const data = await res.data;
        const newFolder = {
          id: data?._id,
          droppable: true,
          text: data.name,
          parent: data?.parentFolder ? data.parentFolder : 0,
          data: {
            type: 'folder',
          },
        };
        // add new folder to treeData
        const updatedTreeData = [newFolder, ...treeData];
        setTreeData(updatedTreeData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      } finally {
        setNewFolderName('');
        setModalOpen(false);
      }
    };

    const debouncedValueText = useDebounce(search, 500);

    useEffect(() => {
      if (debouncedValueText === '') {
        cleanTreeData();
        getFolders(1, '');
      } else {
        cleanTreeData();
        getFolders(1, debouncedValueText);
      }
    }, [debouncedValueText]);

    const getFolders = async (page, search) => {
      setLoading(true);
      try {
        const res = await store.api.get(
          `/chat/v1/folders?page_number=${page}&search=${search}`
        );
        const data = await res.data;
        const chats = data.chats;

        if (page === 1) {
          setPage(data.currentPage);
          setTotalPage(data.totalPages);
        }

        if (search === '') {
          if (page === 1) {
            const newTree = buildDataTreeDNDChat(chats);
            const uniqueTree = newTree.filter(
              (node, index, self) =>
                self.findIndex((n) => n.id === node.id) === index
            );
            setTreeData(uniqueTree);
          } else {
            const newTree = buildDataTreeDNDChat(chats);
            const updatedTreeData = [...treeData, ...newTree];
            const uniqueTree = updatedTreeData.filter(
              (node, index, self) =>
                self.findIndex((n) => n.id === node.id) === index
            );
            setTreeData(uniqueTree);
          }
        } else {
          setTreeData(buildDataTreeDNDChat(chats, true));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (debouncedValueText === '') {
        getFolders(page, '');
      } else {
        getFolders(page, debouncedValueText);
      }
    }, [page]);

    return (
      <>
        <>
          {loading && page === 1 ? (
            <div className="flex items-center justify-center h-full">
              <Loader />
            </div>
          ) : (
            <TreeDNDChat onSelectChat={onSelectChat} newDesing />
          )}
        </>
        <Modal
          onClose={handleCloseModal}
          open={isModalOpen}
          title="Create chat folder"
          onConfirm={onCreateFolder}
          onConfirmBtnText="Create"
          onConfirmBtnIcon={
            <CheckIcon className="h-[18px] w-[18px]" aria-hidden="true" />
          }
        >
          <>
            <div className="font-figtree max-w-full text-base text-black dark:text-crystal-bell text-center px-4">
              <input
                autoFocus
                type="text"
                className={`w-full border border-platinum text-sm leading-[18px] ${
                  theme === 'dark'
                    ? 'bg-lead text-crystal-bell'
                    : 'bg-white text-raisin-black'
                } border-solid px-3 py-2 rounded-[10px] mb-2`}
                placeholder="Folder title"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
            </div>
          </>
        </Modal>
        <UpdateModalFolderChat />
        <DeleteModalFolderChat />
      </>
    );
  })
);

export default DragAndDropFolders;
