import { SparklesIcon } from '@heroicons/react/24/outline';
import { Divider, Tab, Tabs } from '@mui/material';
import useModelsModalStore from 'Features/ModelsModal/store';
import { a11yProps } from 'utils/styles';
import CapabilitiesIcon from '../../../Theme/icons/capabilitiesIcon';

const TabsContainer = () => {
  const { tab, setTab } = useModelsModalStore((state) => ({
    tab: state.tab,
    setTab: state.setTab,
  }));

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className="flex flex-col">
      <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
        <Tab
          label="LLM Selection"
          {...a11yProps(0)}
          iconPosition="start"
          icon={<SparklesIcon className="size-4" />}
          sx={{
            minHeight: 'auto',
            textTransform: 'initial',
          }}
        />
        <Tab
          label="Capabilities"
          {...a11yProps(1)}
          iconPosition="start"
          icon={
            <CapabilitiesIcon
              className={`w-4 h-4 ${
                tab === 1 ? 'stroke-violet-blue' : 'stroke-cool-gray'
              }`}
            />
          }
          sx={{
            minHeight: 'auto',
            textTransform: 'initial',
          }}
        />
      </Tabs>
      <Divider flexItem />
    </div>
  );
};

export default TabsContainer;
