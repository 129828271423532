import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
// Components
import MainBody from '../Components/Body';
import Subheader from '../Components/Subheader';
// icons
import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import RestartAltIcon from '@mui/icons-material/RestartAlt'; // Ícono para Reset
import FilterAltIcon from '@mui/icons-material/FilterAlt'; // Ícono para Apply
import {
  Button,
  TextField,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from '@mui/material';
import useThemeStore from 'Theme/store';

const Transactions = inject('store')(
  observer(({ store }) => {
    const { theme: themeValue } = useThemeStore();
    const [loadingBar, setLoadingBar] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);
    const [filters, setFilters] = React.useState({
      category: '',
      startDate: '',
      endDate: '',
      timezoneOffset: new Date().getTimezoneOffset(),
      dateNow: new Date().toISOString().split('T')[0],
    });

    const getTransactions = async (page, filters = {}) => {
      setLoadingBar(true);
      try {
        const response = await store.api.get('/transaction', {
          params: { page, ...filters },
        });
        if (response.data.success) {
          setTransactions([
            ...(page === 1 ? [] : transactions), // Reset transactions if it's the first page
            ...(response.data.transactions || []),
          ]);
          setHasMore(response.data.pagination.next);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingBar(false);
      }
    };

    const onLoadMore = () => {
      setPage(page + 1);
    };

    const handleFilterChange = (e) => {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyFilters = () => {
      const timezoneOffset = new Date().getTimezoneOffset();
      const dateNow = new Date().toISOString().split('T')[0];

      const filtersToApply = {
        category: filters.category || '',
        startDate: filters.startDate || undefined,
        endDate: filters.endDate || undefined,
        timezoneOffset,
        dateNow,
      };

      setPage(1);
      setTransactions([]);
      getTransactions(1, filtersToApply); // Reset transactions if it's the first page
    };

    const resetFilters = () => {
      setFilters({ category: '', startDate: '', endDate: '' });
      setPage(1);
      setTransactions([]);
      getTransactions(1, { category: '', startDate: '', endDate: '' });
    };

    const categoryOptions = [
      {
        value: '*',
        label: 'All Categories',
        description: 'Includes all types of transactions',
      },
      {
        value: 'reward',
        label: 'Reward',
        description: 'Coins awarded for completing specific tasks',
      },
      {
        value: 'purchase',
        label: 'Purchase',
        description: 'Transactions for coin purchases',
      },
      {
        value: 'chat',
        label: 'Chat',
        description: 'Text-based interactions with chat models',
      },
      {
        value: 'image',
        label: 'Image',
        description:
          'Image generation using tools like Stable Diffusion, Dall-E 3 or FLUX',
      },
      {
        value: 'plan',
        label: 'Plan',
        description: 'Monthly or yearly subscription charges',
      },
      {
        value: 'speech',
        label: 'Speech',
        description: 'Audio files generated through speech services',
      },
      {
        value: 'rollover',
        label: 'Rollover',
        description: 'Coins not used for more than a year',
      },
      {
        value: 'api',
        label: 'API',
        description: 'Transactions involving API interactions or requests',
      },
    ];

    useEffect(() => {
      getTransactions(page, filters);
    }, [page]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <>
        <Helmet>
          <title>{`Transactions - Straico`}</title>
        </Helmet>
        <Subheader title="Transactions" icon="ArrowsRightLeftIcon" />
        <MainBody className="h-full overflow-auto">
          <div className="flex flex-col gap-4 px-4 sm:px-6 md:px-16 lg:px-28 h-full pt-5 font-figtree">
            <div className="flex flex-wrap gap-3 mb-4 justify-center md:justify-between">
              {/* Category */}
              <FormControl
                className="flex-1 min-w-[120px]"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-root': {
                    fontSize: '14px',
                    textTransform: 'initial',
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                  },
                  '& .MuiInputLabel-root': {
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                  },
                }}
              >
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={filters.category}
                  fontFamily="inherit"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFilters({ ...filters, category: selectedValue });
                  }}
                  input={<OutlinedInput label={`Category  \u00A0`} />}
                  renderValue={(selected) => {
                    const selectedOption = categoryOptions.find(
                      (option) => option.value === selected
                    );
                    return selectedOption
                      ? selectedOption.label
                      : 'All Categories';
                  }}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        fontFamily: 'Figtree',
                      }}
                    >
                      <ListItemText
                        primary={option.label}
                        secondary={option.description}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                          },
                        }}
                        secondaryTypographyProps={{
                          sx: {
                            fontSize: '12px',
                            color: 'text.secondary',
                            fontFamily: 'Figtree',
                          },
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Dates */}
              <TextField
                name="startDate"
                label={`Start Date  \u00A0`}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={filters.startDate}
                onChange={handleFilterChange}
                className="flex-1 min-w-[120px]"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-root': {
                    fontSize: '14px',
                    textTransform: 'initial',
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                  },
                  '& .MuiInputLabel-root': {
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                  },
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter:
                      themeValue === 'dark'
                        ? window.matchMedia('(prefers-color-scheme: dark)')
                            .matches
                          ? 'none'
                          : 'invert(80%)'
                        : window.matchMedia('(prefers-color-scheme: dark)')
                            .matches
                        ? 'invert(80%)'
                        : 'none',
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    cursor: 'pointer',
                  },
                  '& input': {
                    color: themeValue === 'dark' ? 'white' : 'black',
                  },
                }}
              />

              <TextField
                name="endDate"
                label={`End Date  \u00A0`}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={filters.endDate}
                onChange={handleFilterChange}
                className="flex-1 min-w-[120px]"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-root': {
                    fontSize: '14px',
                    textTransform: 'initial',
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                  },
                  '& .MuiInputLabel-root': {
                    fontWeight: 'normal',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                  },
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter:
                      themeValue === 'dark'
                        ? window.matchMedia('(prefers-color-scheme: dark)')
                            .matches
                          ? 'none'
                          : 'invert(80%)'
                        : window.matchMedia('(prefers-color-scheme: dark)')
                            .matches
                        ? 'invert(80%)'
                        : 'none',
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    cursor: 'pointer',
                  },
                  '& input': {
                    color: themeValue === 'dark' ? 'white' : 'black',
                  },
                }}
              />

              {/* Buttons */}
              <div className="flex flex-row gap-3 w-full md:w-auto mt-0 md:mt-0">
                <Button
                  variant="contained"
                  color="primary"
                  className="rounded-lg px-6 py-2 flex-1 md:flex-none"
                  sx={{ boxShadow: 'none' }}
                  onClick={applyFilters}
                >
                  <FilterAltIcon className="mr-1" /> Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<RestartAltIcon />}
                  className="rounded-lg px-6 py-2 flex-1 md:flex-none"
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              </div>
            </div>

            <div
              id="scrollableDiv"
              className="h-full transition-all ease-in duration-500 py-2"
            >
              <div className="flex gap-3 flex-col mt-3">
                {transactions.map((transaction, index) => (
                  <TransactionCard transaction={transaction} key={index} />
                ))}
                {loadingBar && (
                  <div className="flex gap-3 flex-col mt-3">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                      <SkeletonCard key={index} />
                    ))}
                  </div>
                )}
                <Button
                  startIcon={<ArrowPathIcon className="size-4" />}
                  onClick={onLoadMore}
                  disabled={!hasMore}
                  variant="outlined"
                  color="primary"
                  sx={{
                    mb: 3,
                    borderRadius: '10px',
                  }}
                >
                  Load More
                </Button>
              </div>
            </div>
          </div>
        </MainBody>
      </>
    );
  })
);

const TransactionCard = inject('store')(
  observer(({ transaction }) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    const { theme: themeValue } = useThemeStore();

    const renderMessage = () => {
      if (transaction?.type === 'rollover') {
        return 'coin rollover expiry';
      }
      return transaction?.type;
    };

    return (
      <div
        className={`w-full transition duration-500 transform flex flex-col md:flex-row justify-between items-center ${
          themeValue === 'dark' ? 'bg-umbra' : 'bg-white'
        } rounded-md p-4 gap-6`}
        style={{ borderRadius: '8px' }}
      >
        {/* Left column */}
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-14 h-14 mx-3">
            {transaction?.operation_type === 'add' ? (
              <ArrowUpIcon className="text-violet-blue" />
            ) : (
              <ArrowDownIcon className="text-violet-blue" />
            )}
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div
              className={`flex text-battleship-gray dark:text-crystal-bell text-base font-light tracking-wide`}
            >
              <span className="text-sm font-semibold">
                {transaction?.operation_type === 'add' ? '+' : '-'}
                {transaction?.coins != null
                  ? transaction?.coins?.toLocaleString('en-US')
                  : ''}
              </span>
              <CurrencyDollarIcon className="w-5 h-5 text-violet-blue" />
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="flex flex-col h-full gap-4 w-full">
          <div className="flex flex-row">
            <div className="w-4/12">
              <div
                className={`flex justify-start items-center font-bold uppercase tracking-wide text-base text-raisin-black dark:text-crystal-bell leading-none mt-1`}
                style={{ fontFamily: 'inherit' }}
              >
                {renderMessage()}
              </div>
            </div>
            <div className="w-8/12">
              <div
                className={`flex justify-end text-battleship-gray dark:text-crystal-bell text-xs font-light tracking-wide`}
                style={{ fontFamily: 'inherit' }}
              >
                ID: {transaction?.hash_id}
              </div>
            </div>
          </div>
          <p
            className="mt-1 pr-1 font-bold uppercase tracking-wide text-sm line-clamp text-raisin-black dark:text-crystal-bell"
            style={{ fontFamily: 'inherit' }}
          >
            {transaction?.title}
          </p>
          <p
            className="-mt-4 pr-1 text-base font-semibold line-clamp dark:text-crystal-bell"
            style={{ fontFamily: 'inherit' }}
          >
            {transaction?.postitle}
          </p>
          <p
            className="-mt-4 pr-1 text-base line-clamp dark:text-crystal-bell"
            style={{ fontFamily: 'inherit' }}
          >
            {transaction?.subtitle}
          </p>
          <p
            className="-mt-2 pr-1 text-sm line-clamp text-right text-battleship-gray dark:text-crystal-bell"
            style={{ fontFamily: 'inherit' }}
          >
            {transaction?.date
              ? new Date(transaction?.date)?.toLocaleString('en-US', options)
              : ''}
          </p>
        </div>
      </div>
    );
  })
);

const SkeletonCard = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      className={`w-md flex w-full items-center justify-between flex-row ${
        themeValue === 'dark' ? 'bg-umbra' : 'bg-white'
      } rounded-lg overflow-hidden`}
      style={{ borderRadius: '8px' }}
    >
      <div className="bg-ghost-white dark:bg-night-black h-32 w-32 animate-pulse"></div>
      <div className="px-4 py-2 w-full">
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/2 mb-2 animate-pulse"></div>
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/3 animate-pulse"></div>
      </div>
    </div>
  );
};

export default Transactions;
