import { Skeleton } from '@mui/material';
import CardItem from 'Features/ModelsModal/components/cardItem';
import EmptyGrid from 'Features/ModelsModal/components/emptyGrid';
import TableModels from 'Features/ModelsModal/components/tableModels';

const ModelsContainer = ({
  isLoading,
  layout,
  models,
  handleChange,
  selected: isSelected,
  handleToggleStar,
}) => {
  return (
    <>
      {layout === 'grid' ? (
        <div className="flex-1 mt-3 overflow-y-scroll">
          <>
            {/* Models content GRID */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 overflow-y-scroll">
              {!isLoading &&
                (models ?? [])?.length > 0 &&
                models.map((item, index) => (
                  <div key={`model-${index}`}>
                    <CardItem
                      model={item}
                      selected={isSelected(item)}
                      onSelect={handleChange}
                      icon={item?.model_icon}
                      name={item?.name}
                      description={item?.model_pros.join(', ')}
                      maxWords={item?.words_text}
                      coins={item?.pricing?.coins}
                      applications={item?.model_applications}
                      features={item?.model_features}
                      date={item?.model_date}
                      runs={item?.model_runs ?? 0}
                      handleStarred={handleToggleStar}
                      favorite={item?.favorite}
                    />
                  </div>
                ))}
              {isLoading &&
                [...Array(16)].map((_, index) => (
                  <Skeleton
                    key={`skeleton-${index}`}
                    variant="rectangular"
                    width="100%"
                    height={220}
                    className="w-full cursor-pointer rounded min-w-[300px] backdrop-blur"
                  />
                ))}
            </div>
            {!isLoading && (models ?? [])?.length === 0 && <EmptyGrid />}
          </>
        </div>
      ) : (
        <>
          {/* Models content LIST */}
          {!isLoading && (models ?? [])?.length === 0 && <EmptyGrid />}
          {isLoading && (
            <div className="mt-2 gap-2 flex flex-col h-full">
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={`skeleton-${index}`}
                  variant="rectangular"
                  width="100%"
                  height={70}
                  className="w-full cursor-pointer rounded min-w-[300px] backdrop-blur"
                />
              ))}
            </div>
          )}
          {!isLoading && (models ?? [])?.length > 0 && (
            <TableModels
              models={models}
              isSelected={isSelected}
              onSelect={handleChange}
              handleStarred={handleToggleStar}
            />
          )}
        </>
      )}
    </>
  );
};

export default ModelsContainer;
