import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Box } from '@mui/material';
import * as React from 'react';

export default function ScrollableTabs({ children }) {
  const tabListRef = React.useRef(null);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(false);

  const checkScroll = React.useCallback(() => {
    if (!tabListRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = tabListRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  }, []);

  React.useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const scroll = (direction, e) => {
    e.stopPropagation();
    if (!tabListRef.current) return;

    const scrollAmount = direction === 'left' ? -200 : +200;
    tabListRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <div className="relative flex items-center max-w-full">
      {showLeftArrow && (
        <button
          onClick={(e) => scroll('left', e)}
          className="absolute left-0 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-50 dark:bg-[#39383a] dark:text-crystal-bell hover:dark:bg-[#39383a]"
          aria-label="Scroll left"
        >
          <ChevronLeftIcon className="h-4 w-4 text-gray-600 dark:text-crystal-bell" />
        </button>
      )}

      <Box
        ref={tabListRef}
        className="hide-scrollbar flex gap-2 overflow-x-auto scroll-smooth pr-5"
        sx={{ scrollSnapType: 'x mandatory', scrollbarWidth: 'none' }}
        onScroll={checkScroll}
      >
        {children}
      </Box>

      {showRightArrow && (
        <button
          onClick={(e) => scroll('right', e)}
          className="absolute right-0 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-50 dark:bg-[#39383a] dark:text-crystal-bell hover:dark:bg-[#39383a]"
          aria-label="Scroll right"
        >
          <ChevronRightIcon className="h-4 w-4 text-gray-600 dark:text-crystal-bell" />
        </button>
      )}
    </div>
  );
}
