/* eslint-disable no-console */
import {
  EyeIcon,
  GlobeAltIcon,
  LockClosedIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  RectangleGroupIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  ArchiveBoxXMarkIcon,
  ArrowPathIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import { Button, Tab, Tabs } from '@mui/material';
import AvatarSvgIcon from '@straico-ui/commons/AvatarSvgIcon';
import BackButton from '@straico-ui/commons/BackButton';
import SocialLinksMap from '@straico-ui/commons/SocialLinksMap';
import TextInput from '@straico-ui/forms/TextInput';
import TemplateCard, {
  LoadingTemplateCard,
} from 'Components/Chat/PromptLibrary/Components/TemplateCard';
import config from 'config';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StraicoSymbolIcon from 'Theme/icons/straicoSymbol';
import useThemeStore from 'Theme/store';
import { a11yProps, cn } from 'utils/styles';

const UserProfileDetail = ({ store }) => {
  const user = store.profile;

  const navigate = useNavigate();
  /** THEME */
  const { theme } = useThemeStore();

  const goToMainSettings = () => {
    navigate('/my-profile');
  };

  useEffect(() => {
    store.refreshTokenAndProfile();
  }, []);

  const goToEditProfile = () => navigate('/my-profile/edit');

  const bio = useMemo(() => {
    if (user?.bio !== '') {
      return user.bio;
    }
    return 'No bio';
  }, [user.bio]);

  const socialLinks = useMemo(() => {
    return user?.social_media_links?.length > 0
      ? user?.social_media_links.map((i) => ({
          username: i?.username,
          url: i?.link,
          type: i?.type,
        }))
      : [];
  }, [user.socialLinks]);

  const catchphrase = useMemo(() => {
    if (user?.catch_phrase !== '') {
      return user.catch_phrase;
    }
    return 'No catchphrase';
  }, [user.catch_phrase]);

  return (
    <div
      className={cn('h-full overflow-auto font-figtree', {
        'text-white': theme === 'dark',
        'text-[#211E21]': theme !== 'dark',
      })}
    >
      <div className="max-w-4xl mx-auto p-6">
        <BackButton
          text="Go back to user settings"
          onClick={goToMainSettings}
        />
        <h1 className={cn('text-3xl font-bold mt-7')}>Your Profile</h1>
        <VisibilityBanner onEdit={goToEditProfile} user={user} />
        <ProfileInfo
          isPublicProfile={user?.profile_is_public}
          avatar={user?.avatar?.svg}
          nickname={user?.hero_alias}
          hero={catchphrase}
        />
        <SocialLinks description={bio} socialLinks={socialLinks} />
        <TemplateSection store={store} user={user} />
      </div>
    </div>
  );
};

const VisibilityBanner = ({ onEdit, user }) => (
  <div className="flex items-center justify-between mt-5 mb-8">
    <div className="flex items-center">
      {user?.profile_is_public ? (
        <EyeIcon className="w-5 h-5 mr-2" />
      ) : (
        <LockClosedIcon className="w-5 h-5 mr-2" />
      )}
      <p className="">
        {user?.profile_is_public ? (
          <>
            Your profile is currently public. Continue to showcase your
            superhero identity!
          </>
        ) : (
          <>
            <strong>Your profile is currently private.</strong> Make a template
            public to showcase your superhero identity (learn more about
            templates{' '}
            <a
              href="https://straico.com/the-power-of-template-prompts-boost-your-productivity-with-straico/"
              target="_blank"
              className="text-nue-blue"
              rel="noreferrer"
            >
              here
            </a>
            ) !
          </>
        )}
      </p>
    </div>
    <div>
      <Button
        size="small"
        variant="contained"
        startIcon={<PencilIcon className="size-5" />}
        sx={{
          minWidth: '150px',
        }}
        onClick={onEdit}
      >
        Edit profile
      </Button>
    </div>
  </div>
);

const ProfileInfo = ({ avatar, nickname, hero, isPublicProfile }) => {
  const onGotoPublicProfile = () => {
    if (nickname && nickname !== '')
      window.open(`${config?.communityUrl}/${nickname}`, '_blank');
  };

  return (
    <div className="flex items-center mb-7">
      <div
        id="avatar-image"
        className={cn('', {
          'cursor-pointer': isPublicProfile && nickname !== '',
          'cursor-not-allowed': !isPublicProfile && nickname !== '',
        })}
        onClick={onGotoPublicProfile}
      >
        {avatar ? (
          <AvatarSvgIcon svg={avatar} className="h-16 w-16 mr-4" />
        ) : (
          <>
            {nickname?.toLowerCase() !== 'straico' ? (
              <UserCircleIcon className="size-16 -mb-1 text-cool-gray dark:text-battleship-gray cursor-pointer" />
            ) : (
              <StraicoSymbolIcon className="size-14 -mb-1 rounded-full fill-nue-blue bg-white cursor-pointer" />
            )}
          </>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2">
          <h2
            className="text-2xl font-bold cursor-pointer hover:text-nue-blue capitalize"
            onClick={onGotoPublicProfile}
          >
            {['', null, undefined].includes(nickname)
              ? 'No Hero Alias'
              : nickname}
          </h2>
          {isPublicProfile ? (
            <GlobeAltIcon
              onClick={onGotoPublicProfile}
              className="w-4 h-4 text-gray-500 cursor-pointer hover:text-nue-blue"
            />
          ) : (
            <LockClosedIcon className="w-4 h-4 text-gray-500" />
          )}
        </div>
        <p className="text-gray-600">{hero ?? '--/--'}</p>
      </div>
    </div>
  );
};

const SocialLinks = ({ description, socialLinks }) => (
  <div className="mb-7">
    <p className="mb-7">{description ?? '--'}</p>
    <div className="flex space-x-4">
      <SocialLinksMap socialLinks={socialLinks} />
    </div>
  </div>
);

const TemplateSection = ({ store, user }) => {
  const { theme } = useThemeStore();

  // STATES
  const [tab, setTab] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [metaData, setMetaData] = useState(null);
  const [templates, setTemplates] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const resetValues = () => {
    setLimit(10);
    setTemplates([]);
    setMetaData(null);
  };

  // EFFECTS
  useEffect(() => {
    resetValues();
  }, [tab]);

  useEffect(() => {
    getTemplates(tab);
  }, [tab, limit, search]);

  // CALLBACKS SERVICES
  const getTemplates = async (tab, limit) => {
    setLoading(true);
    try {
      let paramsAdditionals;
      if (user?.profile_is_public) {
        paramsAdditionals = {
          ...(tab === 1
            ? {
                favorites: true,
              }
            : {
                publics_templates: true,
              }),
        };
      } else {
        paramsAdditionals = {
          favorites: true,
        };
      }

      const response = await store.api.get(`/tool/prompt-library`, {
        params: {
          ...paramsAdditionals,
          creator: '3',
          limit: limit,
          keyword: search,
        },
      });
      setTemplates(response.data.tools);
      setMetaData({
        currentPage: response?.data?.currentPage,
        pages: response?.data?.totalPages,
        total: response?.data?.totalCount,
      });
    } catch (err) {
      console.error(err);
      resetValues();
    } finally {
      setLoading(false);
    }
  };

  const onLoadMore = () => {
    setLimit(limit + 10);
  };

  const navigate = useNavigate();
  const onClickCard = (id) => () => {
    navigate(`/chat?selectedTool=${id}`);
  };

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div className="space-x-4 flex-1">
          <Tabs value={tab} onChange={handleChangeTab}>
            {user?.profile_is_public && (
              <Tab
                sx={{
                  textTransform: 'initial',
                }}
                icon={<RectangleGroupIcon className="size-5" />}
                iconPosition="start"
                label="Public templates"
                {...a11yProps(0)}
              />
            )}
            <Tab
              sx={{
                textTransform: 'initial',
              }}
              icon={<StarIcon className="size-5" />}
              iconPosition="start"
              label="Favorite templates"
              {...a11yProps(1)}
            />
          </Tabs>
        </div>
        <div className="flex-1">
          <TextInput
            value={search}
            onChange={handleChangeSearch}
            startIcon={MagnifyingGlassIcon}
            type="text"
            hideLock
            placeholder="Search templates"
            disabled={loading || metaData?.total === 0}
          />
        </div>
      </div>
      <div
        className={cn(
          'flex flex-wrap items-start gap-4  px-2 overflow-auto h-full w-full',
          {
            'justify-center': templates?.length > 0,
            'justify-start': templates?.lenght === 1 || templates?.length === 2,
            'justify-around': loading || templates?.length === 0,
          }
        )}
      >
        <>
          {!loading && templates?.length === 0 && (
            <div className="flex items-center justify-center w-full h-full my-10">
              {tab === 1 ? (
                <div className="flex justify-center items-center flex-col">
                  <ArchiveBoxXMarkIcon className="w-12 h-12" />
                  <p
                    className={cn('text-base font-bold font-barlow', {
                      'text-nue-blue': theme === 'dark',
                      'text-graphite-900': theme !== 'dark',
                    })}
                  >
                    No results found
                  </p>
                </div>
              ) : (
                <div className="flex justify-center items-center flex-col">
                  <h2
                    className={`text-xl font-bold leading-[30px] ${
                      theme === 'dark' ? 'text-white' : 'text-black'
                    }`}
                  >
                    No favorite templates
                  </h2>
                  <p
                    className={`text-base ${
                      theme === 'dark' ? 'text-white' : 'text-black'
                    }`}
                  >
                    To add a prompt template to your favorites, simply click on
                    the star ⭐️ on the template's description.
                  </p>
                </div>
              )}
            </div>
          )}
          {templates?.map((tool, index) => (
            <TemplateCard
              id={tool._id}
              multi_default_models={tool?.multi_default_models}
              default_model={tool?.default_model}
              icon={tool?.Icon}
              key={index}
              category={tool.category}
              title={tool.title}
              description={tool?.desc}
              userInfo={
                tool.creator
                  ? {
                      ...tool.creator,
                    }
                  : null
              }
              files={tool?.files ?? []}
              runs={tool?.runs ?? 0}
              onClick={onClickCard(tool._id)}
            />
          ))}
          {loading && (
            <>
              {Array.from({ length: 20 }).map((_, index) => (
                <LoadingTemplateCard
                  key={index}
                  classNameContainer="!m-0 !h-[170px] !min-w-[300px]"
                />
              ))}
            </>
          )}
        </>
      </div>
      <Button
        startIcon={<ArrowPathIcon className="size-4" />}
        onClick={onLoadMore}
        fullWidth
        disabled={
          loading ||
          !metaData ||
          metaData.currentPage === metaData.pages ||
          metaData?.total === 0
        }
        variant="outlined"
        color="primary"
        sx={{
          my: 3,
        }}
      >
        Load More
      </Button>
    </div>
  );
};

export default inject('store')(observer(UserProfileDetail));
