import { StarIcon } from '@heroicons/react/24/outline';
import {
  CurrencyDollarIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/solid';
import {
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import BadgeInformation from 'Features/ModelsModal/components/BadgeInformation';
import ScrollableTabs from 'Features/ModelsModal/components/scrollableHorizontal';
import {
  APPLICATIONS_MAP,
  CAPABILITIES_MAP,
  FEATURES_MAP,
} from 'Features/ModelsModal/mocks/constants';
import { formatDate } from 'Features/ModelsModal/utils';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const CardItem = ({
  name,
  date,
  icon,
  coins,
  model,
  runs,
  features,
  onSelect,
  maxWords,
  selected,
  description,
  applications,
  handleStarred,
  favorite,
}) => {
  const handleSelect = () => {
    onSelect(model);
  };

  const { theme } = useThemeStore();
  const isDark = theme === 'dark';

  const colorCardBg = `${
    model?.editors_choice_level > 0
      ? !isDark
        ? '!bg-gradient-to-t from-white/40 via-[#D4D5E600] to-[#D4D5E6]'
        : '!bg-gradient-to-t dark:from-[#211d20] dark:via-[#211d20] dark:to-[#51526C]'
      : ''
  }`;

  return (
    <Card
      onClick={handleSelect}
      className={cn(
        `w-full cursor-pointer max-w-2xl rounded  backdrop-blur h-[220px] shadow-2xl`,
        {
          '!bg-platinum dark:!bg-night-black': selected,
          [colorCardBg]: !selected,
        }
      )}
      sx={{
        backdropFilter: 'blur(20px)',
        border: '1px solid rgba(209, 213, 219, 0.3)',
        borderRadius: '12px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      <CardContent className="p-1 h-full">
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center space-x-2">
            <div className=" rounded-full flex items-center">
              <CurrencyDollarIcon className="w-5 h-5 text-cool-gray dark:text-palladium" />
              <span className="text-xs ml-1">{coins}</span>
            </div>
          </div>
          <span className="text-gray-600 dark:text-palladium text-xs">
            {formatDate(date)}
          </span>
        </div>

        <div className="flex items-start justify-between mb-2">
          <div className="flex items-center gap-2">
            {selected ? (
              <Checkbox
                checked={selected}
                sx={{
                  p: '0px !important',
                }}
              />
            ) : (
              <img
                className="size-6 rounded-lg"
                src={icon}
                alt={`icon-${name}`}
              />
            )}
            <div>
              <h2 className="text-[16px] line-clamp-1 font-semibold">{name}</h2>
              <div className="flex flex-wrap gap-2 justify-start">
                {features.map((feature, i) => {
                  const featureSelect = FEATURES_MAP.find(
                    (item) => item.feature === feature
                  );
                  const Icon = featureSelect ? featureSelect?.icon : null;

                  return (
                    <BadgeInformation
                      index={i}
                      label={featureSelect ? featureSelect.label : feature}
                      variant="image"
                      icon={Icon ? <Icon className="size-3" /> : null}
                    />
                  );
                })}
                {model?.model_capabilities?.map((capability, i) => {
                  const capabilitySelect = CAPABILITIES_MAP.find(
                    (item) => item.capability === capability
                  );
                  const Icon = capabilitySelect ? capabilitySelect?.icon : null;

                  return (
                    <BadgeInformation
                      index={i}
                      label={
                        capabilitySelect ? capabilitySelect.label : capability
                      }
                      variant="browsing"
                      icon={Icon ? <Icon className="size-3" /> : null}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleStarred(model);
            }}
          >
            <StarIcon
              className={`w-6 h-6 ${
                favorite ? 'text-nue-blue fill-nue-blue' : 'text-cool-gray'
              }`}
            />
          </IconButton>
        </div>

        <Tooltip title={description} placement="top">
          <p className="text-gray-600 dark:text-palladium text-sm font-[400] mb-3 line-clamp-2">
            {description}
          </p>
        </Tooltip>

        <div className="flex w-full gap-2 justify-start mb-3">
          <ScrollableTabs>
            {applications.map((app, i) => {
              const appSelect = APPLICATIONS_MAP.find(
                (item) => item.application === app
              );
              const Icon = appSelect ? appSelect?.icon : null;

              return (
                <BadgeInformation
                  index={i}
                  label={appSelect ? appSelect.application : app}
                  variant="default"
                  icon={Icon ? <Icon className="size-3" /> : null}
                />
              );
            })}
          </ScrollableTabs>
        </div>

        <div className="flex justify-between items-center text-gray-600 dark:text-palladium mt-1">
          <div className="flex items-center gap-2">
            <RocketLaunchIcon className="size-4" />
            <span className="text-xs">{runs} Last 30-Day runs</span>
          </div>
          <span className="text-xs">
            {maxWords ? maxWords.toLocaleString() : 0} words max.
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardItem;
