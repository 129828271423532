import { create } from 'zustand';

const useModelsModalStore = create((set, get) => ({
  tab: 0,
  setTab: (tab) => set({ tab }),
  refetch: 0,
  setRefetch: (refetch) => set({ refetch }),
  // Filters
  favorites: false,
  editorsChoice: false,
  features: [],
  capabilities: [],
  applications: [],
  other: [],
  toggleFavorites: () => set((state) => ({ favorites: !state.favorites })),
  toggleEditorsChoice: () =>
    set((state) => ({ editorsChoice: !state.editorsChoice })),
  toggleFilter: (category, filter) =>
    set((state) => ({
      [category]: state[category].includes(filter)
        ? state[category].filter((f) => f !== filter)
        : [...state[category], filter],
    })),
  clearAll: () =>
    set({
      favorites: false,
      editorsChoice: false,
      features: [],
      capabilities: [],
      applications: [],
      other: [],
    }),
  // Search
  search: '',
  setSearch: (search) => set({ search }),
  // Sort
  sort: {},
  setSort: (field) =>
    set((state) => {
      const newState = { ...state.sort };
      if (newState[field] === undefined) {
        newState[field] = 1;
      } else if (newState[field] === 1) {
        newState[field] = -1;
      } else {
        delete newState[field];
      }
      return { sort: newState };
    }),
  // Layout
  layout: 'table',
  setLayout: (layout) => set({ layout }),
}));

export default useModelsModalStore;
