import {
  ArrowDownIcon,
  ArrowsUpDownIcon,
  ArrowUpIcon,
} from '@heroicons/react/24/solid';

export const getSortIcon = (field, sortState, defaultSort) => {
  const direction = sortState[field];

  if (direction === 1) {
    return (
      <ArrowDownIcon className="size-3 text-nue-blue dark:text-crystal-bell" />
    );
  }
  if (direction === -1) {
    return (
      <ArrowUpIcon className="size-3 text-nue-blue dark:text-crystal-bell" />
    );
  }

  if (defaultSort) {
    return (
      <ArrowsUpDownIcon className="size-4 text-nue-blue dark:text-crystal-bell" />
    );
  }

  return <> </>;
};

export const formatDate = (date) => {
  if (!date) return '';
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
};
