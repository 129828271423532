function formatString(input) {
  return input.replace(/_/g, ' ');
}

const BadgeInformation = ({ label, variant, icon, className, onClick }) => {
  const variants = {
    default: 'bg-[#66BCC2] text-[#66BCC2] border-[#66BCC2]',
    filtered: 'bg-[#EE6C4C] text-burnt-sienna border-burnt-sienna',
    browsing: 'bg-visa-blue text-vista-blue border-vista-blue',
    image: 'bg-rose-quartz text-rose-quartz border-rose-quartz',
    none: 'bg-[#8183B8] text-[#8183B8] border-[#8183B8]',
    disabled:
      'border-platinum text-gray-600 bg-platinum dark:text-crystal-bell',
  };

  return (
    <span
      onClick={onClick}
      className={`uppercase font-bold px-1 rounded-full bg-opacity-10 text-[10px] inline-flex border w-fit items-center gap-1  ${
        variants[variant || 'default']
      } ${className}`}
      style={{ whiteSpace: 'nowrap' }}
    >
      {icon && icon}
      {formatString(label) ?? ''}
    </span>
  );
};

export default BadgeInformation;
