import { useMemo } from 'react';

export const useSelectedFiltersCount = (state) => {
  const count = useMemo(() => {
    return (
      (state.favorites ? 1 : 0) +
      (state.editorsChoice ? 1 : 0) +
      state.features.length +
      state.capabilities.length +
      state.applications.length +
      state.other.length
    );
  }, [
    state.favorites,
    state.editorsChoice,
    state.features,
    state.capabilities,
    state.applications,
    state.other,
  ]);

  return count;
};
