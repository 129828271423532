/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import useChatStore from 'Components/Chat/store';

import { inject, observer } from 'mobx-react';
import { TranslateContext } from '../../Context/translate-context';
import config from '../../config';
// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import ReCAPTCHA from 'react-google-recaptcha';

import StraicoIcon from '../../Theme/icons/straico';

const baseURL = config.baseURL;

const LoginLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Start - Straico</title>
      </Helmet>

      <div className="h-screen flex flex-col">
        <header className="h-auto bg-nue-blue font-medium text-2xl py-3">
          <NavLink to="/login">
            <img className="h-7 pl-4 -mt-1" src="/svg/logo-snow-100.svg" />
          </NavLink>
        </header>

        <div className="lg:grid lg:grid-cols-3 flex-1 bg-seasalt h-full overflow-auto">
          <div className="login-banner-wrapper bg-ghost-white hidden lg:block lg:col-span-2">
            <LazyLoadImage
              className="h-[calc(100vh_-_48px)] w-full object-cover"
              src="/images/straico-wall-16.png"
              placeholderSrc="/images/straico-wall-16-blur.png"
              effect="blur"
            />
          </div>
          <div className="flex items-center justify-center bg-seasalt font-figtree text-raisin-black overflow-auto">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

const NewLoginLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Start - Straico</title>
      </Helmet>

      <div className="h-screen w-screen flex flex-col justify-center items-center gap-12 bg-[radial-gradient(46.08%_64.79%_at_50%_50%,_var(--tw-gradient-stops))] from-nue-blue to-raisin-black">
        <StraicoIcon className="fill-nue-blue h-[51px] w-[215.129px]" />
        <div className="bg-seasalt px-4 sm:px-12 md:px-28 py-10 rounded-3xl">
          {children}
        </div>
      </div>
    </>
  );
};

export const LoginSection = inject('store')(
  observer(({ store }) => {
    const [error, setError] = useState(null);
    const { translator } = useContext(TranslateContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [captchaValue, setCaptchaValue] = useState('');

    const onChange = (val, attr) => {
      if (attr === 'email') setEmail(val);
      if (attr === 'password') setPassword(val);
      setError('');
    };

    const handleCaptchaChange = (value) => {
      setCaptchaValue(value);
    };

    const onLogin = async (e) => {
      try {
        if (!captchaValue) {
          toast.error(
            "Please verify that you're human by completing the captcha"
          );
          return;
        }
        e.preventDefault();
        const response = await fetch(baseURL + 'auth/signin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        const data = await response.json();
        // Handle error
        if ([401, 400, 404].includes(response.status)) {
          if (data?.message) {
            toast.error(data?.message);
            return;
          }
        }

        store.loginWithDataTokenAndProfile(data);
        store.sendMetricsEvent('login', null, { method: 'user-pass' });
        if (sessionStorage.getItem('redirectUrl')) {
          navigate(sessionStorage.getItem('redirectUrl'));
        } else {
          navigate('/chat');
        }
        // Navigate to chat page if user is verified
        // if (data?.profile?.verified) {
        //   store.loginWithDataTokenAndProfile(data);
        //   if (sessionStorage.getItem('redirectUrl')) {
        //     navigate(sessionStorage.getItem('redirectUrl'));
        //   } else {
        //     navigate('/chat');
        //   }
        // } else {
        //   toast(
        //     'Your account needs to be activated. Kindly check your email inbox and confirm.',
        //     {
        //       icon: '⚠️',
        //       duration: 10000,
        //     }
        //   );
        // }
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const signUpGoogle = async (event) => {
      if (store.fpr) {
        window.open(baseURL + 'auth/google/callback?fpr=' + store.fpr, '_self');
      } else {
        window.open(baseURL + 'auth/google/callback', '_self');
      }
    };

    const { cleanTreeData } = useTreeDNDChatStore();
    const { cleanChatList, cleanSelectedChat } = useChatStore();

    const handleSubmit = (e) => {
      e.preventDefault();
      cleanTreeData();
      cleanChatList();
      cleanSelectedChat();
      onLogin(e);
    };

    return (
      <NewLoginLayout>
        <form onSubmit={handleSubmit}>
          <div className="bg-seasalt px-4 w-80 pt-4 lg:pt-0">
            <div className="text-3xl font-bold mb-4 text-center text-nue-blue">
              {translator('sectionLogIn.labelTitle')}
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionLogIn.fieldEmail')}
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, 'email')}
                type="email"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionLogIn.placeholderEmail')}
              />
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-4 text-left font-light tracking-wider">
                {translator('sectionLogIn.fieldPassword')}
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, 'password')}
                type="password"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionLogIn.placeholderPassword')}
              />
            </div>
            <div className="text-center mb-4">
              <NavLink to="/forgot">
                <p className="text-violet-blue text-sm">
                  Forgot your password?
                </p>
              </NavLink>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={handleCaptchaChange}
              style={{
                transform: 'scale(0.77)',
                WebkitTransform: 'scale(0.77)',
                transformOrigin: 'center center',
                WebkitTransformOrigin: 'center center',
              }}
            />
            {error ? <div className="text-red-600">{error}</div> : null}
            <div className="flex flex-col mb-4 mt-4">
              <button
                type="submit"
                className="bg-nue-blue hover:bg-lavender text-white font-bold py-2 px-4 rounded-md uppercase"
              >
                {translator('sectionLogIn.buttonLogIn')}
              </button>
            </div>
            <div className="flex flex-col text-center mb-4">
              <p className="text-sm">or</p>
              <div
                onClick={signUpGoogle}
                className="flex items-center hover:bg-lavender font-bold rounded-md px-4 py-2 bg-ghost-white mt-4 border border-lavender cursor-pointer justify-center"
              >
                <span>
                  <img
                    src="/images/logo-google-oauth.png"
                    className="mr-2"
                  ></img>
                </span>
                <span className="uppercase text-sm">Sign in with Google</span>
              </div>
            </div>
            <div className="text-center mt-0">
              <p className="text-sm">
                New on Straico?{' '}
                <NavLink to="/signup" className="text-violet-blue text-sm">
                  Sign up
                </NavLink>
              </p>
            </div>
            {/* <div className="text-center mt-8">
              <p className="text-sm">
                <a
                  href="https://www.straico.com/"
                  target="_blank"
                  className="text-violet-blue text-sm"
                  rel="noreferrer"
                >
                  Learn more about us
                </a>
              </p>
            </div> */}
          </div>
        </form>
      </NewLoginLayout>
    );
  })
);

const schemaValidationSignUp = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  fname: yup
    .string()
    .required('First name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'First name should only contain letters and numbers'
    ),
  lname: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'Last name should only contain letters and numbers'
    ),
});

export const SignupSection = inject('store')(
  observer(({ store }) => {
    const { translator } = useContext(TranslateContext);
    const [error, setError] = useState(null);

    const [captchaValue, setCaptchaValue] = useState('');

    const [fprValue, setFprValue] = useState(null);

    const [loadingSingUp, setLoadingSingUp] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const currentURL = location.search;
      const urlParams = new URLSearchParams(currentURL);
      // const fprValue = urlParams.get('fpr');
      setFprValue(urlParams.get('fpr'));
    }, [location.search]);

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: '',
        password: '',
        fname: '',
        lname: '',
      },
      resolver: yupResolver(schemaValidationSignUp),
    });

    const handleCaptchaChange = (value) => {
      setCaptchaValue(value);
    };

    const onSignup = async ({ email, password, fname, lname }) => {
      setLoadingSingUp(true);
      try {
        setError('');
        if (!captchaValue) {
          toast.error(
            "Please verify that you're human by completing the captcha"
          );
          setLoadingSingUp(false);
          return;
        }
        await fetch(baseURL + 'auth/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
            fname,
            lname,
            referral: store.referral,
            fpr: fprValue,
          }),
        }).then(async (response) => {
          const data = await response.json();
          toast(data.message, {
            icon: '✉️',
            duration: 12000,
          });
          // Handle error
          if ([401, 400, 404].includes(response.status)) {
            if (data?.message) {
              toast.error(data?.message);
              setLoadingSingUp(false);
              return;
            }
          }
          // Navigate to chat page if user is verified

          store.loginWithDataTokenAndProfile(data);
          store.sendMetricsEvent('login', null, { method: 'signup' });
          if (sessionStorage.getItem('redirectUrl')) {
            navigate(sessionStorage.getItem('redirectUrl'));
            setLoadingSingUp(false);
          } else {
            navigate('/chat');
            setLoadingSingUp(false);
          }
        });
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const signUpGoogle = async (event) => {
      if (fprValue) {
        window.open(baseURL + 'auth/google/callback?fpr=' + fprValue, '_self');
      } else {
        window.open(baseURL + 'auth/google/callback', '_self');
      }
    };

    const onSubmit = (data) => {
      store?.setFpr(fprValue);
      onSignup(data);
    };

    const ErrorMessage = ({ message }) => (
      <div className="pl-2 mt-2">
        <p className="text-red-600 text-xs italic">{message}</p>
      </div>
    );

    return (
      <LoginLayout>
        <form className="pt-8 pb-6 lg:pt-24" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-seasalt w-full max-w-md mx-auto pt-8 lg:pt-8">
            <div className="text-3xl font-bold mb-4 text-center uppercase">
              {translator('sectionSignUp.labelTitle')}
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldFirstName')}
              </label>
              <input
                {...register('fname')}
                type="text"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderFirstName')}
              />
              {errors.fname && <ErrorMessage message={errors.fname.message} />}
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldLastName')}
              </label>
              <input
                {...register('lname')}
                type="text"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderLastName')}
              />
              {errors.lname && <ErrorMessage message={errors.lname.message} />}
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldEmail')}
              </label>
              <input
                {...register('email')}
                type="email"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderEmail')}
              />
              {errors.email && <ErrorMessage message={errors.email.message} />}
            </div>
            <div className="mb-4">
              <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldPassword')}
              </label>
              <input
                {...register('password')}
                type="password"
                className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderPassword')}
              />
              {errors.password && (
                <ErrorMessage message={errors.password.message} />
              )}
            </div>
            {error ? <div className="text-red-600">{error}</div> : null}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={handleCaptchaChange}
              style={{
                transform: 'scale(0.77)',
                WebkitTransform: 'scale(0.77)',
                transformOrigin: 'center center',
                WebkitTransformOrigin: 'center center',
              }}
            />
            <div className="flex flex-col mb-4 mt-4">
              <button
                type="submit"
                className="bg-nue-blue hover:bg-lavender text-white font-bold py-2 px-4 rounded-md uppercase"
              >
                {!loadingSingUp && translator('sectionSignUp.buttonSignUp')}{' '}
                {loadingSingUp && (
                  <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                )}
              </button>
            </div>
            <div className="flex flex-col text-center mb-4">
              <p className="text-sm">or</p>
              <div
                onClick={signUpGoogle}
                className="flex items-center hover:bg-lavender font-bold rounded-md px-4 py-2 bg-ghost-white mt-4 border border-lavender cursor-pointer justify-center"
              >
                <span>
                  <img
                    src="/images/logo-google-oauth.png"
                    className="mr-2"
                  ></img>
                </span>
                <span className="uppercase text-sm">Sign in with Google</span>
              </div>
            </div>
            <div className="text-center mb-2">
              <p className="text-sm">
                Already in Straico?{' '}
                <NavLink to="/login" className="text-violet-blue text-sm">
                  Log in
                </NavLink>
              </p>
            </div>
            <div className="text-center mt-4">
              <p className="text-graphite-400 text-sm">
                <a
                  href="https://www.straico.com/"
                  target="_blank"
                  className="text-violet-blue text-sm"
                  rel="noreferrer"
                >
                  Learn more about us
                </a>
              </p>
            </div>
          </div>
        </form>
      </LoginLayout>
    );
  })
);

export const ForgotSection = () => {
  const { translator } = useContext(TranslateContext);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');

  const onChange = (val, attr) => {
    if (attr === 'email') setEmail(val);
    setError('');
  };

  const onForgot = async (e) => {
    try {
      e.preventDefault();
      setError('');
      await fetch(baseURL + '/forgot/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      }).then(async (response) => {
        const data = await response.json();
        toast.success(data.message, { duration: 10000 });
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };
  return (
    <LoginLayout>
      <form onSubmit={onForgot}>
        <div className="bg-seasalt px-4 w-80 pt-8 lg:pt-0">
          <div className="mt-3 mb-6 text-center ">
            <div className="text-3xl font-bold  mb-4 text-center uppercase">
              Reset password
            </div>
            <p className="text-sm text-cool-gray">
              Enter your email address below and submit. We will send you a mail
              containing a password reset link.
            </p>
          </div>
          <div className="mb-6">
            <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
              {translator('sectionSignUp.fieldEmail')}
            </label>
            <input
              value={email}
              onChange={(e) => onChange(e.target.value, 'email')}
              type="email"
              className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
              placeholder="kate@mail.com"
            />
          </div>
          <div className="flex flex-col mb-4">
            <button
              type="submit"
              className="bg-nue-blue hover:bg-lavender text-white font-bold py-2 px-4 rounded-md uppercase"
            >
              Send email
            </button>
          </div>
          <div className="text-center">
            <NavLink to="/login" className="text-violet-blue text-sm">
              Back to login
            </NavLink>
          </div>
          <div className="text-center mt-8">
            <p className="text-sm">
              <a
                href="https://www.straico.com/"
                target="_blank"
                className="text-violet-blue text-sm"
                rel="noreferrer"
              >
                Learn more about us
              </a>
            </p>
          </div>
        </div>
      </form>
    </LoginLayout>
  );
};

const LanguageSelectorComponent = ({ changer }) => {
  return (
    <div className="container mx-auto text-right mt-2">
      <button
        className="hover:bg-green-500 bg-gray-200 font-medium rounded-md text-xs px-3 py-1 hover:text-white mt-2 inline-block"
        onClick={() => changer('en')}
      >
        EN
      </button>
      <button
        className="hover:bg-green-500 bg-gray-200 font-medium rounded-md text-xs px-3 py-1 hover:text-white mt-2 inline-block"
        onClick={() => changer('es')}
      >
        ES
      </button>
    </div>
  );
};
