import { useRef, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import {
  Dialog,
  Typography,
  Box,
  Slider,
  Button,
  CircularProgress,
  Tooltip,
  keyframes,
} from '@mui/material';
import {
  XMarkIcon,
  SparklesIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react/24/outline';
import { useTheme } from '@mui/material/styles';

const fadeInSlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

function hexToRgba(hex, alpha = 1) {
  const cleanedHex = hex.replace('#', '');
  const r = parseInt(cleanedHex.substring(0, 2), 16);
  const g = parseInt(cleanedHex.substring(2, 4), 16);
  const b = parseInt(cleanedHex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Helper function to convert a base64 DataURL to a Blob
function dataURLToBlob(dataURL) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const ImageMaskEditorModal = ({
  isOpen,
  onRequestClose,
  imageUrl,
  store,
  onSend,
}) => {
  const theme = useTheme();
  const [cursorSize, setCursorSize] = useState(10);
  const [editText, setEditText] = useState('');
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  const canvasRef = useRef(null);

  // Load image and calculate dimensions
  useEffect(() => {
    if (!imageUrl) return;

    setLoading(true);
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const maxWidth = 700;
      const maxHeight = 500;
      const scaleFactor = Math.min(
        maxWidth / img.naturalWidth,
        maxHeight / img.naturalHeight,
        1
      );

      setImageDimensions({
        width: img.naturalWidth * scaleFactor,
        height: img.naturalHeight * scaleFactor,
      });
      setLoading(false);
    };

    img.onerror = () => {
      console.error('Error loading image.');
      setLoading(false);
    };
  }, [imageUrl]);

  const primaryColor = theme.palette.primary.main;
  const brushColor = hexToRgba(primaryColor, 0.3);

  const draw = (x, y) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    if (x < 0 || y < 0 || x > canvas.width || y > canvas.height) {
      return;
    }

    ctx.fillStyle = brushColor;
    ctx.beginPath();
    ctx.arc(x, y, cursorSize, 0, Math.PI * 2);
    ctx.fill();
  };

  const handlePointerDown = (e) => {
    setIsDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    draw(x, y);
  };

  const handlePointerMove = (e) => {
    if (!isDrawing) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    draw(x, y);
  };

  const handlePointerUp = () => {
    setIsDrawing(false);
  };

  /**
   * Reset the editor state: clear the text and the canvas.
   */
  const resetEditor = () => {
    setEditText('');
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  /**
   * Called after a successful edit or when closing the modal.
   */
  const handleClose = () => {
    resetEditor();
    onRequestClose();
  };

  const handleSubmitEdits = async () => {
    if (!editText.trim()) {
      toast.error('Please enter a prompt before submitting edits.');
      return;
    }
    if (!canvasRef.current) {
      console.error('Canvas is not available.');
      return;
    }
    setLoading(true);
    try {
      const canvas = canvasRef.current;

      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        const originalCanvas = document.createElement('canvas');
        originalCanvas.width = img.naturalWidth;
        originalCanvas.height = img.naturalHeight;

        const originalCtx = originalCanvas.getContext('2d');

        originalCtx.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          img.naturalWidth,
          img.naturalHeight
        );

        const maskDataUrl = originalCanvas.toDataURL('image/png');

        const editData = [
          {
            type: 'image_url',
            image_url: imageUrl,
          },
          {
            type: 'mask_url',
            mask_url: maskDataUrl,
          },
        ];

        onSend(null, editText, null, [], true, editData);

        // Cierra el modal después de enviar los datos
        onRequestClose();
      };
    } catch (error) {
      console.error('Error editing the image:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!imageUrl) return;

    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const maxWidth = 700; // Máximo ancho permitido
      const maxHeight = 500; // Máximo alto permitido
      const scaleFactor = Math.min(
        maxWidth / img.naturalWidth,
        maxHeight / img.naturalHeight,
        1 // No escalar más allá del tamaño original
      );

      setImageDimensions({
        width: img.naturalWidth * scaleFactor,
        height: img.naturalHeight * scaleFactor,
      });
    };

    img.onerror = () => {
      console.error('Error loading image.');
    };
  }, [imageUrl]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose} // when user closes, reset and close
      PaperProps={{
        sx: {
          width: '90%',
          maxWidth: '800px',
          maxHeight: '90vh',
          p: 3,
          borderRadius: '20px',
          bgcolor: theme.palette.mode === 'dark' ? '#252425' : '#F7F7F8',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Figtree, sans-serif',
          transition: 'width 0.5s, height 0.5s',
          animation: `${fadeInSlideUp} 0.4s ease-in-out`,
          position: 'relative',
        },
        elevation: 0,
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box position="absolute" top={16} right={16}>
        <XMarkIcon
          className={`h-6 w-6 cursor-pointer ${
            theme.palette.mode === 'dark' ? 'text-white' : 'text-gray-500'
          }`}
          onClick={handleClose}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={2} width="100%">
        <SparklesIcon
          className="h-6 w-6 mr-2"
          style={{
            color: theme.palette.mode === 'dark' ? '#7379FF' : '#464BBA',
          }}
        />
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            fontSize: '1.8rem',
            fontFamily: 'Figtree, sans-serif',
            flexGrow: 1,
          }}
        >
          Image Mask Editor
        </Typography>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <CircularProgress />
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              textAlign: 'center',
              mt: 2,
              fontFamily: 'Figtree, sans-serif',
            }}
          >
            Editing your image...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
              width: `${imageDimensions.width}px`, // Dimensiones de la imagen
              height: `${imageDimensions.height}px`,
              overflow: 'hidden', // Para evitar desbordamientos
              margin: '0 auto', // Centrar el contenedor horizontalmente
            }}
          >
            <img
              src={imageUrl}
              alt="Editable"
              style={{
                width: '100%', // Coincidir con el contenedor
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'none',
                zIndex: 1, // Detrás del canvas
              }}
            />

            <canvas
              ref={canvasRef}
              width={imageDimensions.width}
              height={imageDimensions.height}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2,
                cursor: 'crosshair',
              }}
              onPointerDown={handlePointerDown}
              onPointerMove={handlePointerMove}
              onPointerUp={handlePointerUp}
              onPointerLeave={handlePointerUp}
            />
          </Box>

          <Box sx={{ width: '100%', mb: 2 }}>
            <Tooltip
              title="Use the brush to paint the areas you want to mask. You can adjust the brush size below."
              arrow
              placement="top"
            >
              <Typography
                variant="body2"
                fontWeight="bold"
                mb={1}
                sx={{
                  fontFamily: 'Figtree, sans-serif',
                  color: theme.palette.mode === 'dark' ? '#EFEFEF' : '#6e6e6e',
                  cursor: 'help',
                }}
              >
                Brush Size
              </Typography>
            </Tooltip>

            <Slider
              value={cursorSize}
              onChange={(e, value) => setCursorSize(value)}
              min={1}
              max={50}
              sx={{ mb: 2 }}
            />
            <Typography
              variant="body2"
              mb={3}
              sx={{
                fontFamily: 'Figtree, sans-serif',
                color: theme.palette.mode === 'dark' ? '#EFEFEF' : '#6e6e6e',
              }}
            >
              Current Size: {cursorSize}px
            </Typography>

            <Typography
              variant="body2"
              fontWeight="bold"
              mb={1}
              sx={{
                fontFamily: 'Figtree, sans-serif',
                color: theme.palette.mode === 'dark' ? '#EFEFEF' : '#6e6e6e',
              }}
            >
              Edit Instructions
            </Typography>
            <textarea
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              placeholder="Describe the desired edits here..."
              rows="4"
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '8px',
                border: `1px solid ${
                  theme.palette.mode === 'dark' ? '#444' : '#ccc'
                }`,
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#333' : '#fff',
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                fontFamily: 'Figtree, sans-serif',
                fontSize: '0.9rem',
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmitEdits}
              sx={{
                borderRadius: '8px',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontFamily: 'Figtree, sans-serif',
              }}
            >
              Submit Edits
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClose}
              sx={{
                borderRadius: '8px',
                textTransform: 'capitalize',
                fontFamily: 'Figtree, sans-serif',
              }}
            >
              Close
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default ImageMaskEditorModal;
