import showdown from 'showdown';
import jsPDF from 'jspdf';
import { callAddFont } from './Figtree-VariableFont_wght-normal';
import straicoText from '../../../../Theme/icons/png/straico_text.png';
import straicoSymbol from '../../../../Theme/icons/png/straico_symbol.png';
import Modal from 'Components/Common/Modal/NewModal';

const formatChatDataForTxt = (chatData) => {
  let txtContent = '';
  // Format the chat data for the .txt file (moved from backend)
  chatData.messages.forEach((messageGroup) => {
    if (Array.isArray(messageGroup)) {
      messageGroup.forEach((message) => {
        if (message.data.role === 'assistant') {
          txtContent += `<${message.model}>: ${message.data.content}\n`;
        }
      });
    } else {
      if (messageGroup.data.role === 'assistant') {
        txtContent += `<${messageGroup.model}>: ${messageGroup.data.content}\n`;
      } else {
        // Handle user messages, including images
        if (Array.isArray(messageGroup.data.content)) {
          messageGroup.data.content.forEach((contentItem) => {
            if (contentItem.type === 'text') {
              txtContent += `<user>: ${contentItem.text}\n`;
            } else if (contentItem.type === 'image_url') {
              txtContent += `<image src="${contentItem.image_url.url}"/>\n`;
            }
          });
        } else {
          if (typeof messageGroup.data.content === 'string') {
            txtContent += `<user>: ${messageGroup.data.content}\n`;
          }
        }
      }
    }
  });
  return txtContent;
};

const formatChatDataForMarkdown = (chatData) => {
  const escapeMarkdown = (text) => {
    return text
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\[/g, '\\[')
      .replace(/\]/g, '\\]');
  };

  const formatContent = (content) => {
    if (Array.isArray(content)) {
      return content
        .map((item) => {
          if (item.type === 'text') return escapeMarkdown(item.text);
          if (item.type === 'image') return `**Image URL**: ${item.image.url}`;
          if (item.type === 'image_url')
            return `**Image URL**: ${item.image_url.url}`;
          return '';
        })
        .join('\n');
    }
    return escapeMarkdown(content);
  };

  const formatMessage = (msg) => {
    const role = msg.data.role === 'assistant' ? msg.model : 'user';
    const content = formatContent(msg.data.content);
    return { role, content };
  };

  return chatData.messages
    .flatMap((messageGroup) =>
      Array.isArray(messageGroup) ? messageGroup : [messageGroup]
    )
    .map(formatMessage);
};

const generatePDF = (chatData, cleanedChatTitle) => {
  try {
    // Get formatted messages array
    const formattedMessages = formatChatDataForMarkdown(chatData);

    // Convert each message to HTML with styled role and content
    const messagesDivs = formattedMessages
      .map(({ role, content }) => {
        // Split content into lines to process each separately
        const lines = content.split('\n');
        const processedLines = lines
          .map((line) => {
            // Handle bullet points with bold titles
            const bulletListPattern =
              /^([*-])\s+\*\*([^*]+?)(?:\*\*:|\*\*|:\*\*)\s*$/;
            if (bulletListPattern.test(line)) {
              return line.replace(bulletListPattern, '$1 ### $2');
            }

            // Standalone bold titles
            const standalonePattern =
              /^\s*\*\*([^*]+?)(?:\*\*:|\*\*|:\*\*)\s*$/;
            if (standalonePattern.test(line)) {
              return line.replace(standalonePattern, '### $1');
            }

            return line;
          })
          .join('\n');

        // Remove remaining bold syntax
        const sanitizedContent = processedLines.replace(/\*\*(.*?)\*\*/g, '$1');

        const htmlContent = new showdown.Converter({
          literalMidWordUnderscores: true,
          tables: true,
          simpleLineBreaks: true,
          ghCodeBlocks: true,
          tablesHeaderId: true,
          parseImgDimensions: true,
          listStyle: true,
        }).makeHtml(sanitizedContent);

        if (role === 'user') {
          return `
          <div class="message-container">
            <div class="user-message">
              ${htmlContent}
            </div>
          </div>
        `;
        } else {
          return `
          <div class="message-container">
            <div class="model-message">
              <div class="model-name">${role}</div>
              ${htmlContent}
            </div>
          </div>
        `;
        }
      })
      .join('');

    const styledHtmlContent = `
      <div class="chat-container">
        <div class="chat-header">
          <div class="header-content">
            <img src="${straicoSymbol}" class="header-logo" alt="Straico Logo"/>
            <div class="header-text">
              <div class="chat-title">${chatData.title}</div>
              <div class="chat-date">Date: ${new Date().toLocaleDateString()}</div>
            </div>
          </div>
        </div>
        <br>
        <style>

          img, svg, video, canvas, audio, iframe, embed, object { display: initial; vertical-align: initial; }

          .chat-container {
            width: 1052px;
            font-family: 'Figtree', sans-serif !important;
            padding: 20px;
          }

          .chat-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #464bba;
            padding: 13px 30px;
            border-radius: 14px;
            width: 1052px;
            min-height: 60px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          .header-content {
            display: flex;
            align-items: center;
            gap: 20px;
          }

          .header-logo {
            height: 40px;
            width: auto;
          }

          .header-text {
            display: flex;
            flex-direction: column;
          }

          h1 {
            font-size: 28px !important;
            font-weight: 600 !important;
            margin: 16px 0 8px 0 !important;
            padding: 0 !important;
            color:rgb(0, 0, 0) !important;
            page-break-after: avoid !important;
          }
          
          /* Ensure headers don't get split across pages */
          h1 + * {
            page-break-before: avoid !important;
          }

          h2 {
            font-size: 24px !important;
            font-weight: 600 !important;
            margin: 16px 0 8px 0 !important;
            padding: 0 !important;
            color:rgb(0, 0, 0) !important;
            page-break-after: avoid !important;
          }
          
          /* Ensure headers don't get split across pages */
          h2 + * {
            page-break-before: avoid !important;
          }

          h3 {
            font-size: 20px !important;
            font-weight: 600 !important;
            margin: 16px 0 8px 0 !important;
            padding: 0 !important;
            color:rgb(0, 0, 0) !important;
            page-break-after: avoid !important;
            font-family: 'Figtree', sans-serif !important;
          }
          
          /* Ensure headers don't get split across pages */
          h3 + * {
            page-break-before: avoid !important;
          }

          .chat-title {
            font-size: 28px;
            font-weight: bold;
            color: white;
            line-height: 34px;
          }

          .chat-date {
            font-size: 22px;
            color:rgb(181, 181, 182);
          }

          .message-container {
            margin-bottom: 30px;
            width: 1052px;
          }

          .user-message {
            background-color:rgb(209, 209, 231);
            padding: 16px;
            border-radius: 20px 0 20px 20px;
            font-size: 16px;
            line-height: 24px;
            min-height: 56px;
            color: black;
          }

          .model-message {
            background-color: #ededf2;
            min-height: 234px;
            padding: 16px;
            border-radius: 0 20px 20px 20px;
            color: black;
          }

          .model-name {
            color: #464bba;
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
            margin-bottom: 10px;
            text-transform: uppercase;
          }

          code {
            display: block;
            background-color: #2d2d2d;
            color: #ffffff;
            padding: 8px;
            margin: 8px auto;
            font-family: monospace;
            font-size: 16px;
            white-space: pre;
            border-left: 3px solid #666;
            text-align: left;
            border-radius: 12px;
          }

          /* Table Styling */

          table {
            width: 100%;
            border-collapse: collapse;
            font-family: 'Figtree', sans-serif !important;
          }

          th, td {
            padding: 8px;
            text-align: left;
            border-bottom: 1px solid #464bba;
          }

          th {
            border-bottom: 2px solid #464bba;
            color: #212529;
          }

          tr:hover {
            background-color: #f5f5f5;
          }

          /* Optional: Add some spacing between columns */
          td, th {
            padding-right: 15px;
          }

          /* List styling */
          ul, ol {
            display: block !important;
            padding-left: 40px !important;
            margin: 0 !important;
            list-style-position: outside !important;
            line-height: normal !important;
          }

          ul {
            list-style-type: disc !important;
          }

          ol {
            list-style-type: decimal !important;
          }

          li {
            display: list-item !important;
            margin: 0 !important;
            padding: 0 0 0 10px !important;
            white-space: normal !important;
            line-height: 1.5 !important;  /* Added line spacing within list items */
          }

          /* Force list styles in PDF */
          .user-message ul, .user-message ol,
          .model-message ul, .model-message ol {
            padding-left: 40px !important;
            margin: 0 !important;
            line-height: normal !important;
          }

          .user-message li,
          .model-message li {
            display: list-item !important;
            page-break-inside: avoid;
            white-space: normal !important;
            padding: 0 0 0 10px !important;
            margin: 4px 0 !important;  /* Added vertical spacing */
            line-height: 1.5 !important;  /* Added line spacing within list items */
            
          }

          .footer {
              display: flex;
              justify-content: center;
              margin-top: 40px;
              padding: 20px;
              border-top: 1px solid #eee;
            }
            .footer-logo {
              height: 50px;
              width: auto;
              align-items: center;
            }
        </style>
        ${messagesDivs}
      </div>
    `;

    jsPDF.API.events.push(['addFonts', callAddFont]);

    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
      orientation: 'portrait',
      hotfixes: ['px_scaling'],
    });
    doc.addFont(
      'Figtree-VariableFont_wght-normal.ttf',
      'Figtree',
      'normal',
      'normal'
    );
    doc.setFont('Figtree', 'normal');

    doc.html(styledHtmlContent, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const logoHeight = 15;
        const logoWidth = 60;
        const logoMargin = 55;

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFont('Figtree', 'normal');
          doc.setFontSize(8);
          doc.setTextColor(100);

          // Add logo to left side of footer
          doc.addImage(
            straicoText,
            'PNG',
            logoMargin,
            pageHeight - 45,
            logoWidth,
            logoHeight
          );

          // Add clickable area over logo
          doc.link(logoMargin, pageHeight - 50, logoWidth, logoHeight, {
            url: 'https://straico.com/',
          });

          // Add page numbers to right side
          doc.text(
            `${i}/${pageCount}`,
            pageWidth - logoMargin,
            pageHeight - 30,
            { align: 'right' }
          );
        }
        doc.save(`chat-${cleanedChatTitle}.pdf`);
      },
      html2canvas: {
        scale: 0.63,
        useCORS: true,
        logging: false,
        allowTaint: true,
      },
      autoPaging: 'text',
      margin: [70, 50, 60, 50],
    });

    return doc;
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

const downloadTxtFile = (content, filename) => {
  const blob = new Blob([content], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const DownloadChat = ({ chatId, onClose, onDownloadTxt, onDownloadPdf }) => {
  return (
    <Modal open={true} title="Download Chat" onClose={onClose}>
      <div className="font-figtree text-base text-black dark:text-crystal-bell text-center">
        What format do you want to download the chat's contents?
      </div>
      <div className="mt-4 flex justify-center space-x-4">
        <button
          onClick={onDownloadTxt}
          className="px-4 py-2 bg-blue-500 text-white rounded"
          style={{ backgroundColor: '#464bba', fontWeight: 'bold' }}
        >
          Download as .txt
        </button>
        <button
          onClick={onDownloadPdf}
          className="px-4 py-2 bg-red-500 text-white rounded"
          style={{ backgroundColor: '#464bba', fontWeight: 'bold' }}
        >
          Download as .pdf
        </button>
      </div>
    </Modal>
  );
};

export {
  formatChatDataForTxt,
  formatChatDataForMarkdown,
  generatePDF,
  downloadTxtFile,
  DownloadChat as default,
};
