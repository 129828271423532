import {
  AcademicCapIcon,
  ArchiveBoxIcon,
  ArrowPathRoundedSquareIcon,
  ArrowsPointingOutIcon,
  CalculatorIcon,
  ChatBubbleLeftIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  LightBulbIcon,
  NoSymbolIcon,
  PencilIcon,
  PhotoIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';

export const APPLICATIONS_MAP = [
  { application: 'writing', icon: PencilIcon },
  { application: 'translation', icon: GlobeAltIcon },
  { application: 'content', icon: DocumentTextIcon },
  { application: 'reasoning', icon: LightBulbIcon },
  { application: 'math_and_logic', icon: CalculatorIcon },
  { application: 'coding', icon: CodeBracketIcon },
  { application: 'social_chat', icon: ChatBubbleLeftIcon },
  { application: 'tutoring', icon: AcademicCapIcon },
  { application: 'roleplay', icon: UserGroupIcon },
];

export const FEATURES_MAP = [
  { feature: 'image_input', icon: PhotoIcon, label: 'Image Input' },
  { feature: 'web_search', icon: GlobeAltIcon, label: 'Real-time Knowledge' },
];

export const CAPABILITIES_MAP = [
  { capability: 'browsing', icon: GlobeAltIcon, label: 'Web Browsing' },
  {
    capability: 'image_generation',
    icon: PhotoIcon,
    label: 'IDEOGRAM IMAGE GENERATION',
  },
  {
    capability: 'image_remix',
    icon: ArrowPathRoundedSquareIcon,
    label: 'IMAGE REMIX',
  },
  {
    capability: 'image_upscale',
    icon: ArrowsPointingOutIcon,
    label: 'IMAGE UPSCALE',
  },
];

export const OTHERS_MAP = [
  { other: 'uncensored', icon: NoSymbolIcon },
  { other: 'deprecated', icon: ArchiveBoxIcon },
  { other: 'unavailable', icon: ExclamationTriangleIcon },
];
