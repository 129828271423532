import { styled, Tooltip, tooltipClasses } from '@mui/material';

const BlurTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    slotProps={{
      tooltip: {
        sx: {
          background: 'rgba(255, 255, 255, 0.3) !important',
          backdropFilter: 'blur(3px) !important',
        },
      },
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.text.primary,
    borderRadius: '8px',
    fontSize: '14px',
    padding: '8px 12px',
    boxShadow: theme.shadows[2],
    position: 'relative', // Esto es importante para que el efecto funcione
    zIndex: 9999,
  },
}));

export default BlurTooltip;
