/* eslint-disable no-constant-condition */
import { UserCircleIcon } from '@heroicons/react/20/solid';
import {
  AdjustmentsVerticalIcon,
  ArrowLeftEndOnRectangleIcon,
  CogIcon,
} from '@heroicons/react/24/outline';
import {
  CreditCardIcon,
  UserGroupIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Container } from '@mui/material';
import ModalConfirmClose from 'Features/CreateTemplatesForm/Components/ModalConfirmClose';
import { inject, observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const ProfileInfo = ({ store }) => {
  const { theme } = useThemeStore();
  const user = store.profile;

  const navigate = useNavigate();

  const onClickCard = (path, onClick) => () => {
    if (path) navigate(path);
    if (onClick) onClick(store);
  };

  const OPTIONS = {
    'Profile settings': [
      {
        icon: CogIcon,
        label: 'Your Preferences',
        path: '/user-settings',
        description:
          'Set your default options and control your privacy settings',
      },
      {
        icon: UserGroupIcon,
        label: 'Your Profile',
        description: 'Customize your profile and how your info is displayed',
        path: '/my-profile/detail',
      },
      {
        icon: UserCircleIcon,
        label: 'Your avatar',
        description: 'Design your avatar and make it unique',
        path: '/my-avatar',
      },
    ],
    Membership: [
      {
        label: 'Subscription Plans',
        icon: AdjustmentsVerticalIcon,
        description: 'Manage your subscription and payment information',
        path: '/my-profile/pricing',
      },
      {
        component: (
          <>
            {store.profile.scope === 'general' && (
              <ToolForm
                Icon={CreditCardIcon}
                label="Payment"
                api={store.api}
                description={
                  'Update card details, check invoices, or cancel the plan anytime'
                }
                to={store.baseURL + 'user/stripe/customer-portal'}
              />
            )}
          </>
        ),
      },
      {
        component: (
          <>
            {store.profile.plan != 'free' &&
              (store.profile.status === 'active' ||
                store.profile.status === 'trialing') &&
              store.profile.scope === 'general' && (
                <ToolForm
                  Icon={XCircleIcon}
                  label="Cancel subscription"
                  api={store.api}
                  description={
                    'Immediate cancellation of your subscription and payments'
                  }
                  to={store.baseURL + 'user/stripe/cancel'}
                  showConfirm={store.profile.status === 'trialing'}
                />
              )}
          </>
        ),
      },
    ],
    Platform: [
      {
        label: 'Sign out',
        icon: ArrowLeftEndOnRectangleIcon,
        description: 'Securely log out of your account',
        onClick: (store) => {
          store.handleLogout();
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="md"
      className={cn('overflow-y-auto overflow-rtl', {
        'overflow-rtl-dark': theme === 'dark',
        'overflow-rtl-light': theme !== 'dark',
      })}
      sx={{
        margin: 0,
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        className={cn('my-4 mb-10', {
          'text-white': theme === 'dark',
          'text-raisin-black': theme !== 'dark',
        })}
      >
        <h1 className={cn('text-3xl font-bold')}>
          Welcome back, {user?.fname ?? '--'}!
        </h1>
        <p className="text-xl mt-2">
          This is your personal dashboard. Here, you can manage your settings
          and customize your <br /> profile.
        </p>
        <div className="flex items-center w-full gap-4 mt-4">
          <div>
            {user?.avatar?.svg ? (
              <div className="avatar">
                <div className="w-16 rounded">
                  <div
                    dangerouslySetInnerHTML={{ __html: user?.avatar?.svg }}
                  />
                </div>
              </div>
            ) : (
              <UserCircleIcon className="size-14 -mb-1 text-cool-gray dark:text-battleship-gray cursor-pointer" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-md font-bold">{user?.email}</h2>
            <div className="flex gap-2 items-center">
              <p className="text-sm font-bold">{user?.plan_description}</p>
              <Badge size="small">Active</Badge>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          {Object.keys(OPTIONS).map((category) => (
            <div key={category} className="flex flex-col  w-full">
              <h3 className="text-xl font-bold my-8">{category}</h3>
              <div className="flex items-center gap-7 flex-wrap justify-center md:justify-start">
                {OPTIONS[category].map((option) =>
                  option.component ? (
                    option.component
                  ) : (
                    <div
                      key={option.label}
                      onClick={onClickCard(option?.path, option?.onClick)}
                      className={cn(
                        'rounded-xl w-[260px] h-auto md:h-[94px] p-4 shadow-settings-card cursor-pointer hover:scale-105 ease-in-out transition-transform ',
                        {
                          'bg-white hover:text-nue-blue': theme !== 'dark',
                          'bg-night-black hover:text-crystal-bell':
                            theme === 'dark',
                        }
                      )}
                    >
                      <div className="flex items-center gap-2 mb-2">
                        <option.icon className="h-6 w-6" />
                        <h4 className="font-bold tex-sm">{option.label}</h4>
                      </div>
                      <div>
                        <p className="text-xs">{option.description}</p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default inject('store')(observer(ProfileInfo));

function Badge() {
  const { theme } = useThemeStore();
  return (
    <div
      className={cn(
        'inline-flex items-center justify-center bg-white rounded-md shadow-sm px-2',
        {
          'text-[#7F82C3] bg-[#3B3B45]': theme === 'dark',
          'bg-[#E1E1E5] text-[#9091A4]': theme !== 'dark',
        }
      )}
    >
      <span className="text-[10px] font-bold relative">ACTIVE</span>
    </div>
  );
}

const ToolForm = ({
  Icon,
  label,
  description,
  to,
  api,
  showConfirm = false,
}) => {
  const { theme } = useThemeStore();
  const [cancelTrialOpen, setCancelTrialOpen] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    setCancelTrialOpen(false);
    submitForm();
  };

  const handleSubmit = () => {
    if (showConfirm) {
      setCancelTrialOpen(true);
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <>
      <form
        ref={formRef}
        action={to}
        method="POST"
        className="flex relative font-figtree"
      >
        <input
          type="hidden"
          name="token"
          value={api.defaults.headers.common['x-access-token']}
        />
        <div onClick={handleSubmit} className="flex-1 text-left">
          <div
            className={cn(
              'rounded-xl w-[260px] h-auto md:h-[94px] p-4 shadow-settings-card cursor-pointer hover:scale-105 ease-in-out transition-transform ',
              {
                'bg-white hover:text-nue-blue': theme !== 'dark',
                'bg-[#39383A] hover:text-snow-800': theme === 'dark',
              }
            )}
          >
            <div className="flex items-center gap-2 mb-2">
              <Icon className="h-6 w-6" />
              <h4 className="font-bold tex-sm">{label}</h4>
            </div>
            <div>
              <p className="text-xs">{description}</p>
            </div>
          </div>
        </div>
      </form>
      <ModalConfirmClose
        open={cancelTrialOpen}
        onCancel={() => setCancelTrialOpen(false)}
        onClose={handleClose}
        title="Are you sure?"
        description="Be aware that by cancelling your trial, you will forfeit any unused coins and will automatically return to the free tier service."
        confirmButtonText="Accept"
        cancelButtonText="Cancel"
      />
    </>
  );
};
