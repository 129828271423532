import { useState } from 'react';

const usePopoverState = (customId) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? customId : undefined;

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return { anchorEl, open, id, handleClick, handleClose };
};

export default usePopoverState;
