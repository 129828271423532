/* eslint-disable no-console */
import { PLANS } from 'Constants/user';
import useModelsModalStore from 'Features/ModelsModal/store';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const useModelsAndCapabilities = ({ store, setModelsList }) => {
  const [models, setModels] = useState([]);
  const [freePlanModels, setFreePlanModels] = useState([]);
  const [capabilities, setCapabilities] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    search,
    sort,
    features,
    capabilities: capabilitiesOptions,
    applications,
    other,
    favorites,
    editorsChoice,
    refetch,
  } = useModelsModalStore();

  useEffect(() => {
    // Use debounce to wait for user to stop typing before fetching
    const debouncedGetModels = debounce(async () => {
      await getModels();
      await getCapabilities();
    }, 500); // 500 ms delay

    debouncedGetModels();

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedGetModels.cancel();
    };
  }, [
    search,
    sort,
    features,
    capabilitiesOptions,
    applications,
    other,
    editorsChoice,
    favorites,
    refetch,
  ]);

  const buildSortString = (sortObj) => {
    if (!sortObj || typeof sortObj !== 'object') return null;

    return Object.entries(sortObj)
      .map(([key, value]) => `${key}:${value === 1 ? 'asc' : 'desc'}`)
      .join(',');
  };

  const getModels = async () => {
    setIsLoading(true);
    try {
      const params = {
        ...(search && { name: search }),
        ...(features.length && { model_features: features.join(',') }),
        ...(capabilitiesOptions.length && {
          model_capabilities: capabilitiesOptions.join(','),
        }),
        ...(applications.length && {
          model_applications: applications.join(','),
        }),
        ...(sort && { sortBy: buildSortString(sort) }),
        ...(editorsChoice && { editorsChoice }),
        ...(other.length && { model_other: other.join(',') }),
        ...(favorites && { favorite: favorites }),
      };

      const {
        data: { data: availableModels },
      } = await store.api.get(`/v2/models/search`, { params });

      if (store.profile.plan === PLANS.FREE_TOS) {
        const {
          data: { rules },
        } = await store.api.get('/user/constant', {
          params: { type: 'princing_plans' },
        });

        const freePlanModels = rules[0].models || [];
        const modelsInFreePlan = availableModels.filter((model) =>
          freePlanModels.includes(model.model)
        );
        const otherModels = availableModels.filter(
          (model) => !freePlanModels.includes(model.model)
        );

        setModels(otherModels);
        setModelsList(otherModels);
        setFreePlanModels(modelsInFreePlan);
      } else {
        setModels(availableModels);
        setModelsList(availableModels);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCapabilities = async () => {
    try {
      const response = await store.api.get(`/capability`);
      setCapabilities(response.data.capabilities);
    } catch (error) {
      console.error('Error fetching capabilities:', error);
    }
  };

  return {
    models,
    setModels,
    freePlanModels,
    capabilities,
    isLoading,
  };
};

export default useModelsAndCapabilities;
