// Mobx
import TextAreaInput from '@straico-ui/forms/TextAreaInput';
import SelectMultipleControlled from 'Components/Forms/SelectMultipleControlled';
import { inject, observer } from 'mobx-react';
// RHF
import { useFormContext } from 'react-hook-form';
// Components
import useThemeStore from 'Theme/store';

const SectionGeneral = ({ models }) => {
  // Form
  const { register, control } = useFormContext();

  const { theme } = useThemeStore();

  const mappedModels = models.map((model) => ({
    id: model._id,
    title: model.name,
  }));

  /* TODO make it multi model */

  return (
    <div className="flex flex-col font-figtree">
      <p className={`text-xl font-bold`}>Step 1: General adjustments</p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label className="uppercase text-sm font-bold">Template name *</label>
          <TextAreaInput
            name="name"
            className={`w-full border ${
              theme == 'dark' ? 'dark:bg-lead ' : 'bg-seasalt '
            } border-platinum border-solid text-sm p-2 rounded-md `}
            placeholder="Name of your prompt"
            {...register('name')}
            rows={2}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-sm font-bold">Description *</label>
          <TextAreaInput
            name="description"
            className={`w-full border ${
              theme == 'dark' ? 'dark:bg-lead ' : 'bg-seasalt '
            } border-platinum border-solid text-sm p-2 rounded-md `}
            placeholder="A description for your prompt"
            {...register('description')}
            rows={3}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-sm font-bold">
            Default models *
          </label>
          <p className="text-xs py-2 text-pretty">
            Need assistance selecting LLMs for your template?{' '}
            <a
              className="text-violet-blue font-bold"
              target="_blank"
              href="https://straico.com/multimodel"
              rel="noreferrer"
            >
              Click here{' '}
            </a>{' '}
            to view related documentation.
          </p>
          <SelectMultipleControlled
            name="multi_default_models"
            options={mappedModels}
            control={control}
            label="Default model"
            formControlProps={{
              className: `w-full ${
                theme === 'dark'
                  ? 'dark:bg-lead dark:text-crystal-bell'
                  : 'bg-seasalt text-raisin-black'
              } border border-platinum text-sm border-solid p-2 rounded-md`,
            }}
            selectProps={{
              sx: {
                '& .MuiSelect-select': {
                  borderRadius: '10px !important',
                  backgroundColor: theme === 'light' ? 'white' : '#39383a',
                  fontSize: '14px',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(SectionGeneral));
