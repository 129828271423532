import { StarIcon } from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import BadgeInformation from 'Features/ModelsModal/components/BadgeInformation';
import BlurTooltip from 'Features/ModelsModal/components/blurTooltip';
import {
  APPLICATIONS_MAP,
  CAPABILITIES_MAP,
  FEATURES_MAP,
} from 'Features/ModelsModal/mocks/constants';
import useModelsModalStore from 'Features/ModelsModal/store';
import { formatDate, getSortIcon } from 'Features/ModelsModal/utils';
import useIsMobile from 'Hooks/useIsMobile';
import { CrownIcon } from 'Theme/icons/crown';
import useThemeStore from 'Theme/store';
import { formatNumberLikeSocialMedia } from 'utils/commons';
import { cn } from 'utils/styles';

export default function TableModels({
  models,
  handleStarred,
  isSelected,
  onSelect,
}) {
  const isMobile = useIsMobile();
  const { theme } = useThemeStore();
  const isDark = theme === 'dark';
  // sort filter
  const { sort, setSort } = useModelsModalStore();

  const getSortIconTable = (field) => {
    return getSortIcon(field, sort, true);
  };

  const onSortChange = (field) => (e) => {
    setSort(field);
  };

  const onSelectModel = (model) => {
    onSelect(model);
  };

  const colorRowBg = (model) =>
    `${
      model?.editors_choice_level > 0
        ? !isDark
          ? 'bg-gradient-to-l from-seasalt via-[#D4D5E600] to-[#D4D5E6]'
          : 'bg-gradient-to-l dark:from-[#252425] dark:via-[#252425] dark:to-[#51526C]'
        : ''
    }`;

  return (
    <div className="w-full short:h-[70%] h-[80%] long:h-[85%]">
      <TableContainer
        component={Paper}
        className="w-full mx-auto !font-figtree h-full mt-2 dark:bg-transparent"
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          '::-webkit-scrollbar-thumb': {
            backgroundColor: isDark ? '#5C5A5E' : '#D4D5E6',
            borderRadius: '4px',
          },
        }}
      >
        <Table stickyHeader className="bg-transparent">
          <TableHead>
            <TableRow>
              <TableCell className="bg-seasalt dark:bg-[#252425] ">
                <div
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={onSortChange('name')}
                >
                  <p className="text-md font-bold">Model</p>
                  <span className="ml-2">{getSortIconTable('name')}</span>
                </div>
              </TableCell>
              <TableCell className="bg-seasalt dark:bg-[#252425] "></TableCell>
              <TableCell className="bg-seasalt dark:bg-[#252425] ">
                <div
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={onSortChange('input_limit')}
                >
                  <p className="text-center text-md font-bold">Max. words</p>
                  <span className="ml-2">
                    {getSortIconTable('input_limit')}
                  </span>
                </div>
              </TableCell>
              <TableCell className="bg-seasalt dark:bg-[#252425] ">
                <div
                  className="flex items-center gap-1 cursor-pointer min-w-[120px]"
                  onClick={onSortChange('coinsToSort')}
                >
                  <p className="text-center text-md font-bold">
                    Coins per 100 words
                  </p>
                  <span className="ml-2">
                    {getSortIconTable('coinsToSort')}
                  </span>
                </div>
              </TableCell>
              {!isMobile && (
                <TableCell className="bg-seasalt dark:bg-[#252425] ">
                  <div
                    className="flex items-center gap-1 min-w-[100px] cursor-pointer"
                    onClick={onSortChange('model_runs')}
                  >
                    <p className="text-center text-md font-bold">
                      Last 30-Day Runs
                    </p>
                    <span className="ml-2">
                      {getSortIconTable('model_runs')}
                    </span>
                  </div>
                </TableCell>
              )}
              <TableCell className="bg-seasalt dark:bg-[#252425] ">
                <p className="text-center text-md font-bold">
                  Top Applications
                </p>
              </TableCell>
              {!isMobile && (
                <TableCell className="bg-seasalt dark:bg-[#252425] ">
                  <p className="text-center text-md font-bold">Pros</p>
                </TableCell>
              )}
              {!isMobile && (
                <TableCell className="bg-seasalt dark:bg-[#252425] ">
                  <p className="text-center text-md font-bold">Cons</p>
                </TableCell>
              )}
              {!isMobile && (
                <TableCell className="bg-seasalt dark:bg-[#252425] ">
                  <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={onSortChange('model_date')}
                  >
                    <p className="text-center text-md font-bold">Launch Date</p>
                    <span className="ml-2">
                      {getSortIconTable('model_date')}
                    </span>
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {models?.map((model, index) => (
              <TableRow
                key={index}
                className={cn(
                  `hover:bg-platinum dark:hover:bg-night-black rounded-3xl cursor-pointer mb-1
                  max-h-[300px] overflow-hidden dark:!text-palladium
                  `,
                  {
                    'bg-platinum dark:bg-night-black': isSelected(model),
                    [colorRowBg(model)]: !isSelected(model),
                  }
                )}
                style={{
                  outline: 'none',
                  boxShadow: 'none',
                }}
                onClick={() => onSelectModel(model)}
              >
                <TableCell className="m-w-[300px]">
                  <div className="flex items-center space-x-3">
                    <div className="flex self-start">
                      {model?.editors_choice_level > 0 && (
                        <CrownIcon className="size-4 text-nue-blue dark:text-[#7379FF] " />
                      )}
                    </div>
                    {isSelected(model) ? (
                      <Checkbox
                        checked={isSelected(model)}
                        sx={{
                          p: '0px !important',
                        }}
                      />
                    ) : (
                      <img
                        className="size-6 rounded-lg"
                        src={model?.model_icon}
                        alt={`icon-${model?.name}`}
                      />
                    )}
                    <div>
                      <div className="font-medium">{model.name}</div>
                      <div className="flex gap-1 mt-1 flex-wrap">
                        {model?.model_features?.map((feature, i) => {
                          const featureSelect = FEATURES_MAP.find(
                            (item) => item.feature === feature
                          );
                          const Icon = featureSelect
                            ? featureSelect?.icon
                            : null;

                          return (
                            <BadgeInformation
                              index={i}
                              label={
                                featureSelect ? featureSelect?.label : feature
                              }
                              variant="image"
                              icon={Icon ? <Icon className="size-3" /> : null}
                            />
                          );
                        })}
                        {model?.model_capabilities?.map((capability, i) => {
                          const capabilitySelect = CAPABILITIES_MAP.find(
                            (item) => item.capability === capability
                          );
                          const Icon = capabilitySelect
                            ? capabilitySelect?.icon
                            : null;
                          return (
                            <BadgeInformation
                              index={i}
                              label={
                                capabilitySelect
                                  ? capabilitySelect.label
                                  : capability
                              }
                              variant="browsing"
                              icon={Icon ? <Icon className="size-3" /> : null}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStarred(model);
                    }}
                  >
                    <StarIcon
                      className={`w-5 h-5 ${
                        model?.favorite
                          ? 'text-nue-blue fill-nue-blue'
                          : 'text-cool-gray'
                      }`}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="right text-2xl">
                  {model?.words_text?.toLocaleString()}
                </TableCell>
                <TableCell className="min-w-[50px]">
                  <div className="flex items-center justify-center gap-1">
                    <CurrencyDollarIcon className="w-5 h-5 text-cool-gray dark:text-palladium" />
                    {model?.pricing?.coins}
                  </div>
                </TableCell>
                {!isMobile && (
                  <TableCell className="min-w-[100px]">
                    {formatNumberLikeSocialMedia(model?.model_runs ?? 0)}
                  </TableCell>
                )}
                <TableCell className="max-w-[110px]">
                  <div className="flex flex-wrap gap-1">
                    {model?.model_applications?.map((app, i) => {
                      const appSelect = APPLICATIONS_MAP.find(
                        (item) => item.application === app
                      );
                      const Icon = appSelect ? appSelect?.icon : null;

                      return (
                        <BadgeInformation
                          index={i}
                          label={appSelect ? appSelect?.application : app}
                          variant="default"
                          icon={Icon ? <Icon className="size-3" /> : null}
                        />
                      );
                    })}
                  </div>
                </TableCell>
                {!isMobile && (
                  <TableCell>
                    <BlurTooltip
                      title={model?.model_pros?.join(', ')}
                      placement="top"
                    >
                      <p className="text-sm text-gray-600 line-clamp-3 dark:text-crystal-bell">
                        {model?.model_pros?.join(', ')}
                      </p>
                    </BlurTooltip>
                  </TableCell>
                )}
                {!isMobile && (
                  <TableCell>
                    <BlurTooltip
                      title={model?.model_cons?.join(', ')}
                      placement="top"
                    >
                      <p className="text-sm text-gray-600 line-clamp-3 dark:text-crystal-bell">
                        {model?.model_cons?.join(', ')}
                      </p>
                    </BlurTooltip>
                  </TableCell>
                )}
                {!isMobile && (
                  <TableCell align="right">
                    <span className="text-sm text-gray-500 dark:text-crystal-bell">
                      {formatDate(model?.model_date)}
                    </span>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
