import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';
import { Box, Typography } from '@mui/material';

const EmptyGrid = ({ message = 'No models found for this search.' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="black"
      height="100%"
      textAlign="center"
      p={3}
      className="rounded-3xl dark:bg-night-black !text-nue-blue w-full
      "
    >
      <ArchiveBoxXMarkIcon className="size-20" />
      <Typography variant="h6">{message}</Typography>
      <Typography variant="body2">
        Try using different keywords or adjusting your filtering options.
      </Typography>
    </Box>
  );
};

export default EmptyGrid;
