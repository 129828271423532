const FilterButton = ({ icon: Icon, text, ...props }) => {
  return (
    <button
      className="flex gap-2 px-3 py-2 rounded-xl border border-cool-gray hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors min-w-[128px] justify-between items-center dark:bg-night-black"
      {...props}
    >
      <span className="text-raisin-black dark:text-crystal-bell text-sm">
        {text}
      </span>
      {Icon && <Icon className="w-4 h-4 text-gray-500" />}
    </button>
  );
};

export default FilterButton;
