import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

const CapabilityModelValidator = ({
  capabilitiesSelected,
  modelsSelected,
  setModelsSelected,
  isOpen,
  onClose,
  themeValue,
  onInteraction,
  onCapabilities,
  newModelsSelected,
  setModelValidatorOpen,
}) => {
  // Indicates whether the user has definitively chosen "Don't ask again."
  const [dontAskAgain, setDontAskAgain] = useState(false);

  // Temporary checkbox state (before being confirmed in the irreversible dialog).
  const [tempDontAskAgain, setTempDontAskAgain] = useState(false);

  // Stores the list of incompatible models to display in the main dialog.
  const [incompatibleModels, setIncompatibleModels] = useState([]);

  // Manages secondary dialogs: irreversible confirmation, and "no models" warning.
  const [showIrreversibleDialog, setShowIrreversibleDialog] = useState(false);
  const [showNoModelsDialog, setShowNoModelsDialog] = useState(false);

  // 1. Fetch "dontAskAgain" user preference from localStorage at component mount.
  useEffect(() => {
    const storedDontAskAgain = localStorage.getItem(
      'capabilityCheckDontAskAgain'
    );
    if (storedDontAskAgain === 'true') {
      setDontAskAgain(true);
    }
  }, []);

  /**
   * 2. If the user has opted "Don't ask again," and the modal is open, and there are capabilities:
   *    - We compute the incompatibilities.
   *    - We read the last chosen action from localStorage (Keep or Discard).
   *    - We execute that action automatically without showing the main dialog.
   */
  useEffect(() => {
    if (!capabilitiesSelected || capabilitiesSelected.length === 0) {
      return;
    }

    if (dontAskAgain && isOpen && capabilitiesSelected.length > 0) {
      const newlyFoundIncompatibles = computeIncompatibles(
        modelsSelected,
        capabilitiesSelected
      );

      const lastAction = localStorage.getItem('capabilityCheckLastAction');
      if (lastAction === 'discard') {
        handleDiscard(true, newlyFoundIncompatibles);
      } else {
        handleKeep(true, newlyFoundIncompatibles);
      }
    }
  }, [dontAskAgain, isOpen, capabilitiesSelected]);

  /**
   * 3. If "dontAskAgain" is false, and the dialog is open, we recalculate incompatibility
   *    to display the new list in the UI.
   */
  useEffect(() => {
    if (!capabilitiesSelected || capabilitiesSelected.length === 0) {
      return;
    }

    if (!dontAskAgain && isOpen && capabilitiesSelected.length > 0) {
      checkCompatibility(); // Sets 'incompatibleModels' in state so the main dialog can display them.
    }
  }, [dontAskAgain, isOpen, capabilitiesSelected, modelsSelected]);

  /**
   * Returns an array of incompatible models based on the selected models and capabilities.
   * This function does not alter component state; it merely computes incompatibilities.
   */
  const computeIncompatibles = (models, capabilities) => {
    return models.filter((model) =>
      capabilities.some(
        (capability) =>
          !model.function_calls?.some(
            (func) => func.capability === capability._id
          )
      )
    );
  };

  /**
   * Updates 'incompatibleModels' in the component state, which is needed
   * when the main dialog is actually shown (dontAskAgain = false).
   */
  const checkCompatibility = () => {
    const incompatible = computeIncompatibles(
      modelsSelected,
      capabilitiesSelected
    );
    setIncompatibleModels(incompatible);
  };

  /**
   * Discards all incompatible models.
   * @param {boolean} skipDialog - If true, we skip closing the dialog manually (used for automatic actions).
   * @param {Array} overrideIncompatibles - If provided, we use this list instead of the state 'incompatibleModels'.
   */
  const handleDiscard = (skipDialog = false, overrideIncompatibles = null) => {
    // Use the newly computed incompatibilities if given, otherwise fallback to local state.
    const actualIncompatibles = overrideIncompatibles || incompatibleModels;

    // Filter out any model that's in the incompatible list.
    const filteredModels = modelsSelected.filter(
      (model) => !actualIncompatibles.some((inc) => inc._id === model._id)
    );
    const filteredNewModels = newModelsSelected.filter(
      (model) => !actualIncompatibles.some((inc) => inc._id === model._id)
    );

    // If no models remain, we show a warning dialog and abort.
    const totalRemaining = filteredModels.length + filteredNewModels.length;
    if (totalRemaining === 0) {
      setShowNoModelsDialog(true);
      return;
    }

    // Proceed with normal discard flow.
    setModelsSelected(filteredModels);
    setIncompatibleModels([]);

    // Notify the parent about the updated models and capabilities.
    onInteraction(filteredModels, filteredNewModels);
    onCapabilities(capabilitiesSelected);

    // Save the 'discard' action for future automatic decisions.
    localStorage.setItem('capabilityCheckLastAction', 'discard');

    // If user had selected "Don't ask again" (and confirmed), we set it definitively in localStorage.
    if (tempDontAskAgain) {
      setDontAskAgain(true);
      localStorage.setItem('capabilityCheckDontAskAgain', 'true');
    }
    setModelValidatorOpen(false);
  };

  /**
   * Keeps all models as-is even if they're incompatible.
   * @param {boolean} skipDialog - If true, we skip closing the dialog manually (used for automatic actions).
   * @param {Array} overrideIncompatibles - Optional array to override local state, if needed.
   */
  const handleKeep = (skipDialog = false, overrideIncompatibles = null) => {
    // Keep everything intact.
    onInteraction(modelsSelected, newModelsSelected);
    onCapabilities(capabilitiesSelected);

    // Save the 'keep' action for future automatic decisions.
    localStorage.setItem('capabilityCheckLastAction', 'keep');

    // If user had selected "Don't ask again" (and confirmed).
    if (tempDontAskAgain) {
      setDontAskAgain(true);
      localStorage.setItem('capabilityCheckDontAskAgain', 'true');
    }
    setModelValidatorOpen(false);
  };

  // 4. Persist 'dontAskAgain' whenever it changes.
  useEffect(() => {
    localStorage.setItem('capabilityCheckDontAskAgain', dontAskAgain);
  }, [dontAskAgain]);

  /**
   * Invoked when the user toggles "Don't ask again" in the main dialog.
   * We show an irreversible confirmation dialog before confirming the final setting.
   */
  const handleDontAskAgainChange = (event) => {
    const isChecked = event.target.checked;
    setTempDontAskAgain(isChecked);

    if (isChecked) {
      setShowIrreversibleDialog(true);
    }
  };

  // Called if the user confirms the irreversible action in the secondary dialog.
  const handleIrreversibleConfirm = () => {
    setShowIrreversibleDialog(false);
  };

  // Called if the user cancels the irreversible action in the secondary dialog.
  const handleIrreversibleCancel = () => {
    setTempDontAskAgain(false);
    setShowIrreversibleDialog(false);
  };

  /**
   * Determines whether the main dialog should be displayed:
   * - The dialog is open.
   * - We have at least one incompatible model to show.
   * - The user hasn't set "don'tAskAgain" to true.
   */
  const shouldShowMainDialog =
    isOpen && incompatibleModels.length > 0 && !dontAskAgain;

  return (
    <>
      {/* --- Main Compatibility Dialog --- */}
      <Dialog
        open={shouldShowMainDialog}
        onClose={() => setModelValidatorOpen(false)}
        onClick={(event) => event.stopPropagation()}
        PaperProps={{
          elevation: 0, // Sin sombras
          style: {
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#F7F7F8',
            width: '655px',
            height: '378px',
            borderRadius: '21px',
            padding: '32px',
            gap: '4px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Figtree, sans-serif',
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '28px',
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
          }}
        >
          Incompatible Models Detected
        </DialogTitle>

        <DialogContent className="font-figtree">
          <Typography
            sx={{
              marginBottom: '4px',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontFamily: 'Figtree, sans-serif',
              textAlign: 'center',
            }}
          >
            Some selected models are not compatible with the chosen
            capabilities. Would you like to discard these incompatible models?
          </Typography>
          <ul
            style={{
              margin: '0 auto',
              listStyleType: 'disc',
              width: 'fit-content',
              padding: 8, // Sin padding adicional
            }}
          >
            {incompatibleModels.map((model) => (
              <li
                key={model._id}
                style={{
                  fontFamily: 'Figtree, sans-serif',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#B05A5A',
                }}
              >
                {model.name}
              </li>
            ))}
          </ul>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempDontAskAgain}
                  onChange={handleDontAskAgainChange}
                  sx={{
                    backgroundColor:
                      themeValue === 'dark' ? '#5C5A5E' : '#E1E1E5',
                    color: themeValue === 'dark' ? '#5C5A5E' : '#E1E1E5',
                    borderRadius: '4px',
                    width: '20px',
                    height: '20px',
                    '&.Mui-checked': {
                      backgroundColor:
                        themeValue === 'dark' ? '#5C5A5E' : '#E1E1E5',
                      color: themeValue === 'dark' ? '#5C5A5E' : '#E1E1E5',
                    },
                    '& .MuiSvgIcon-root': {
                      display: 'none',
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Figtree, sans-serif',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    margin: '16px 0 16px 8px',
                    textAlign: 'center',
                    color: '#858585',
                  }}
                >
                  Don't ask me again
                </Typography>
              }
              sx={{
                gap: '2px',
              }}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {' '}
          {/* Centra los botones */}
          <Button
            onClick={() => handleKeep(false)}
            color="secondary"
            sx={{
              backgroundColor: themeValue === 'dark' ? '#7F82C3' : '#D4D5E6',
              color: themeValue === 'dark' ? '#EFEFEF' : '#5256A6',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#6C6FB5' : 'inherit',
              },
            }}
          >
            Continue with Selected Models
          </Button>
          <Button
            onClick={() => handleDiscard(false)}
            color="primary"
            sx={{
              backgroundColor: themeValue === 'dark' ? '#464BBA' : '#464BBA',
              color: themeValue === 'dark' ? '#FFFFFF' : '#FFFFFF',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#3C41A3' : '#3C41A3',
              },
            }}
          >
            Discard Incompatible Models
          </Button>
        </DialogActions>
      </Dialog>

      {/* --- Irreversible Confirmation Dialog --- */}
      <Dialog
        open={showIrreversibleDialog}
        onClose={handleIrreversibleCancel}
        disableEscapeKeyDown
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#F7F7F8',
            width: '655px',
            height: 'fit-content',
            borderRadius: '21px',
            padding: '32px',
            gap: '4px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Figtree, sans-serif',
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '28px',
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
          }}
        >
          Confirm Irreversible Action
        </DialogTitle>
        <DialogContent className="font-figtree">
          <Typography
            sx={{
              marginBottom: '8px',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontFamily: 'Figtree, sans-serif',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            By selecting "Don't ask me again", this dialog will no longer be
            shown, and the system will always execute the last chosen option
            automatically (Keep or Discard). This action is irreversible. Are
            you sure?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '12px' }}>
          <Button
            onClick={handleIrreversibleCancel}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#7F82C3' : '#D4D5E6',
              color: themeValue === 'dark' ? '#EFEFEF' : '#5256A6',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#6C6FB5' : 'inherit',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleIrreversibleConfirm}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#464BBA' : '#464BBA',
              color: themeValue === 'dark' ? '#FFFFFF' : '#FFFFFF',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#3C41A3' : '#3C41A3',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* --- 'No Models Selected' Warning Dialog --- */}
      <Dialog
        open={showNoModelsDialog}
        onClose={() => setShowNoModelsDialog(false)}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#F7F7F8',
            width: '655px',
            height: 'fit-content',
            borderRadius: '21px',
            padding: '32px',
            gap: '4px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Figtree, sans-serif',
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '28px',
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
          }}
        >
          No Models Selected
        </DialogTitle>
        <DialogContent className="font-figtree">
          <Typography
            sx={{
              marginBottom: '8px',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontFamily: 'Figtree, sans-serif',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            You must select at least one compatible model to use this
            capability.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={() => setShowNoModelsDialog(false)}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#464BBA' : '#464BBA',
              color: themeValue === 'dark' ? '#FFFFFF' : '#FFFFFF',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#3C41A3' : '#3C41A3',
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CapabilityModelValidator;
